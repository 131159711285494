import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ORDER_DIRECTION } from 'src/utils/constant';

function TableSortIcon({ order, orderBy, onClick }) {
  return (
    <Stack sx={{ height: '30px', width: '20px', position: 'relative', cursor: 'pointer' }} onClick={onClick}>
      <ArrowDropUp
        fontSize="large"
        sx={{ position: 'absolute', top: '1px' }}
        color={order === ORDER_DIRECTION.ASC && orderBy ? 'primary' : 'disabled'}
      />
      <ArrowDropDown
        fontSize="large"
        sx={{ position: 'absolute', bottom: '1px' }}
        color={order === ORDER_DIRECTION.DESC && orderBy ? 'primary' : 'disabled'}
      />
    </Stack>
  );
}

export default TableSortIcon;
