import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TableActionButton from 'src/components/table-action-button';
import { LOCALIZATION } from 'src/constants';
import UserIcon from 'src/layouts/components/UserIcon';
import { projectAPIs } from 'src/services';
import { EVENTS, MESSAGES } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import EditForm from './EditForm';
import SupportedUserStatus from './SupportedUserStatus';

const headCells = [
  {
    id: 'fullName',
    numeric: false,
    disablePadding: false,
    label: 'участник',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'роль',
    width: '250px',
  },
  {
    id: 'expiredDate',
    numeric: false,
    disablePadding: false,
    label: 'дата',
    width: '200px',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'статус',
    width: '200px',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: '',
    width: '50px',
  },
];

function SupportedUserList({
  projectUuid,
  systemRoles,
  loading,
  allTabs,
  queryParams = {},
  setQueryParams,
  onReloadTable = () => {},
}) {
  const lang = LOCALIZATION.ru_RU;
  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);
  const currentProject = useSelector((state) => state.application.currentProject);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [showEditForm, setShowEditForm] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { page: queryPage, limit, query } = queryParams;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { reset, register, getValues, handleSubmit } = useForm({
    defaultValues: { query: queryParams?.query || '' },
  });

  const handleSearch = ({ query }) => {
    const trimmedText = query && query.trim();
    onReloadTable(trimmedText);
  };

  const handleUpdateUser = async (sysuserUuid, body) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await projectAPIs.updateSupportedUser(projectUuid, sysuserUuid, body);
      onReloadTable();
      createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY, 'success');
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setTimeout(() => setIsSubmitting(false), 200);
    }
  };

  const deleteRow = async (row) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await projectAPIs.deleteSupportedUser(projectUuid, row.uuid);
      onReloadTable();
      createNotification(MESSAGES[lang].DELETE_SUCCESSFULLY, 'success');
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setTimeout(() => setIsSubmitting(false), 200);
    }
  };

  const onDeleteRow = (row) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteRow(row);
      },
    });
  };

  const visibleRows = React.useMemo(
    () => list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [list, page, rowsPerPage]
  );

  return (
    <Stack>
      <Divider />

      <Stack>
        <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap" padding="20px">
          <Stack direction="row" gap="8px 24px" flexWrap="wrap"></Stack>

          <form noValidate onSubmit={handleSubmit(handleSearch)}>
            <Stack direction="row" gap="8px 24px" flexWrap="wrap">
              <TextField
                name="query"
                size="small"
                placeholder="Введите название..."
                sx={{ minWidth: '240px' }}
                {...register('query')}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: getValues('query') ? 'visible' : 'hidden' }}
                      onClick={() => {
                        reset();
                        handleSearch({ query: '' });
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
              <Button type="submit" variant="contained">
                поиск
              </Button>
            </Stack>
          </form>
        </Stack>

        <TableContainer
          id="supported-users-table"
          component={Paper}
          sx={{ position: 'relative', marginBottom: '20px' }}
        >
          {loading && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ position: 'absolute', inset: '0', zIndex: 2, background: '#ffffff40', padding: '60px 0' }}
            >
              <CircularProgress />
            </Stack>
          )}
          <Table size="medium" sx={{ minWidth: 750, minHeight: 150 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow sx={{ background: '#F9FAFC' }}>
                {headCells.map((headCell, indexHead) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    width={headCell.width}
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 600, lineHeight: '24px' }}>
                        {headCell.label}
                      </Typography>
                      {indexHead < headCells.length - 2 && (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ width: '2px', height: '14px !important', margin: 'auto 0' }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {counts.total === 0 && !loading ? (
                <TableRow>
                  <TableCell colSpan={headCells.length}>
                    <Typography align="center">Нет данных</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {visibleRows.map((row, index) => {
                    // const vizitDetailURL = `${routes.projectVizitDetail.path
                    //   .replace(':uuid', currentProject?.uuid)
                    //   .replace(':vizitUuid', row.uuid)}`;
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={`row-${index}`}
                        role="checkbox"
                        // sx={{ cursor: 'pointer' }}
                        // onClick={() => navigate(vizitDetailURL)}
                      >
                        <TableCell component="th" scope="row" padding="none" sx={{ minWidth: '60%' }}>
                          <Stack>
                            <Typography variant="body1">{row.fullName}</Typography>
                            <Typography variant="body2">{row.email}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
                            {row.roleName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }}>
                            {moment(new Date(row.expiredDate)).format('DD MMMM yyyy')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <SupportedUserStatus status={row.status} />
                        </TableCell>
                        <TableCell>
                          <TableActionButton
                            options={[
                              {
                                label: 'Редактировать',
                                onClick: (e) => {
                                  setShowEditForm(true);
                                  setSelectedRow(row);
                                  //   navigate(vizitDetailURL);
                                },
                              },
                              {
                                label: 'Сменить пароль',
                                onClick: (e) => {
                                  setShowEditForm(true);
                                  setSelectedRow(row);
                                  setIsChangePassword(true);
                                  //   navigate(vizitDetailURL);
                                },
                              },
                              {
                                label: 'Заблокировать',
                                onClick: (e) => {
                                  handleUpdateUser(row.uuid, { status: 'blocked' });
                                },
                              },
                              {
                                label: 'Удалить',
                                onClick: (e) => {
                                  onDeleteRow(row);
                                },
                              },
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
          {counts.total > 0 && (
            <TablePagination
              labelRowsPerPage="Рядов на странице:"
              labelDisplayedRows={({ count, from, to }) => {
                return `${from}-${to} из ${count}`;
              }}
              page={page}
              component="div"
              count={counts.total}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10, 20, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ marginTop: '20px' }}
            />
          )}
        </TableContainer>
      </Stack>

      {showEditForm && (
        <EditForm
          systemRoles={systemRoles}
          show={showEditForm}
          onClose={() => {
            setShowEditForm(false);
            setIsChangePassword(false);
          }}
          isChangePassword={isChangePassword}
          user={selectedRow}
          onHandleUpdateUser={(body) => {
            setShowEditForm(false);
            handleUpdateUser(selectedRow.uuid, body);
          }}
        />
      )}
    </Stack>
  );
}

export default SupportedUserList;
