import { FORM_ERROR } from 'final-form';
import { request } from '../../utils/request';
import * as constants from '../constants/auth';
import { dispatch, getCurrentState } from '../index';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { staffAPIs } from 'src/services';

export const login = async ({ username, password }) => {
  try {
    const data = await request('/login', {
      method: 'POST',
      body: { username, password },
    });
    if (!data.logged_in) {
      createErrorNotification('Пароль или логин не верный');
      return { [FORM_ERROR]: 'Неверный логин или пароль' };
    }
    localStorage.setItem('token', data.token);
    dispatch({ type: constants.AUTH__LOGIN_SUCCESS, data });
  } catch (error) {
    createErrorNotification('Пароль или логин не верный');
    return { [FORM_ERROR]: 'Неверный логин или пароль' };
  }
};

export const staffLogin = async ({ email, password, projectUuid }) => {
  try {
    const res = await staffAPIs.login({ email, password, projectUuid });
    const token = res.message;
    localStorage.setItem('token', token);
    dispatch({ type: constants.AUTH__LOGIN_SUCCESS, data: { email, projectUuid, token, isStaff: true } });
    getCurrentStaffProfile();
  } catch (error) {
    createErrorNotification('Пароль или логин не верный');
    return;
  }
};

export const checkMfa = async ({ otp }) => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  try {
    const data = await request('/login/mfa', {
      method: 'POST',
      body: { otp },
    });
    localStorage.setItem('token', data.token);
    dispatch({ type: constants.AUTH__LOGIN_MFA_SUCCESS, data });
  } catch (error) {
    return { [FORM_ERROR]: 'Неверный код, попробуйте еще раз через 5 секунд' };
  }
};

export const getCurrentStaffProfile = async () => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  const user = getCurrentState()?.auth?.user;
  if (user?.isStaff) {
    try {
      const res = await staffAPIs.getCurrentStaff(user.projectUuid);
      dispatch({ type: constants.AUTH__GET_STAFF_PROFILE, data: res.message });
    } catch (error) {
      console.error(error);
    }
  }
};

export const autoLogin = async () => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  const user = getCurrentState()?.auth?.user;
  try {
    if (user?.isStaff) {
      await getCurrentStaffProfile();
      return true;
    }
    const { data } = await request('/user/current');
    dispatch({ type: constants.AUTH__AUTO_LOGIN_SUCCESS, data });
    return true;
  } catch (error) {
    localStorage.removeItem('token');
    dispatch({ type: constants.AUTH__LOGOUT });
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  dispatch({ type: constants.AUTH__LOGOUT });
};

export const deleteAccount = async ({}) => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  try {
    await request('/settings/delete', {
      method: 'POST',
    });
    localStorage.removeItem('token');
    dispatch({ type: constants.AUTH__LOGOUT });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};
