import { Button, Card, CardContent, Grid, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Loader from 'src/components/loader';
import { projectAPIs } from 'src/services';
import { LOCALIZATION, MESSAGES } from 'src/utils/localization';
import { createErrorNotification, createNotification } from 'src/utils/notifications';

function AvatarSizeSettings({ projectUuid }) {
  const fetchAvatarSize = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await projectAPIs.getAvatarSizeSetting(projectUuid);
    return response.message;
  };

  const { isLoading, data, refetch, isRefetching } = useQuery(['fetchAvatarSize', projectUuid], fetchAvatarSize, {
    enabled: !!projectUuid,
  });

  const [currentSize, setCurrentSize] = useState({ height: 100, width: 100 });

  useEffect(() => {
    if (data) {
      setCurrentSize(data);
    }
  }, [data]);

  const handleSubmit = async () => {
    try {
      await projectAPIs.updateAvatarSizeSetting(projectUuid, {
        height: parseInt(currentSize.height),
        width: parseInt(currentSize.width),
      });
      refetch();
      createNotification(MESSAGES[LOCALIZATION.ru_RU].UPDATE_SUCCESSFULLY, 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(MESSAGES[LOCALIZATION.ru_RU].ERROR);
    }
  };

  return (
    <Card>
      <CardContent>
        {isLoading || isRefetching ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid item xs={12} md={4}>
              <Stack gap="18px">
                <TextField
                  label="Ширина (px)"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  value={currentSize.width}
                  onChange={(e) => {
                    setCurrentSize((old) => ({ ...old, width: e.target.value }));
                  }}
                />
                <TextField
                  label="Высота (px)"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  value={currentSize.height}
                  onChange={(e) => {
                    setCurrentSize((old) => ({ ...old, height: e.target.value }));
                  }}
                />
                <Button
                  variant="contained"
                  sx={{ width: { xs: '100%', md: '150px' } }}
                  disabled={currentSize.width === data?.width && currentSize.height === data?.height}
                  onClick={handleSubmit}
                >
                  сохранить
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default AvatarSizeSettings;
