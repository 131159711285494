import { FormControl, InputLabel, TextField } from '@mui/material';
import _ from 'lodash';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { Field } from 'react-final-form';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import CustomInput from './PickersCustomInput';

export default function DateInput({ name, label, className, shrink = true }) {
  const labelId = _.uniqueId('date-picker__');

  return (
    <Field name={name}>
      {({ input }) => (
        // <div className={cx(classes.root, className)}>
        //   {label && <label>{label}</label>}
        //   <DatePicker
        //     selected={input.value}
        //     onChange={(date) => input.onChange(date)}
        //     customInput={<PureInput className={classes.input} />}
        //   />
        // </div>
        // <FormControl fullWidth>
        //   <InputLabel shrink={shrink ? shrink : undefined} id={labelId}>
        //     {label}
        //   </InputLabel>
        <DatePicker
          selected={input.value}
          onChange={(date) => input.onChange(date)}
          dateFormat="dd.MM.yyyy"
          popperProps={{
            strategy: 'fixed', // use this to make the popper position: fixed
          }}
          customInput={
            <CustomInput
              fullWidth={true}
              InputLabelProps={{ shrink: shrink ? shrink : undefined }}
              name={name}
              labelId={labelId}
              label={label}
              value={moment(input.value).format(DEFAULT_DATE_FORMAT)}
            />
          }
        />
        // </FormControl>
      )}
    </Field>
  );
}
