// import { Print } from '@mui/icons-material';
// import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
// import Loader from 'src/components/loader';
// import { projectAPIs } from 'src/services';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
// import { createErrorNotification } from 'src/utils/notifications';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/dist/styles.css';

function ViewPDFModal() {
  const [state, setState] = useState({
    show: false,
    images: [],
    // projectUuid: '',
    // userUuid: '',
    // onPrint: null,
  });
  //   const [isDownloading, setIsDownloading] = useState(false);

  const handleCancel = () => setState({ show: false });
  const handleShow = (data) => {
    setState({ ...state, ...data, show: true });
  };

  //   const handleDownload = async () => {
  //     if (isDownloading) return;
  //     try {
  //       setIsDownloading(true);
  //       if (state.onPrint && typeof state.onPrint === 'function') {
  //         await state.onPrint();
  //       } else {
  //         const response = await projectAPIs.printBadge(state.projectUuid, state.userUuid);
  //         const blobURL = window.URL.createObjectURL(response);
  //         window.open(blobURL).print();
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       createErrorNotification(error.message.error);
  //     } finally {
  //       setIsDownloading(false);
  //     }
  //   };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_VIEW_PDF_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_VIEW_PDF_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.show) return null;
  return (
    <Lightbox
      open={state.show}
      styles={{ root: { '--yarl__color_backdrop': 'rgba(0, 0, 0, .8)' } }}
      close={handleCancel}
      slides={[state.images, state.images].flat()}
      carousel={{ finite: true }}
      toolbar={{
        buttons: [
          //   <IconButton key="print-button" onClick={handleDownload}>
          //     {isDownloading ? <Loader size="28px" /> : <Print sx={{ color: 'hsla(0,0%,100%,.8)', fontSize: 32 }} />}
          //   </IconButton>,
          'close',
        ],
      }}
      render={{
        slide: ({ slide }) => {
          // return <embed src={slide.src + '#toolbar=0'} height="100%" width="100%" style={{ padding: 48 }} />;
          return <embed src={slide.src} height="100%" width="100%" style={{ padding: 48 }} />;
        },
      }}
    />
  );
}

export default ViewPDFModal;
