import fetch from 'isomorphic-unfetch';
import { getCurrentState } from 'src/store';
import { logout } from '../store/actions/auth';

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = await response.json();
  throw { ...error, code: response.status };
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

function parseBlob(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.blob();
}

export function request(url, params = {}, isSVGFile, isBlob) {
  let body = params.body ? JSON.stringify(params.body) : null;
  const isFormData = params.body && params.body instanceof FormData;
  const token = localStorage.getItem('token');
  const isStaff = getCurrentState()?.auth?.isStaff;

  const headers = {
    'Content-Type': 'application/json',
    token: token,
    ...params.headers,
    // method: params.method || 'GET',
  };
  if (isFormData) {
    body = params.body;
    delete headers['Content-Type'];
  }
  if (isStaff) {
    headers.authorization = token;
    delete headers.token;
  }
  return fetch((isStaff ? process.env.REACT_APP_STAFF_SERVER_URL : process.env.REACT_APP_SERVER_URL + '/api') + url, {
    ...params,
    headers,
    body,
  })
    .then(checkStatus)
    .then(
      isSVGFile
        ? (response) => {
            return response.text();
          }
        : /\/internal\/users\/([A-Za-z0-9]+(-[A-Za-z0-9]+)+)\/documents\/file/i.test(url) || isBlob
        ? parseBlob
        : parseJSON
    )
    .catch((error) => {
      if (error.statusCode === 401) {
        logout();
      }
      throw error;
    });
}

export const downloadBlob = (blob, name) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const downloadFile = ({ path, name, method = 'GET', returnBlob, accept }) => {
  const token = localStorage.getItem('token');
  const isStaff = getCurrentState()?.auth?.isStaff;

  const headers = {
    accept: accept || 'application/json',
    token: token,
  };
  if (isStaff) {
    headers.authorization = token;
    delete headers.token;
  }

  return fetch(
    `${isStaff ? process.env.REACT_APP_STAFF_SERVER_URL : process.env.REACT_APP_SERVER_URL + '/api'}${path}`,
    {
      headers: headers,
      method,
    }
  )
    .then(checkStatus)
    .then((res) => res.blob())
    .then((blob) => {
      if (returnBlob) {
        return blob;
      }
      downloadBlob(blob, name);
    });
};
