import ButtonLoading from 'src/components/button-loading';

function SaveGiftsButton({ user, disabled, onClick }) {
  const isGivenMerch = user?.isGivenMerch;

  return (
    <ButtonLoading variant="outlined" disabled={disabled} onClick={onClick}>
      {isGivenMerch ? 'Вернуть мерч' : 'Выдать мерч'}
    </ButtonLoading>
  );
}

export default SaveGiftsButton;
