import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  Input,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import MuiMenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { Form } from 'react-final-form';
import DateTimeInputMui from 'src/components/date-time-input-mui';
import Loader from 'src/components/loader';
import { downloadReport } from 'src/store/actions/application';
import DateReportDialog from './DateReportDialog';
import TagsReportDialog from './TagsReportDialog';

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: '9px 16px',
  gap: '12px',
  minWidth: '260px',
  '&:hover': {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #9155FD',
    '& *': {
      color: theme.palette.primary.main,
    },
  },
}));

function ReportButton({ onDownload = () => {}, disabled }) {
  const [open, setOpen] = useState(false);
  const [datesReportOpen, setDatesReportOpen] = useState(false);
  const [tagsReportOpen, setTagsReportOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const options = [
    { label: 'Текущий отчёт', onClick: onDownload },
    { label: 'Виза отчёт', onClick: () => downloadReport({ reportType: 'no_visa', format: 'xlsx' }) },
    { label: 'Общий отчёт', onClick: () => downloadReport({ reportType: 'residence_citizenship', format: 'xlsx' }) },
    { label: 'ЕПГУ отчёт', onClick: () => downloadReport({ reportType: 'users_accreditation_all', format: 'csv' }) },
    { label: 'Ковидный отчёт', onClick: () => downloadReport({ reportType: 'users_covid_all', format: 'csv' }) },
    {
      label: 'Деловая программа',
      onClick: () => downloadReport({ reportType: 'business_programm', format: 'xlsx' }),
    },
    {
      label: 'Прибытие/отправление',
      onClick: () => downloadReport({ reportType: 'users_schedules_all', format: 'xlsx' }),
    },
    { label: 'Отчет по дате', onClick: () => setDatesReportOpen(true) },
    { label: 'Отчет по тегам', onClick: () => setTagsReportOpen(true) },
  ];

  const downloadDateReport = async (dates) => {
    setIsSubmitting(true);
    try {
      setDatesReportOpen(false);
      await downloadReport({
        reportType: 'participant_schedules',
        format: 'xlsx',
        dates,
      });
    } catch (e) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const downloadTagsReport = async (tags) => {
    setIsSubmitting(true);
    try {
      setTagsReportOpen(false);
      await downloadReport({
        reportType: 'tags',
        format: 'xlsx',
        tags,
      });
    } catch (e) {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <Button
        ref={anchorRef}
        variant="outlined"
        startIcon={isSubmitting ? <Loader color="inherit" size="12px" /> : <DownloadIcon />}
        disabled={disabled || isSubmitting}
        onClick={handleToggle}
      >
        отчёт по экрану
      </Button>
      <Popper
        transition
        open={open}
        disablePortal
        role={undefined}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        sx={{ zIndex: 9 }}
        popperOptions={{
          modifiers: [
            {
              name: 'flip',
              options: {
                enabled: true,
                boundary: 'window',
              },
            },
          ],
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'right top' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={`option-${index}`}
                      onClick={async () => {
                        if (isSubmitting) return;
                        handleToggle();
                        try {
                          setIsSubmitting(true);
                          if (option.onClick) {
                            await option.onClick();
                          }
                        } finally {
                          setIsSubmitting(false);
                        }
                      }}
                    >
                      <CircleOutlinedIcon sx={{ fontSize: '14px', width: '20px' }} />
                      <Typography variant="body1">{option.label}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <DateReportDialog
        open={datesReportOpen}
        onClose={() => setDatesReportOpen(false)}
        onSubmit={downloadDateReport}
      />
      <TagsReportDialog open={tagsReportOpen} onClose={() => setTagsReportOpen(false)} onSubmit={downloadTagsReport} />
    </Box>
  );
}

export default ReportButton;
