import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateUserByProjectUuid } from 'src/store/actions/application';

function ActionsButton({ item = {}, reloadTable = () => {} }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateUser = async (e, { user, isAccredited }) => {
    e.preventDefault();
    e.stopPropagation();
    await updateUserByProjectUuid(user.uuid, { isAccredited, mainRoleId: user.mainRoleId });
    reloadTable();
  };

  const options = [
    {
      label: 'Принимать',
      //   disabled: item.isAccredited !== null,
      onClick: (e) => {
        handleUpdateUser(e, { user: item, isAccredited: true });
      },
    },
    {
      label: 'Отклонить',
      //   disabled: item.isAccredited !== null,
      onClick: (e) => {
        handleUpdateUser(e, { user: item, isAccredited: false });
      },
    },
  ];

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.label} disabled={option.disabled} onClick={option.onClick}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default ActionsButton;
