import React, { useCallback, useEffect } from 'react';
import { HotKeys } from 'react-hotkeys';
import { useNavigate } from 'react-router-dom';
import { userAPIs } from 'src/services';
import qs from 'query-string';
import { createErrorNotification } from 'src/utils/notifications';
import { useSelector } from 'react-redux';
import { useAbility } from 'src/hooks/useAbility';
import { ACCESSES } from 'src/utils/ability';

function CopyBadgeIdProvider({ allowCopyBadgeID, children }) {
  let badgeId = '';
  const navigate = useNavigate();
  const ability = useAbility();
  const currentProject = useSelector((state) => state.application.currentProject);

  const searchUserByBadgeID = async (badgeId) => {
    if (ability.cannot(ACCESSES.SEE_PROFILE)) return;
    try {
      const res = await userAPIs.getUser(
        qs.stringify({ badgeId, limit: 1, offset: 0, ...(currentProject ? { projectUuid: currentProject.uuid } : {}) })
      );
      if (res?.message?.[0]) {
        navigate(`/profile/${res.message[0].uuid}`);
      } else {
        createErrorNotification('Пользователь не найден');
      }
    } catch (e) {
      createErrorNotification('Пользователь не найден');
    }
  };

  const handlers = {
    COPY_BADGE_ID: useCallback(() => {
      if (allowCopyBadgeID) {
        navigator.clipboard
          .readText()
          .then((text) => searchUserByBadgeID(text.trim()))
          .catch((error) => console.error(error));
      }
    }, [allowCopyBadgeID, currentProject]),
  };

  const handleKeyPress = (e) => {
    if (allowCopyBadgeID) {
      const value = e.key;
      if (value === 'Enter') {
        searchUserByBadgeID(badgeId.trim());
        badgeId = '';
      } else {
        badgeId = badgeId + value;
      }
    }
  };

  useEffect(() => {
    if (allowCopyBadgeID) {
      window.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [allowCopyBadgeID, currentProject]);

  return (
    <HotKeys keyMap={{ COPY_BADGE_ID: ['ctrl+v', 'command+v'] }} handlers={handlers}>
      {children}
    </HotKeys>
  );
}

export default CopyBadgeIdProvider;
