import * as constants from '../constants/auth';

const initialState = {
  mfaEnabled: null,
  mfaSuccess: null,
  qrCode: null,
  user: null,
  isStaff: false,
  currentProjectUuid: null,
  staffProfile: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case constants.AUTH__LOGIN_SUCCESS:
      return {
        ...state,
        mfaEnabled: action.data.enabled_mfa,
        user: action.data,
        isStaff: action.data.isStaff,
        currentProjectUuid: action.data.projectUuid,
      };
    case constants.AUTH__LOGIN_MFA_SUCCESS:
      return {
        ...state,
        mfaSuccess: action.data.mfa_success,
      };
    case constants.AUTH__AUTO_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.data,
        mfaEnabled: true,
        mfaSuccess: true,
      };
    case constants.AUTH__GET_STAFF_PROFILE:
      return {
        ...state,
        staffProfile: action.data,
      };
    case constants.AUTH__LOGOUT:
      return initialState;
    default:
      return state;
  }
}
