import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import UserIcon from 'src/layouts/components/UserIcon';
import TemplateForm from './TemplateForm';
import DuplicateForm from './DuplicateForm';

function TemplateCreate({ projectUuid, roleUuid, onRefetchData }) {
  const [showAddNew, setShowAddNew] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);

  if (showAddNew)
    return (
      <TemplateForm
        projectUuid={projectUuid}
        roleUuid={roleUuid}
        onCancelAddNew={() => setShowAddNew(false)}
        onRefetchData={onRefetchData}
      />
    );
  if (showDuplicate)
    return (
      <DuplicateForm
        projectUuid={projectUuid}
        roleUuid={roleUuid}
        onCancelDuplicate={() => setShowDuplicate(false)}
        onRefetchData={onRefetchData}
      />
    );
  return (
    <Stack alignItems="center" gap="13px">
      <UserIcon icon="mdi:alert-circle-outline" style={{ width: '50px', height: '50px', color: '#E3E3E3' }} />
      <Typography variant="h5">Шаблон отсутствует</Typography>
      <Typography variant="body2">Вы можете дублировать уже готовый шаблон.</Typography>
      <Stack direction="row" gap="16px" marginTop="18px">
        <Button variant="contained" onClick={() => setShowDuplicate(true)}>
          Дублировать
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => setShowAddNew(true)}>
          Создать новый
        </Button>
      </Stack>
    </Stack>
  );
}

export default TemplateCreate;
