import { toast } from 'react-toastify';

export const createNotification = (message, type = 'info', params) => {
  toast[type](message, {
    pauseOnHover: true,
    position: toast.POSITION.BOTTOM_LEFT,
    ...params,
  });
};

export const createErrorNotification = (message, type = 'error', params) => {
  toast[type](message, {
    pauseOnHover: true,
    position: toast.POSITION.BOTTOM_LEFT,
    ...params,
  });
};
