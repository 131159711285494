const EventEmitter = require('events');

export const EVENTS = {
  OPEN_ALERT_FORM: 'open-alert-form',
  OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
  OPEN_CONFIRM_APPOINTMENT_MODAL: 'OPEN_CONFIRM_APPOINTMENT_MODAL',
  OPEN_VIEW_IMAGE_MODAL: 'OPEN_VIEW_IMAGE_MODAL',
  OPEN_PACKAGE_DETAIL_MODAL: 'OPEN_PACKAGE_DETAIL_MODAL',
  OPEN_FORGOT_PASSWORD_FORM: 'OPEN_FORGOT_PASSWORD_FORM',
  OPEN_CHANGE_PASSWORD_FORM: 'OPEN_CHANGE_PASSWORD_FORM',
  RELOAD_PRODUCTS: 'RELOAD_PRODUCTS',
  OPEN_MEMBERS_MODAL: 'OPEN_MEMBERS_MODAL',
  OPEN_PROGRAM_DETAIL_MODAL: 'OPEN_PROGRAM_DETAIL_MODAL',
  OPEN_COMPANY_MODAL: 'OPEN_COMPANY_MODAL',
  OPEN_VIZIT_SEARCH_MODAL: 'OPEN_VIZIT_SEARCH_MODAL',
  RELOAD_INVITATION_LINKS: 'RELOAD_INVITATION_LINKS',
  RELOAD_PROFILE: 'RELOAD_PROFILE',
};

const eventBus = new EventEmitter();
export default eventBus;
