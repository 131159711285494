import { Stack, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getFullName } from 'src/utils/common';
import { request } from 'src/utils/request';

async function fetchOptions({ queryKey }) {
  const [_, projectUuid, searchQuery = '', isEmailValid] = queryKey;
  if (isEmailValid) {
    const response = await request(`/internal/users?email=${searchQuery}&projectUuid=${projectUuid}&limit=1000`);
    return { options: response.message };
  }
  return { options: [] };
}

export default function UserSearchSelect({ projectUuid, onChangeUser }) {
  const [value, setValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const { isLoading, error, data } = useQuery(['userOptions', projectUuid, searchQuery, isEmailValid], fetchOptions);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSearchChange = debounce((event, value) => {
    setSearchQuery(value);
    setIsEmailValid(validateEmail(value) || value === '');
  }, 500);

  return (
    <Autocomplete
      loading={isLoading}
      options={data?.options || []}
      getOptionLabel={(option) => option.email}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack direction="row" gap="8px">
            <Typography>{option.email}</Typography>
            {getFullName(option) !== '' && <Typography variant="body2"> - {getFullName(option)}</Typography>}
          </Stack>
        </li>
      )}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            label="Поиск пользователя"
            onChange={(event) => {
              const value = event.target.value?.trim();
              handleSearchChange(event, value);
              if (value === '' || validateEmail(value)) {
                setIsEmailValid(true);
              } else {
                setIsEmailValid(false);
              }
            }}
            error={!isEmailValid}
            helperText={!isEmailValid && 'Please enter a valid email address'}
          />
        </>
      )}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        onChangeUser(newValue);
      }}
      onClose={() => {
        if (value) {
          setIsEmailValid(true);
        }
      }}
    />
  );
}
