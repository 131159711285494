import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
import { LOCALIZATION, routes } from 'src/constants';
import { useProjectDetail } from 'src/hooks';
import UserIcon from 'src/layouts/components/UserIcon';
import UserLayout from 'src/layouts/UserLayout';
import { downloadApplicationList, getMeetingList, getTotalMeetings, setProject } from 'src/store/actions/application';
import { MEETING_STATUS } from 'src/utils/constant';
import { request } from 'src/utils/request';
import MeetingList from './MeetingList';
import ReportButton from './ReportButton';

function ProjectsMeetingsPage() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isInitTotal, setIsInitTotal] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const currentProject = useSelector((state) => state.application.currentProject);
  const counts = useSelector((state) => state.application.counts);

  const projectUuid = params.uuid;
  const queryParams = qs.parse(location.search);
  const activeTab = queryParams.tab || MEETING_STATUS.initial[LOCALIZATION.en_US];

  const { isLoading, data } = useProjectDetail({ projectUuid: projectUuid });

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams, page: newParams.page || 1 });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(`${routes.projectsMeetings.path.replace(':uuid', projectUuid)}?${query}`);
    },
    [queryParams]
  );

  useEffect(() => {
    if (!isLoading && data) {
      setProject(data);
      getTotalMeetings({ projectUuid: data?.uuid, filters: queryParams }).then(() => setIsInitTotal(true));
    }
  }, [isLoading, data]);

  const handleGetMeetingList = useCallback(() => {
    return getMeetingList({ ...queryParams, page: queryParams.page });
  }, [location, currentProject, isInitTotal]);

  const downloadList = useCallback(async () => {
    return await downloadApplicationList(queryParams);
  }, [location]);

  useEffect(() => {
    if (!isLoadingTable && currentProject && isInitTotal) {
      setIsLoadingTable(true);
      handleGetMeetingList().then(() => setIsLoadingTable(false));
    }
  }, [handleGetMeetingList]);

  const handleSelectTab = (name) => () => {
    setQueryParams({ tab: name, page: 1 });
  };

  const tabs = Object.keys(MEETING_STATUS).map((s) => ({
    label: _.upperFirst(_.toLower(MEETING_STATUS[s][LOCALIZATION.ru_RU])),
    count: counts[s],
    icon: MEETING_STATUS[s].icon,
    customIcon: MEETING_STATUS[s].customIcon,
    isActive: activeTab === s,
    onSelect: handleSelectTab(s),
  }));

  return (
    <UserLayout showExtraNavItems={true}>
      <Stack gap="24px">
        <Card>
          <CardContent>
            <Stack gap="20px">
              <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap">
                <Typography variant="h6">Встречи</Typography>
                <ReportButton onDownload={downloadList} disabled={isLoading} />
              </Stack>
              <Stack direction="row" gap="8px" flexWrap="wrap">
                {tabs.map((tab, i) => (
                  <Button
                    key={`tab-${i}`}
                    variant={tab.isActive ? 'contained' : 'text'}
                    color={tab.isActive ? 'primary' : 'secondary'}
                    startIcon={tab.customIcon ? tab.customIcon : <UserIcon icon={tab.icon} />}
                    onClick={tab.onSelect}
                  >
                    {tab.label} ({tab.count || 0})
                  </Button>
                ))}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <MeetingList
            loading={isLoadingTable}
            statuses={[]}
            activeTab={activeTab}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            onReloadTable={handleGetMeetingList}
          />
        </Card>
      </Stack>

      <ConfirmModal />
    </UserLayout>
  );
}

export default ProjectsMeetingsPage;
