import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createErrorNotification } from 'src/utils/notifications';
import { generalAPIs } from 'src/services';
import { useQuery } from 'react-query';
import { isArray } from 'lodash';
import { Clear } from '@mui/icons-material';

const TagsReportDialog = ({ open, onClose = () => {}, onSubmit = () => {} }) => {
  const [value, setValue] = useState([]);

  const handleSubmit = () => {
    if (value.length < 1) {
      createErrorNotification('Необходимо выбрать теги');
      return;
    }
    console.log('value', value);
    onSubmit(value.toString());
  };
  useEffect(() => {
    if (!open) {
      setValue([]);
    }
  }, [open]);

  const fetchTags = async () => {
    const response = await generalAPIs.getTags();
    const raw = response.message;
    return raw && isArray(raw) ? raw : [];
  };

  const { data: tags = [] } = useQuery('fetchTags', fetchTags, { staleTime: 0 });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Отчет по тегам</DialogTitle>
      <DialogContent>
        <Stack pt={'15px'}>
          <FormControl sx={{ width: { xs: '100%', sm: '400px' } }}>
            <InputLabel id="select-label__report-tags">{'Выберите теги'}</InputLabel>

            <Select
              onChange={({ target: { value } }) => {
                setValue(value);
              }}
              labelId="select-label__report-tags"
              label="Выберите теги"
              multiple
              value={value}
              endAdornment={
                value.length > 0 ? (
                  <IconButton onClick={() => setValue([])} sx={{ marginRight: '14px' }}>
                    <Clear />
                  </IconButton>
                ) : (
                  <></>
                )
              }
            >
              {tags.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagsReportDialog;
