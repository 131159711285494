// ** MUI Imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

// ** Icon Imports
import Icon from 'src/@core/components/icon';

// ** Custom Components Imports
import CustomAvatar from 'src/@core/components/mui/avatar';
import ReactApexcharts from 'react-apexcharts';

// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba';
import { Tooltip } from '@mui/material';

const DonutChart = ({ title = '', statistics = [] }) => {
  // ** Hook
  const theme = useTheme();
  const paletteColors = [
    theme.palette.primary.main,
    hexToRGBA(theme.palette.primary.main, 0.7),
    hexToRGBA(theme.palette.primary.main, 0.5),
    theme.palette.customColors.trackBg,
    'rgb(0, 255, 150)',
    'rgb(5, 250, 150)',
    'rgb(10, 245, 150)',
    'rgb(16, 239, 150)',
    'rgb(21, 234, 150)',
    'rgb(26, 229, 150)',
    'rgb(31, 224, 150)',
    'rgb(36, 219, 150)',
    'rgb(42, 213, 150)',
    'rgb(47, 208, 150)',
    'rgb(52, 203, 150)',
    'rgb(57, 198, 150)',
    'rgb(62, 193, 150)',
    'rgb(68, 187, 150)',
    'rgb(73, 182, 150)',
    'rgb(78, 177, 150)',
    'rgb(83, 172, 150)',
    'rgb(88, 167, 150)',
    'rgb(94, 161, 150)',
    'rgb(99, 156, 150)',
    'rgb(104, 151, 150)',
    'rgb(109, 146, 150)',
    'rgb(114, 141, 150)',
    'rgb(120, 135, 150)',
    'rgb(125, 130, 150)',
    'rgb(130, 125, 150)',
    'rgb(135, 120, 150)',
    'rgb(141, 114, 150)',
    'rgb(146, 109, 150)',
    'rgb(151, 104, 150)',
    'rgb(156, 99, 150)',
    'rgb(161, 94, 150)',
    'rgb(167, 88, 150)',
    'rgb(172, 83, 150)',
    'rgb(177, 78, 150)',
    'rgb(182, 73, 150)',
    'rgb(187, 68, 150)',
    'rgb(193, 62, 150)',
    'rgb(198, 57, 150)',
    'rgb(203, 52, 150)',
    'rgb(208, 47, 150)',
    'rgb(213, 42, 150)',
    'rgb(219, 36, 150)',
    'rgb(224, 31, 150)',
    'rgb(229, 26, 150)',
    'rgb(234, 21, 150)',
    'rgb(239, 16, 150)',
    'rgb(245, 10, 150)',
    'rgb(250, 5, 150)',
    'rgb(255, 0, 150)',
  ];

  const options = {
    chart: {
      sparkline: { enabled: true },
    },
    colors: paletteColors,
    stroke: { width: 0 },
    legend: { show: false },
    dataLabels: { enabled: false },
    labels: statistics.map((item) => item.label),
    states: {
      hover: {
        filter: { type: 'none' },
      },
      active: {
        filter: { type: 'none' },
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.9,
        donut: {
          size: '70%',
          labels: {
            show: false,
            name: {
              offsetY: 25,
              fontSize: '0.875rem',
              color: theme.palette.text.secondary,
            },
            value: {
              offsetY: -15,
              fontWeight: 500,
              formatter: (value) => `${value}k`,
              color: theme.palette.text.primary,
            },
            total: {
              show: true,
              fontSize: '0.875rem',
              label: 'Weekly Sales',
              color: theme.palette.text.secondary,
              formatter: (value) => `${value.globals.seriesTotals.reduce((total, num) => total + num)}k`,
            },
          },
        },
      },
    },
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Grid container sx={{ my: [0, 4, 1.625] }}>
          {statistics.length > 0 && (
            <Grid item xs={12} sm={6} sx={{ mb: [3, 0] }}>
              <ReactApexcharts
                type="donut"
                height={220}
                series={statistics.map((item) => item.value || 0)}
                options={options}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} sx={{ my: 'auto' }}>
            <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6">{title}</Typography>
            </Box>
            <Divider sx={{ my: (theme) => `${theme.spacing(4)} !important` }} />
            {statistics.length === 0 && <Typography variant="body1">Пустые данные</Typography>}
            <Grid container spacing={2}>
              {statistics.map((stat, i) => (
                <Grid key={`statistic-${i}`} item xs={6} sx={{ mb: 4 }}>
                  <Box
                    sx={{
                      mb: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      '& svg': { mr: 1.5, fontSize: '0.75rem', color: paletteColors[i] },
                    }}
                  >
                    <Icon icon="mdi:circle" />
                    <Typography variant="body2">{stat.value}</Typography>
                  </Box>
                  <Tooltip title={stat.label}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 600,
                        color: theme.palette.text.secondary,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {stat.label}
                    </Typography>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DonutChart;
