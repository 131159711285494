import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setCountriesList } from 'src/store/actions/application';
import countries from '../configs/countries.json';

function useCountries() {
  const savedCountries = useSelector((state) => state.application.countries);
  useEffect(() => {
    if (savedCountries?.length === 0 || savedCountries?.length !== countries?.length) {
      setCountriesList(countries);
    }
  }, [savedCountries]);
}

export default useCountries;
