const TextField = () => {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
            color: 'red',
          },
        },
      },
    },
  };
};

export default TextField;
