import { Box } from '@mui/material';
import React from 'react';
import classes from '../styles/auth.module.sass';

function AuthLayout({ children }) {
  return (
    <Box>
      <Box className={classes.contentRight}>
        <Box className={classes.leftContent}>
          <img alt="background" src="/images/background.png" className={classes.background} />
          {/* <img alt="logo" src="/images/logo.png" className={classes.logo} /> */}
          {/* <img alt="logo-background" src="/images/logo-background.png" className={classes.logoBackground} /> */}
        </Box>
        <Box className={classes.rightContent}>{children}</Box>
      </Box>
    </Box>
  );
}

export default AuthLayout;
