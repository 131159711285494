import React from 'react';
import { useParams } from 'react-router-dom';
import UserLayout from 'src/layouts/UserLayout';
import ScubSettings from '../project-settings/scub';

function ProjectScubSettingPage() {
  const { uuid } = useParams();

  return (
    <UserLayout showExtraNavItems={true}>
      <ScubSettings projectUuid={uuid} />
    </UserLayout>
  );
}

export default ProjectScubSettingPage;
