import { Box, Chip, Stack, Typography } from '@mui/material';
import { keyBy } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import CustomChip from 'src/@core/components/mui/chip';
import { getFullName } from 'src/utils/common';

function Order({ order, members: allMembers, profileInfo }) {
  const members = useMemo(
    () => (allMembers ? allMembers.filter((m) => profileInfo?.uuid === m.uuid || m.accreditedBy !== '') : []),
    [allMembers, profileInfo]
  );
  const memberByUuid = useMemo(() => keyBy(members || [], 'uuid'), [members]);

  return (
    order && (
      <>
        <Stack direction="row" justifyContent="space-between" gap="8px" flexWrap={['wrap', 'nowrap']}>
          <Stack gap="8px">
            <Typography variant="body2" sx={{ fontWeight: 600, color: (theme) => theme.palette.text.primary }}>
              Заказ {moment.unix(order.createdAt).format('DD/MM/YYYY')}
            </Typography>
            <Stack direction="row" gap="8px" flexWrap="wrap">
              <Chip
                label={order.properties?.invoiceDocId ? 'Оплата по счёту' : 'Оплата банковской картой'}
                color="primary"
                sx={{ width: 'fit-content' }}
              />
              {/* {profileInfo?.uuid !== order.ownerId && <Chip label={'Общий заказ'} />} */}
            </Stack>

            <Stack direction="row" flexWrap="wrap" gap="4px">
              <Typography variant="body2" fontWeight={600} sx={{ color: (theme) => theme.palette.text.primary }}>
                Проект:{' '}
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                {order.project?.localization?.ru_RU?.fullName}
              </Typography>
            </Stack>

            <Box>
              <Typography variant="body2" fontWeight={600} sx={{ color: (theme) => theme.palette.text.primary }}>
                Описание:
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                <ul style={{ listStyle: 'none', marginBottom: '0.25rem' }}>
                  {order.products?.map(
                    (item, i) =>
                      item.comment &&
                      item.amount && (
                        <li key={i} style={{ margin: '2px 0' }}>
                          <Stack direction="row" flexWrap="wrap" alignItems="center" gap="8px">
                            <span>{`${item.comment} X ${item.amount}`}</span>
                            {item.userId !== order.ownerId && profileInfo?.uuid === order.ownerId && (
                              <Chip label={getFullName(memberByUuid[item.userId])} />
                            )}
                            {item.userId !== order.ownerId && profileInfo?.uuid !== order.ownerId && (
                              <Chip label={'Общий заказ'} />
                            )}
                          </Stack>
                        </li>
                      )
                  )}
                </ul>
              </Typography>
            </Box>

            <Stack>
              <Stack direction="row" flexWrap="wrap" gap="4px">
                <Typography variant="body2" fontWeight={600} sx={{ color: (theme) => theme.palette.text.primary }}>
                  Сумма:{' '}
                </Typography>
                <Typography variant="body2" fontWeight={600}>
                  <NumberFormat
                    value={order.totalSum}
                    suffix=" руб"
                    decimalScale={2}
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                  />
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <CustomChip
            label={order.status === 'paid' ? 'оплачен' : 'неоплачен'}
            skin="light"
            color={order.status === 'paid' ? 'success' : 'warning'}
          />
        </Stack>
      </>
    )
  );
}

export default Order;
