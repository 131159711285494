import { request } from '../utils/request';

export const projectAPIs = {
  getProject: (projectUuid) => request(`/projects?uuid=${projectUuid}`),
  getVizitsList: (projectUuid, query) =>
    request(`/internal/projects/${projectUuid}/vizits/search${query ? `?${query}` : ''}`, {
      method: 'GET',
    }),
  getSupportedUser: (projectUuid, query) =>
    request(`/internal/projects/${projectUuid}/sysusers${query ? `?${query}` : ''}`, {
      method: 'GET',
    }),
  updateSupportedUser: (projectUuid, sysuserUuid, body) =>
    request(`/internal/projects/${projectUuid}/sysusers/${sysuserUuid}`, {
      method: 'PATCH',
      body,
    }),
  deleteSupportedUser: (projectUuid, sysuserUuid) =>
    request(`/internal/projects/${projectUuid}/sysusers/${sysuserUuid}`, {
      method: 'DELETE',
    }),
  massiveActions: (projectUuid, body, isBlob) =>
    request(
      `/internal/projects/${projectUuid}/massive-action`,
      {
        method: 'POST',
        body,
      },
      false,
      isBlob
    ),
  getBDKSetting: (projectUuid) =>
    request(`/projects/${projectUuid}/bdk`, {
      method: 'GET',
    }),
  updateBDKSetting: (projectUuid, body) =>
    request(`/internal/projects/${projectUuid}/bdk`, {
      method: 'PUT',
      body,
    }),
  getScubSetting: (projectUuid) => request(`/internal/projects/${projectUuid}/scub`),
  generateBadge: (projectUuid, userUuid) =>
    request(`/internal/projects/${projectUuid}/users/${userUuid}/generate-badge`, { method: 'POST' }, false, true),
  getAvatarSizeSetting: (projectUuid) =>
    request(`/projects/${projectUuid}/avatar-size`, {
      method: 'GET',
    }),
  updateAvatarSizeSetting: (projectUuid, body) =>
    request(`/internal/projects/${projectUuid}/avatar-size`, {
      method: 'PUT',
      body,
    }),
};
