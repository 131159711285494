import { ChevronLeft, CloseOutlined } from '@mui/icons-material';
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ConfirmAppointmentModal } from '../../components/confirm-appoinment-modal';
import { LOCALIZATION, MESSAGES, TEXT } from '../../constants';
import { userAPIs } from '../../services';
import CompanySection from './component/company';
import classes from './vizits-search.module.sass';

const VizitsSearchPage = ({ projectUuid }) => {
  const { id } = useParams();
  const lang = LOCALIZATION.ru_RU;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReadyTalk, setIsReadyTalk] = useState(false);
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterTotal, setFilterTotal] = useState(0);
  const [isInit, setIsInit] = useState(false);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        companyName: '',
        field: '',
        keyWord: '',
        fullName: '',
        role: '',
      }),
      []
    ),
  });

  const onSubmit = async (values) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      var queries = {
        ...Object.fromEntries(Object.entries(values).filter(([key, value]) => value)),
      };
      if (isReadyTalk === true) {
        queries = {
          ...Object.fromEntries(Object.entries(values).filter(([key, value]) => value)),
          isReadyTalk,
        };
      }
      const query = queryString.stringify(queries);
      const res = await userAPIs.searchVizits(projectUuid, id, query);
      setResults((res.message || {}).vizits || []);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchData = async () => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      // const queries = {
      //   isReadyTalk,
      // };
      // const query = queryString.stringify(queries);
      const res = await userAPIs.searchVizits(projectUuid, id);
      const vizits = (res.message || {}).vizits || [];
      setResults(vizits);
      setTotal(vizits.length);
      setFilterTotal(vizits.length);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
      setIsInit(true);
    }
  };

  useEffect(() => {
    if (projectUuid) {
      fetchData();
    }
  }, [projectUuid]);

  const handleReset = () => {
    reset();
    setIsReadyTalk(false);
    handleSubmit(onSubmit)();
  };

  const isLocked = false;

  return (
    <div className={classes.root}>
      <Stack direction="row" alignItems="center" flexWrap="wrap" sx={{ marginBottom: '18px' }}>
        <Typography variant="h5" noWrap component="div" sx={{ mr: 2, lineHeight: '34px' }}>
          {TEXT[lang].COMPANIES_DIRECTORY}
        </Typography>
        {isInit && (
          <Typography variant="h10" noWrap component="div" sx={{ mt: '4px', lineHeight: '20px' }}>
            {TEXT[lang].TOTAL} {total || 0} {TEXT[lang].COMPANIES.toLowerCase()}
          </Typography>
        )}
      </Stack>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" gap="16px" alignItems="center" className={classes.filter}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} algin="left">
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                {...register('query')}
                placeholder={TEXT[lang].SEARCH}
                error={errors.query ? true : false}
                helperText={errors.query?.message}
                disabled={isLocked}
              />
            </Grid>
            <Grid item xs={12} sm={6} algin="left">
              <FormControlLabel
                // sx={{ display: 'flex', height: '100%', alignItems: 'center' }}
                control={<Checkbox checked={isReadyTalk} onChange={(e) => setIsReadyTalk(e.target.checked)} />}
                label={MESSAGES[lang].AVAILABLE_FOR_MEETING}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonControl}>
            <Button type="submit" className={classes.buttonShow} variant="contained">
              {TEXT[lang].SEARCH}
            </Button>
            <Button
              className={classes.buttonReset}
              variant="outlined"
              endIcon={<CloseOutlined />}
              onClick={handleReset}
            >
              {TEXT[lang].RESET}
            </Button>
          </div>
        </Stack>
      </form>

      {isSubmitting ? (
        <CircularProgress style={{ display: 'block', margin: '32px auto', color: '#872FC3' }} />
      ) : (
        <Stack gap="24px" marginTop="24px">
          {results.map((company, i) => (
            <CompanySection key={i} data={company} />
          ))}
        </Stack>
      )}

      <ConfirmAppointmentModal projectUuid={projectUuid} userUuid={id} />
    </div>
  );
};

export default VizitsSearchPage;
