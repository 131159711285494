import { Field } from 'react-final-form';
import PureInput from './pure-input';

const fieldValidator = (type, required, validate) => (value) => {
  if (required && (value === '' || value === undefined || value === null)) {
    return 'Обязательное';
  }
  if (validate) {
    return validate(value);
  }

  return undefined;
};

export default function Input({ name, placeholder, type = 'text', required, validate, defaultValue, ...other }) {
  return (
    <Field
      name={name}
      validate={fieldValidator(type, required, validate)}
      defaultValue={defaultValue}
      render={({ input, meta }) => (
        <PureInput
          placeholder={placeholder}
          type={type}
          required={required}
          error={meta.touched && (meta.error || meta.submitError)}
          {...input}
          {...other}
        />
      )}
    />
  );
}
