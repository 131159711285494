import { yupResolver } from '@hookform/resolvers/yup';
import { Download } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SelectHookForm } from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { routes } from 'src/constants';
import { LOCALIZATION, MESSAGES, PROJECT_SETTING_TABS } from 'src/utils/constant';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import classes from './ticket.module.sass';
import * as Yup from 'yup';
import { validatorMessage } from 'src/utils/formValidator';
import { useQueries, useQuery } from 'react-query';

function TicketForm({
  isAdditional,
  isRefetching,
  projectUuid,
  roleUuid,
  ticket,
  anketaFields = [],
  setShowAddNewField,
  refetchTicketData = () => { },
}) {
  const lang = LOCALIZATION.ru_RU;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState({ eng: false, ru: false });
  const [ticketFile, setTicketFile] = useState(null);
  const [replaceFields, setReplaceFields] = useState([]);
  const [ticketEnglishFile, setTicketEnglishFile] = useState(null);

  const fetchTicketFile = async (ticketUuid, s3Key) => {
    try {
      const res = await request(`/internal/tickets/${ticketUuid}?s3Key=${s3Key}`, { method: 'GET' }, true);
      const blob = new Blob([res], { type: 'image/svg+xml;charset=utf-8' });
      setTicketFile(blob);
      return blob;
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
      return null;
    }
  };

  useQueries([
    {
      queryKey: ['fetchTicketFile', ticket?.s3Key],
      queryFn: () => {
        setIsLoading((old) => ({ ...old, ru: true }));
        fetchTicketFile(ticket.uuid, ticket.s3Key)
          .then((file) => {
            setTicketFile(file);
          })
          .finally(() => setIsLoading((old) => ({ ...old, ru: false })));
      },
      staleTime: Infinity,
      enabled: Boolean(ticket && ticket.s3Key),
    },
    {
      queryKey: ['fetchTicketFile', ticket?.engS3Key],
      queryFn: () => {
        setIsLoading((old) => ({ ...old, eng: true }));
        fetchTicketFile(ticket.uuid, ticket.engS3Key)
          .then((file) => {
            setTicketEnglishFile(file);
          })
          .finally(() => setIsLoading((old) => ({ ...old, eng: false })));
      },
      staleTime: Infinity,
      enabled: Boolean(ticket && ticket.engS3Key),
    },
  ]);

  useEffect(() => {
    if (ticket) {
      setReplaceFields(Object.keys(ticket.replaceFields));
    }
  }, [ticket]);

  const handleUpload = async (e) => {
    try {
      setIsLoading((old) => ({ ...old, ru: true }));
      const file = e.target.files[0];
      if (!file) return;
      let body = new FormData();
      body.append('fileDocument', file);
      const res = await request(`/internal/tickets/fields`, { method: 'POST', body });
      setTicketFile(file);
      setReplaceFields(res.message);
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    } finally {
      setIsLoading((old) => ({ ...old, ru: false }));
    }
  };

  const handleUploadEnglishVersion = async (e) => {
    try {
      setIsLoading((old) => ({ ...old, eng: true }));
      const file = e.target.files[0];
      if (!file) return;
      let body = new FormData();
      body.append('fileDocument', file);
      const res = await request(`/internal/tickets/${ticket.uuid}/eng`, { method: 'POST', body });
      setTicketEnglishFile(file);
    } catch (error) {
      console.error(error);
      createErrorNotification('Поля в английской версии не похожи на русскую версию');
    } finally {
      setIsLoading((old) => ({ ...old, eng: false }));
    }
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => (ticket ? ticket.replaceFields : replaceFields.reduce((prev, field) => ({ ...prev, [field]: '' }), {})),
      [replaceFields, ticket]
    ),
    resolver: yupResolver(
      Yup.object().shape({
        ...(ticket ? Object.keys(ticket.replaceFields) : replaceFields).reduce(
          (prev, field) => ({ ...prev, [field]: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD) }),
          {}
        ),
      })
    ),
  });

  const handleSubmitTicket = async (values) => {
    try {
      let body = new FormData();
      body.append('fileDocument', ticketFile);
      const newData = replaceFields.reduce((prev, field) => ({ ...prev, [field]: values[field] }), {});
      if (ticket) {
        body.append('replaceFields', JSON.stringify({ ...newData }));
        await request(`/internal/tickets/${ticket.uuid}`, { method: 'PATCH', body });
      } else {
        body.append(
          'body',
          JSON.stringify({ projectUuid, roleUuid, isAdditional: isAdditional, replaceFields: { ...newData } })
        );
        await request(`/internal/tickets`, { method: 'POST', body });
      }
      createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY, 'success');
      refetchTicketData();
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  const ticketFileURL = useMemo(() => (ticketFile ? URL.createObjectURL(ticketFile) : ''), [ticketFile]);
  const ticketEnglishFileURL = useMemo(
    () => (ticketEnglishFile ? URL.createObjectURL(ticketEnglishFile) : ''),
    [ticketEnglishFile]
  );
  const hasAnketaFields = anketaFields.length;

  return (
    <form onSubmit={handleSubmit(handleSubmitTicket)}>
      <Stack gap="30px">
        {!!hasAnketaFields && (
          <Grid container spacing={8} sx={{ textAlign: 'center' }}>
            <Grid item xs={12} md={3}>
              <Stack alignItems="center">
                <FormControl margin="normal">
                  <FormLabel>{isAdditional ? 'Русская версия бейджа' : 'Русская версия билета'}:</FormLabel>
                  <Stack gap="8px" marginY="4px" alignItems="center">
                    <Button
                      startIcon={<AttachFileIcon />}
                      variant="contained"
                      component="label"
                      sx={{ width: 'fit-content' }}
                      disabled={isLoading.ru}
                    >
                      Загрузить
                      <input hidden accept=".svg" type="file" onChange={handleUpload} />
                    </Button>
                  </Stack>
                </FormControl>
                {ticket && (
                  <FormControl margin="normal">
                    <FormLabel>{isAdditional ? 'Английская версия бейджа' : 'Английская версия билета'}:</FormLabel>
                    <Stack gap="8px" marginY="4px" alignItems="center">
                      <Button
                        startIcon={<AttachFileIcon />}
                        variant="contained"
                        component="label"
                        sx={{ width: 'fit-content' }}
                        disabled={isLoading.eng}
                      >
                        Загрузить
                        <input hidden accept=".svg" type="file" onChange={handleUploadEnglishVersion} />
                      </Button>
                    </Stack>
                  </FormControl>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack alignItems="center">
                <FormControl margin="normal">
                  <FormLabel>{isAdditional ? 'Русская версия бейджа' : 'Русская версия билета'}:</FormLabel>
                  <Stack gap="8px" marginY="4px" alignItems="center">
                    {isLoading.ru ? (
                      <Box sx={{ width: 'fit-content' }}>
                        <Loader size="20px" />
                      </Box>
                    ) : ticketFileURL ? (
                      <Link href={ticketFileURL} target="_blank" width="fit-content">
                        <img src={ticketFileURL} className={classes.document} />
                      </Link>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack alignItems="center">
                <FormControl margin="normal">
                  <FormLabel>{isAdditional ? 'Английская версия бейджа' : 'Английская версия билета'}:</FormLabel>
                  <Stack gap="8px" marginY="4px" alignItems="center">
                    {isLoading.eng ? (
                      <Box sx={{ width: 'fit-content' }}>
                        <Loader size="20px" />
                      </Box>
                    ) : ticketEnglishFileURL ? (
                      <Link href={ticketEnglishFileURL} target="_blank" width="fit-content">
                        <img src={ticketEnglishFileURL} className={classes.document} />
                      </Link>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack alignItems="center">
                <FormControl margin="normal">
                  <FormLabel>Пример файла:</FormLabel>
                  <Stack gap="8px" marginY="4px" alignItems="center">
                    <Tooltip title="Нажмите, чтобы скачать">
                      <Link download href="/documents/example.svg" className={classes.exampleFile}>
                        <img src="/documents/example.svg" className={classes.document} />
                        <IconButton className={classes.iconDownload}>
                          <Download sx={{ fontSize: '32px' }} color="primary" />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </Stack>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        )}

        {isLoading.ru || isLoading.eng ? (
          <Loader />
        ) : (
          <>
            {replaceFields.length > 0 && <Divider />}
            {!hasAnketaFields && (
              <Typography style={{ textAlign: 'center' }}>
                ** У этой роли еще не было шаблона анкеты.{' '}
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(
                      routes.projectSettings.path.replace(':uuid', projectUuid) +
                      `?tab=${PROJECT_SETTING_TABS.anketa.key}`,
                      {
                        replace: true,
                        state: {
                          defaultRoleUuid: roleUuid,
                        },
                      }
                    );
                  }}
                >
                  Создайте, пожалуйста
                </Link>
              </Typography>
            )}

            <Stack gap="20px" width={{ xs: '100%', md: '70%' }} sx={{ margin: 'auto' }}>
              {replaceFields.map((replaceField) => (
                <Controller
                  key={replaceField}
                  name={replaceField}
                  control={control}
                  render={({ field }) => (
                    <SelectHookForm
                      required
                      label={replaceField}
                      selectProps={{ value: field.value, onChange: field.onChange }}
                      error={errors[replaceField]}
                      errorComponent={
                        <>
                          {errors[replaceField] && (
                            <FormHelperText error={true}>{errors[replaceField]?.message}</FormHelperText>
                          )}
                        </>
                      }
                    >
                      {anketaFields
                        .concat([
                          { uuid: 'barcode', AnketaFieldSettings: { name: 'Штрих-код' } },
                          { uuid: 'ticket_number', AnketaFieldSettings: { name: 'Номер билета' } },
                          { uuid: 'no_data', AnketaFieldSettings: { name: 'Нет данных' } },
                        ])
                        .map((option, i) => (
                          <MenuItem key={`${replaceField}-${i}`} value={option.uuid}>
                            <>{option?.AnketaFieldSettings?.name}</>
                          </MenuItem>
                        ))}
                    </SelectHookForm>
                  )}
                />
              ))}
              {ticketFile && (
                <Box>
                  <Grid container spacing={7.5}>
                    <Grid item xs={12} md={6}>
                      <Button fullWidth variant="outlined" size="large" onClick={() => setShowAddNewField(false)}>
                        Отмена
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button fullWidth variant="contained" size="large" type="submit" disabled={isRefetching}>
                        Сохранить
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </form>
  );
}

export default TicketForm;
