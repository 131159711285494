import { useState } from 'react';
import { Slider, styled, Typography } from '@mui/material';
import { format, addDays } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'src/constants';

const MuiSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.primary.main,
    '&:before': {
      display: 'none',
    },
    // '& *': {
    //   background: 'transparent',
    //   color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    // },
  },
  //   '& .MuiSlider-thumb': { width: '0' },
  //   '& .MuiSlider-track': { border: 'none', borderRadius: '0', background: theme.palette.primary.main },
  //   '& .MuiSlider-rail': { backgroundColor: theme.palette.primary.main },
}));

export const DATE_FORMAT = 'dd.MM.yyyy';

function DateRangeSlider({ startDate, endDate, value, onChange }) {
  const [sliderValue, setSliderValue] = useState(value);

  const formatDate = (date) => {
    return format(date, DATE_FORMAT);
  };

  const marks = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    marks.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
    // onChange(newValue);
  };

  const min = startDate?.getTime();
  const max = endDate?.getTime();

  return (
    <MuiSlider
      value={sliderValue}
      valueLabelFormat={(value) => {
        return (
          <Typography variant="body2" sx={{ fontSize: '12px', color: (theme) => theme.palette.primary.main }}>
            {value === min
              ? min === 0
                ? ''
                : format(value, DATE_FORMAT)
              : max === 100
              ? ''
              : format(value, DATE_FORMAT)}
          </Typography>
        );
      }}
      onChange={handleChange}
      onChangeCommitted={(e, newValue) => onChange(newValue)}
      valueLabelDisplay="on"
      min={min}
      max={max}
      step={86400000}
      //   marks={marks.map((date) => ({
      //     value: date.getTime(),
      //     label: formatDate(date),
      //   }))}
    />
  );
}

export default DateRangeSlider;
