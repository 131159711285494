import ClockIcon from 'src/icon/clock';
import { LOCALIZATION } from './localization';

export { MESSAGES, TEXT, LOCALIZATION } from './localization';

export const HOST_URL =
  process.env.REACT_APP_SERVER_URL === 'https://admin.pro-firpo.ru'
    ? 'https://lk.pro-firpo.ru'
    : 'https://lk.pro-firpo.ru';

export const EVENTS = {
  OPEN_ALERT_FORM: 'open-alert-form',
  OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
  OPEN_CONFIRM_L2_MODAL: 'OPEN_CONFIRM_L2_MODAL',
  OPEN_CONFIRM_APPOINTMENT_MODAL: 'OPEN_CONFIRM_APPOINTMENT_MODAL',
  OPEN_VIEW_IMAGE_MODAL: 'OPEN_VIEW_IMAGE_MODAL',
  OPEN_PACKAGE_DETAIL_MODAL: 'OPEN_PACKAGE_DETAIL_MODAL',
  OPEN_FORGOT_PASSWORD_FORM: 'OPEN_FORGOT_PASSWORD_FORM',
  OPEN_CHANGE_PASSWORD_FORM: 'OPEN_CHANGE_PASSWORD_FORM',
  RELOAD_PRODUCTS: 'RELOAD_PRODUCTS',
  OPEN_MEMBERS_MODAL: 'OPEN_MEMBERS_MODAL',
  OPEN_PROGRAM_DETAIL_MODAL: 'OPEN_PROGRAM_DETAIL_MODAL',
  OPEN_COMPANY_MODAL: 'OPEN_COMPANY_MODAL',
  OPEN_CONFIRM_UPDATE_PROJECT_MODAL: 'OPEN_CONFIRM_UPDATE_PROJECT_MODAL',
  OPEN_CONFIRM_SYNC_PROJECT_MODAL: 'OPEN_CONFIRM_SYNC_PROJECT_MODAL',
  OPEN_IMAGE_CROPPER_MODAL: 'OPEN_IMAGE_CROPPER_MODAL',
  OPEN_BADGE_FILE_MODAL: 'OPEN_BADGE_FILE_MODAL',
  OPEN_VIEW_PDF_MODAL: 'OPEN_VIEW_PDF_MODAL',
};

export const STANDARD_DATE_FORMAT = 'DD-MM-YYYY';
export const STANDARD_TIME_DATE_FORMAT = 'HH:mm DD/MM/YYYY';
export const DEFAULT_BIRTH_DAY = '1990-01-01';

export const ENDPOINT = {
  AUTH: {
    BASE: '/auth',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    REFRESH: '/refresh',
    FORGOT_PASSWORD: '/forgot-password',
    CHECK_EMAIL: '/check-email',
  },
  USER: {
    BASE: '/users',
    CURRENT: '/current',
    AVATAR: '/avatar',
    PASSPORT: '/passport',
    PASSPORT_FILE: '/passport-file',
    PASSPORT_DATA: '/passport-data',
    PROJECTS: '/projects',
    PASSWORD: '/password',
    COMPANY: '/company',
    ORDERS: '/orders',
    ARCHIVED_ORDERS: '/archived_orders',
    DOCUMENTS: '/documents',
    MEMBERS: '/members',
  },
  COUNTRIES: {
    BASE: '/countries',
  },
  ROLES: {
    BASE: '/roles',
  },
  PROJECTS: {
    CURRENT: '/current',
    INTERNAL: '/internal',
    BASE: '/projects',
    JOIN: '/join',
    LEAVE: '/leave',
    PACKAGES: '/packages',
    PHOTOS: '/photos',
    SERVICES: '/services',
    VIZITS: '/vizits',
    MEMBERS: '/members',
  },
};

export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const IMAGE_FILE_TYPES = ['JPG', 'PNG', 'GIF', 'JPEG'];
export const REGEX_E164_PHONE = /^$|^\+[1-9]\d{1,14}$/;

export const GENDER_OPTIONS = {
  man: {
    [LOCALIZATION.ru_RU]: 'Мужчина',
    [LOCALIZATION.en_US]: 'Man',
  },
  woman: {
    [LOCALIZATION.ru_RU]: 'Женщина',
    [LOCALIZATION.en_US]: 'Woman',
  },
};

export const STATUS_ACCREDIATION = {
  null: {
    label: {
      [LOCALIZATION.ru_RU]: 'обработка',
      [LOCALIZATION.en_US]: 'in process',
    },
    color: 'gray',
  },
  true: {
    label: {
      [LOCALIZATION.ru_RU]: 'одобрено',
      [LOCALIZATION.en_US]: 'approved',
    },
    color: 'green',
  },
  false: {
    label: {
      [LOCALIZATION.ru_RU]: 'не одобрено',
      [LOCALIZATION.en_US]: 'declined',
    },
    color: 'red',
  },
};

export const STATUS_PAYMENT = {
  undetermined: {
    label: {
      [LOCALIZATION.ru_RU]: 'Неоплачен',
      [LOCALIZATION.en_US]: 'Unpaid',
    },
    color: 'gray',
  },
  confirmed: {
    label: {
      [LOCALIZATION.ru_RU]: 'Создан',
      [LOCALIZATION.en_US]: 'Create',
    },
    color: 'gray',
  },
  approved: {
    label: {
      [LOCALIZATION.ru_RU]: 'Подтверждён',
      [LOCALIZATION.en_US]: 'Approved',
    },
    color: 'gray',
  },
  paid: {
    label: {
      [LOCALIZATION.ru_RU]: 'Оплачен',
      [LOCALIZATION.en_US]: 'Paid',
    },
    color: 'green',
  },
  failed: {
    label: {
      [LOCALIZATION.ru_RU]: 'Не найден',
      [LOCALIZATION.en_US]: 'Failed',
    },
    color: 'gray',
  },
};

export const COMPANY_TYPE_OPTIONS = {
  LEGAL: {
    [LOCALIZATION.ru_RU]: 'Юридическое лицо',
    [LOCALIZATION.en_US]: 'Legal',
  },
  NONRESIDENT: {
    [LOCALIZATION.ru_RU]: 'Не резидент',
    [LOCALIZATION.en_US]: 'Non-resident',
  },
  BRANCH: {
    [LOCALIZATION.ru_RU]: 'Обособленное подразделение',
    [LOCALIZATION.en_US]: 'Branch',
  },
  PERSON: {
    [LOCALIZATION.ru_RU]: 'Физическое лицо',
    [LOCALIZATION.en_US]: 'Person',
  },
};

export const PAYMENT_METHODS = {
  bankCard: {
    label: {
      [LOCALIZATION.ru_RU]: 'Оплата банковской картой',
      [LOCALIZATION.en_US]: 'Pay by card',
    },
    value: 'order_by_card',
  },
  bankAccount: {
    label: {
      [LOCALIZATION.ru_RU]: 'Оплата по счёту',
      [LOCALIZATION.en_US]: 'Pay by invoice',
    },
    value: 'order_by_company',
  },
};

export const MEETING_STATUS = {
  approved: {
    [LOCALIZATION.ru_RU]: 'подтверждена',
    [LOCALIZATION.en_US]: 'approved',
    icon: 'mdi:account-outline',
  },
  initial: {
    [LOCALIZATION.ru_RU]: 'ожидает',
    [LOCALIZATION.en_US]: 'initial',
    customIcon: <ClockIcon />,
  },
  declined: {
    [LOCALIZATION.ru_RU]: 'отменена',
    [LOCALIZATION.en_US]: 'declined',
    icon: 'mdi:close',
  },
  hold: {
    [LOCALIZATION.ru_RU]: 'в процессе',
    [LOCALIZATION.en_US]: 'in progress',
  },
  done: {
    [LOCALIZATION.ru_RU]: 'проведена',
    [LOCALIZATION.en_US]: 'done',
  },
};

export const PROJECT_SETTING_TABS = {
  data: { key: 'data', label: 'данные проекта' },
  anketa: { key: 'anketa', label: 'конструктор анкеты' },
  ticket: { key: 'ticket', label: 'дизайн билета' },
  badge: { key: 'badge', label: 'дизайн бейджа' },
  user: { key: 'user', label: 'добавить пользователей' },
  email: { key: 'email', label: 'Настройка писем' },
  bdk: { key: 'bdk', label: 'настройки BDK' },
  infoFiles: { key: 'infoFiles', label: 'информационные файлы' },
  avatarSize: { key: 'avatarSize', label: 'Настройка размера' },
  changes: { key: 'changes', label: 'История изменений' },
};

export const DOCUMENT_TYPES = {
  AVATAR: 'photo',
  PASSPORT: 'international_passport',
  CONFIRMATION_LETTER: 'confirmation_letter',
};

export const LANGUAGES = [
  {
    lang: LOCALIZATION.ru_RU,
    name: 'Ru',
    flag: '/flags/russia.svg',
  },
  {
    lang: LOCALIZATION.en_US,
    name: 'En',
    flag: '/flags/united-kingdom.svg',
  },
];

export const DEFAULT_UTC_OFFSET = 3;

export const PRIORITY_DOCUMENT_TYPE = {
  photo: 4,
  international_passport: 3,
  confirmation_letter: 2,
  invoice: 1,
};

export const ORDER_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const INTERNAL_DOCUMENTS = {
  AGREEMENT: '/documents/Согласие.pdf',
};

export const ADD_MEMBER_METHODS = {
  INVITATION: 'registration_by_invitation',
  INVITATION_AND_INTERNAL: 'registration_by_invitation_and_internal',
};

export const ADD_MEMBER_METHODS_OPTIONS = [
  { label: 'Регистрация на зонтичное мероприятие', value: ADD_MEMBER_METHODS.INVITATION },
  { label: 'Регистрация на мероприятие', value: ADD_MEMBER_METHODS.INVITATION_AND_INTERNAL },
];
