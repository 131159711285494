import CustomChip from 'src/@core/components/mui/chip';

const getStatusLabel = (status) => {
  switch (status) {
    case 'active':
      return 'Активно';
    case 'expired':
      return 'Просрочено';
    default:
      return 'Заблокировано';
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return 'success';
    default:
      return 'warning';
  }
};

export default function SupportedUserStatus({ status }) {
  const label = getStatusLabel(status);
  const color = getStatusColor(status);
  return <CustomChip label={label} skin="light" color={color} />;
}
