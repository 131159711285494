import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { getDocumentFile } from '../../store/actions/application';
import { getDateForFile } from '../../utils/dates';
import { downloadBlob } from '../../utils/request';
import Loader from '../loader';
import ProfileField from '../profile-field';
import classes from './document.module.sass';

const imageTypes = [
  { type: 'image/jpeg', ext: 'jpeg' },
  { type: 'image/png', ext: 'png' },
];
const fileTypes = [{ type: 'application/pdf', ext: 'pdf' }];

const getExt = (type) => {
  const img = imageTypes.find((i) => i.type === type);
  const file = fileTypes.find((i) => i.type === type);
  return img?.ext || file?.ext;
};

export default function Document({ document, userName }) {
  const [file, setFile] = useState(null);
  useEffect(() => {
    getDocumentFile(document?.uuid).then((res) => {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(res);
      setFile({
        url: imageUrl,
        type: res.type,
        blob: res,
      });
    });
  }, [document]);
  const ext = getExt(file?.type);
  const name = `${userName}-${getDateForFile()}.${ext}`;
  const props = document.properties || {};

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        {!file && <Loader />}
        {file && imageTypes.some((i) => i.type === file?.type) && (
          <div className={classes.image} style={{ backgroundImage: `url(${file.url})` }} />
        )}
        {file && fileTypes.some((f) => f.type === file.type) && (
          <div className={classes.pdf}>
            <i className="bi bi-filetype-pdf" />
          </div>
        )}
        {file && (
          <div className={classes.download} onClick={() => downloadBlob(file.blob, name)}>
            <i className="bi bi-download" />
          </div>
        )}
      </div>
      {document.type == 'photo' && <Typography variant="body1">Аватар</Typography>}
      {document.type == 'confirmation_letter' && <Typography variant="body1">Билет</Typography>}
      {document.type == 'invoice' && (
        <div className={classes.info}>
          <div style={{ marginBottom: '0.25rem' }}>Инвойс {moment.unix(document.createdAt).format('DD/MM/YYYY')}</div>
          {document.properties?.order && (
            <>
              <ProfileField label="Описание">
                <ul style={{ listStyle: 'none', marginBottom: '0.25rem' }}>
                  {document.properties?.order?.Products?.map((item, i) => (
                    <li key={i}>{`${item.CommentRU} X ${item.Amount}`}</li>
                  ))}
                </ul>
              </ProfileField>
              <ProfileField label="Сумма">
                <NumberFormat
                  value={document.properties?.order?.TotalSum}
                  suffix=" руб"
                  decimalScale={2}
                  displayType="text"
                  thousandSeparator=" "
                  decimalSeparator=","
                />
              </ProfileField>
            </>
          )}
        </div>
      )}
      {Object.keys(props).length !== 0 && document.type == 'international_passport' && (
        <Stack>
          <ProfileField label="Имя">
            {props.name} {props.surname}
          </ProfileField>
          <ProfileField label="Имя (транслит)">
            {props.first_name_translit} {props.last_name_translit}
          </ProfileField>
          <ProfileField label="Номер паспорта">{props.document_number}</ProfileField>
        </Stack>
      )}
    </div>
  );
}
