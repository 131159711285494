import { dispatch } from '..';
import { DOCUMENT_TYPES } from '../../constants';
import { userAPIs } from '../../services';
import { request } from '../../utils/request';
import * as constants from '../constants/vizit';

export const getUserVizitByProject = async (projectUuid, userUuid) => {
  try {
    const response = await request(`/internal/projects/${projectUuid}/users/${userUuid}/vizits`, {
      method: 'GET',
    });
    const user = response.message;
    dispatch({ type: constants.VIZIT__SET_USER, user });
    return user;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getMembersVizitByProject = async (projectUuid, userUuid) => {
  try {
    const response = await request(`/internal/projects/${projectUuid}/users/${userUuid}/members`, {
      method: 'GET',
    });
    const _members = response.message || [];
    const avatars = await Promise.all(
      _members.map((m) => getDocumentOfMember(m.userUuid, DOCUMENT_TYPES.AVATAR, projectUuid))
    );
    const members = _members.map((m, i) => ({
      ...m,
      avatarFile: avatars[i],
    }));
    dispatch({ type: constants.VIZIT__SET_MEMBERS, members });
    return members;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getDocumentOfMember = async (memberUuid, type, projectUuid) => {
  try {
    const document = await userAPIs.getDocumentMember(memberUuid, type, projectUuid);
    return document;
  } catch (error) {
    console.log(error);
    return false;
  }
};
