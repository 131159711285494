import useMediaQuery from '@mui/material/useMediaQuery';

// ** Layout Imports
// !Do not remove this Layout import
import Layout from 'src/@core/layouts/Layout';

// ** Navigation Imports
import VerticalNavItems, { getProjectExtraNavItems } from 'src/navigation/vertical';
import VerticalAppBarContent from './components/vertical/AppBarContent';

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings';
import CopyBadgeIdProvider from './providers/CopyBadgeIdProvider';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useAbility } from 'src/hooks/useAbility';
import { useLocation, useParams } from 'react-router-dom';
import { useProjectDetail } from 'src/hooks';
import queryString from 'query-string';

const UserLayout = ({
  children,
  allowCopyBadgeID,
  contentHeightFixed,
  showExtraNavItems,
  hideSidebar,
  useProjectUuidQuery,
}) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings();
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const ability = useAbility();

  const isStaff = useSelector((state) => state.auth.isStaff);
  const currentProject = useSelector((state) => state.application.currentProject);
  const { uuid } = useParams();
  const location = useLocation();
  const projectUuidQuery = queryString.parse(location.search)?.projectUuid;

  const { data: projectData } = useProjectDetail({ projectUuid: useProjectUuidQuery ? projectUuidQuery : uuid });

  const extraNavItems = useMemo(
    () =>
      showExtraNavItems
        ? projectData
          ? getProjectExtraNavItems(projectData, ability)
          : currentProject
          ? getProjectExtraNavItems(currentProject, ability)
          : []
        : [],
    [projectData, uuid, ability, currentProject, showExtraNavItems]
  );

  return (
    <CopyBadgeIdProvider allowCopyBadgeID={allowCopyBadgeID}>
      <Layout
        hidden={hidden}
        hideSidebar={hideSidebar}
        settings={settings}
        saveSettings={saveSettings}
        contentHeightFixed={contentHeightFixed}
        verticalLayoutProps={{
          navMenu: {
            navItems: (isStaff ? [] : VerticalNavItems()).concat(extraNavItems),
          },
          appBar: {
            content: (props) => (
              <VerticalAppBarContent
                hidden={hidden}
                hideSidebar={hideSidebar}
                settings={settings}
                saveSettings={saveSettings}
                toggleNavVisibility={props.toggleNavVisibility}
              />
            ),
          },
        }}
      >
        {children}
      </Layout>
    </CopyBadgeIdProvider>
  );
};

export default UserLayout;
