import { TextField } from '@mui/material';
import cx from 'classnames';
import classes from './input.module.sass';

export default function PureInput({
  className,
  shrink = true,
  type = 'text',
  label,
  disabled = false,
  error,
  ...other
}) {
  return (
    // <div className={cx(className, classes.container)}>
    //   {label && <label>{label}</label>}
    //   <input type={type} className={classes.input} disabled={disabled} {...other} />
    //   {error && <span className={classes.error}>{error}</span>}
    // </div>
    <TextField
      fullWidth
      type={type}
      label={label}
      InputLabelProps={{ shrink: shrink ? shrink : undefined }}
      error={error}
      disabled={disabled}
      {...other}
    />
  );
}
