import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteMeeting, updateMeetingStatus, updateUserByProjectUuid } from 'src/store/actions/application';
import eventBus from 'src/utils/eventBus';
import { EVENTS, MEETING_STATUS } from 'src/utils/constant';
import { LOCALIZATION } from 'src/constants';
import UserIcon from 'src/layouts/components/UserIcon';

function ActionsButton({ projectUuid, item = {}, reloadTable = () => {} }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeclineMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        updateMeetingStatus(projectUuid, meeting, MEETING_STATUS.declined[LOCALIZATION.en_US]);
      },
    });
  };

  const handleApproveMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    updateMeetingStatus(projectUuid, meeting, MEETING_STATUS.approved[LOCALIZATION.en_US]);
  };

  const handleDeleteMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteMeeting(projectUuid, meeting.uuid, meeting.status);
      },
    });
  };

  const options = [
    ...(item.status !== MEETING_STATUS.approved[LOCALIZATION.en_US]
      ? [
          {
            label: 'Принимать',
            icon: 'mdi:check',
            //   disabled: item.isAccredited !== null,
            onClick: (e) => {
              handleApproveMeeting(e, item);
            },
          },
        ]
      : []),
    ...(item.status !== MEETING_STATUS.declined[LOCALIZATION.en_US]
      ? [
          {
            label: 'Отклонить',
            icon: 'mdi:close',
            //   disabled: item.isAccredited !== null,
            onClick: (e) => {
              handleDeclineMeeting(e, item);
            },
          },
        ]
      : []),
    {
      label: 'Удалить',
      icon: 'mdi:trash-can-outline',
      onClick: (e) => {
        handleDeleteMeeting(e, item);
      },
    },
  ];

  return (
    <Box>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.label} disabled={option.disabled} onClick={option.onClick}>
            <Stack direction="row" gap="8px">
              <UserIcon icon={option.icon} />
              <Typography variant="body1">{option.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default ActionsButton;
