import classes from './setting.mfa.module.sass';
import GeneralLayout from '../../components/general-layout';
import Title from '../../components/title';
import * as React from 'react';
import Button from '../../components/button';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { settingMfa } from '../../store/actions/application';
import Input from '../../components/input';
import { Form } from 'react-final-form';
import { checkMfa } from '../../store/actions/auth';


export default function SettingMfaPage() {
    const qrCode = useSelector((state) => state.application.qrCode);
    return (
        <GeneralLayout className={classes.root} container>
            <div className={classes.head}>
                <Title>Настройки МФА</Title>
            </div>
            <body>
                <Container className={classes.container}>
                    <Row>
                        <Col>
                            <p className={classes.text}>Вы еще не подключили МФА. Включите МФА для повышения безопасности</p>
                        </Col>
                        <Col>
                            <Button
                                type="submit"
                                variant="dark"
                                size="lg"
                                onClick={settingMfa}
                            >Подключить</Button>
                        </Col>
                    </Row>
                    {qrCode &&
                        <Row>
                            <Col>
                                <Row>
                                    <div>
                                        <p><strong>Инструкция</strong></p>
                                        <p>1. Скачайте <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank">Google Authenticator</a> on your mobile.</p>
                                        <p>2. Сканируйте QR-код чтобы установить ключ</p>
                                    </div>
                                </Row>
                                <Row>
                                    <img className={classes.qr} src={qrCode} />
                                </Row>
                            </Col>
                            <Col>
                                <Form onSubmit={checkMfa}>
                                    {({ handleSubmit, submitError, submitting }) => (
                                        <div className={classes.main}>
                                            <form onSubmit={handleSubmit} className={classes.form}>
                                                <Col>
                                                    <Row xs={12} lg={4}>
                                                        <Input
                                                            className={classes.input}
                                                            name="otp"
                                                            type="text"
                                                            placeholder="Код"
                                                        />
                                                    </Row>
                                                    <Row xs={12} lg={4} className={classes.noGutter}>
                                                        <Button
                                                            type="submit"
                                                            variant="dark"
                                                            size="lg"
                                                            isLoading={submitting}
                                                            className={classes.submit}
                                                        >
                                                            Проверить
                                                        </Button>
                                                    </Row>
                                                    <Row>
                                                        {submitError && <div className={classes.error}>{submitError}</div>}
                                                    </Row>
                                                </Col>
                                            </form>
                                        </div>
                                    )}
                                </Form>
                            </Col>
                        </Row>
                    }
                </Container>
            </body>
        </GeneralLayout>
    );
}
