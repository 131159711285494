import { Button, Grid, Stack } from '@mui/material';
import qs from 'query-string';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Input from 'src/components/input';
import classes from './filters.module.sass';

export default function UserFilters({ onSubmit }) {
  const location = useLocation();

  const roles = useSelector((state) => state.application.roles);

  const query = qs.parse(location.search);
  const initialValues = {
    ...query,
    mainRoleId: roles.find((c) => c.id === query.mainRoleId)?.id,
  };
  const handleSubmit = (filters) => {
    return onSubmit(Object.keys(filters).reduce((p, c) => (filters[c] ? { ...p, [c]: filters[c] } : p), {}));
  };
  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.filters}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Stack gap={6}>
                <Input name="lastName" label="ФАМИЛИЯ" />
                <Input name="firstName" label="ИМЯ" />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack gap={6}>
                <Input name="middleName" label="ОТЧЕСТВО" />
                <Input name="email" label="Email" type="email" />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => {
                  form.restart();
                  onSubmit({});
                }}
              >
                Очистить фильтры
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button type="submit" variant="contained" className={classes.button}>
                Искать
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
}
