import { ChevronLeft } from '@mui/icons-material';
import { Card, CardContent, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
import Loader from 'src/components/loader';
import VizitSection from 'src/components/vizit-section';
import AddMemberSection from 'src/components/vizit-section/AddMemberSection';
import { routes } from 'src/constants';
import UserLayout from 'src/layouts/UserLayout';
import { setProject } from 'src/store/actions/application';
import { request } from 'src/utils/request';

function VizitDetailPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [userUuid, setUserUuid] = useState('');
  const [membersVizits, setMembersVizits] = useState([]);
  const [vizit, setVizit] = useState(null);

  const projectUuid = params.uuid;
  const vizitUuid = params.vizitUuid;

  const fetchProjectData = async () => {
    const response = await request(`/projects?uuid=${projectUuid}`);
    const currentProject = response.message?.[0];
    setProject(currentProject);
    return currentProject;
  };

  const { isLoading, data } = useQuery('fetchProjectDetail', fetchProjectData);

  const fetchVizitDetail = async ({ queryKey }) => {
    const [_, projectUuid, vizitUuid] = queryKey;
    const response = await request(`//internal/projects/${projectUuid}/vizits/search?vizitUuid=${vizitUuid}`);
    const vizitDetail = response.message.vizits?.[0];
    if (vizitDetail) {
      setVizit(vizitDetail);
      setUserUuid(vizitDetail.ownerUuid);
      setMembersVizits(vizitDetail.members);
    }
    return vizitDetail;
  };

  const {
    isLoading: isLoadingVizitDetail,
    data: vizitDetail,
    refetch: refetchVizitDetail,
    isFetching: isFetchingVizitDetail,
  } = useQuery(['fetchVizitDetail', projectUuid, vizitUuid], fetchVizitDetail, {
    enabled: !!projectUuid && !!vizitUuid,
  });

  const handleGotoList = () => {
    navigate(routes.projectVizits.path.replace(':uuid', data?.uuid));
  };

  return (
    <UserLayout showExtraNavItems={true}>
      <Card>
        <CardContent>
          {isLoading || isLoadingVizitDetail || isFetchingVizitDetail ? (
            <Loader />
          ) : (
            <Stack gap="20px">
              <Stack direction="row" flexWrap="wrap" gap="8px" alignItems="center">
                <IconButton onClick={handleGotoList}>
                  <ChevronLeft />
                </IconButton>
                <Typography variant="h6">{vizitDetail?.name}</Typography>
              </Stack>

              {userUuid && (
                <>
                  {data?.uuid && (
                    <Stack gap="20px">
                      <VizitSection
                        projectUuid={data.uuid}
                        userUuid={userUuid}
                        isLoadingVizit={isLoadingVizitDetail}
                        vizits={vizit}
                        setVizits={setVizit}
                        onDeletedVizit={handleGotoList}
                        onReloadVizit={refetchVizitDetail}
                        setMembersVizits={setMembersVizits}
                      />
                      <Divider />
                      <AddMemberSection
                        projectUuid={data.uuid}
                        userUuid={userUuid}
                        isLoadingVizit={isLoadingVizitDetail}
                        vizits={vizitDetail}
                        setVizits={() => refetchVizitDetail()}
                        membersVizits={membersVizits}
                        setMembersVizits={setMembersVizits}
                      />
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          )}
        </CardContent>
      </Card>
      <ConfirmModal />
    </UserLayout>
  );
}

export default VizitDetailPage;
