import ButtonLoading from 'src/components/button-loading';

function IssueBadgeButton({ user, disabled, onClick }) {
    const isBadgePrinted = user?.isBadgePrinted;

    return (
        <ButtonLoading variant="outlined" disabled={disabled} onClick={onClick}>
            {isBadgePrinted ? 'Вернуть бейдж' : 'Выдать бейдж'}
        </ButtonLoading>
    );
}

export default IssueBadgeButton;
