import { useQuery } from 'react-query';
import { projectAPIs } from 'src/services';
import { setProjectSettings } from 'src/store/actions/application';

function useProjectSettings({ projectUuid }) {
  const fetchAvatarSize = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await projectAPIs.getAvatarSizeSetting(projectUuid);
    const avatarSize = res.message;
    setProjectSettings({ avatarSize: avatarSize });
    return avatarSize;
  };

  useQuery(['fetchProjectBDK', projectUuid], fetchAvatarSize, {
    enabled: !!projectUuid,
  });

  return {};
}

export default useProjectSettings;
