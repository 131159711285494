import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Status from 'src/components/status';
import CompanyStatus from 'src/components/status/CompanyStatus';
import VizitStatus from 'src/components/status/VizitStatus';
import TableActionButton from 'src/components/table-action-button';
import { LOCALIZATION, routes } from 'src/constants';
import UserIcon from 'src/layouts/components/UserIcon';
import { userAPIs } from 'src/services';
import { MESSAGES } from 'src/utils/constant';
import eventBus, { EVENTS } from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import ActionsButton from './ActionsButton';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'название',
  },
  {
    id: 'web',
    numeric: false,
    disablePadding: false,
    label: 'сеть',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'статус',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: '',
    width: '50px',
  },
];

function VizitsList({ projectUuid, loading, allTabs, queryParams = {}, setQueryParams, onReloadTable }) {
  const lang = LOCALIZATION.ru_RU;
  const navigate = useNavigate();
  const theme = useTheme();
  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);
  const currentProject = useSelector((state) => state.application.currentProject);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { page: queryPage, limit, query } = queryParams;
  const tab = queryParams.tab || allTabs.all.name;
  const rowsPerPage = parseInt(limit) || 20;
  const page = Math.max(queryPage || 1, 1);

  const handleChangePage = (event, newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({ ...queryParams, page: 1, limit: parseInt(event.target.value, 10) });
  };

  const { reset, register, getValues, handleSubmit } = useForm({
    defaultValues: { query: queryParams?.query || '' },
  });

  const handleSearch = ({ query }) => {
    if (query && query.trim()) {
      setQueryParams({ ...queryParams, query: query });
    } else {
      let _queryParams = queryParams;
      delete _queryParams.query;
      setQueryParams(_queryParams);
    }
  };

  const deleteUserVizit = async (vizit) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await userAPIs.deleteUserVizit(projectUuid, vizit.ownerUuid);
      onReloadTable();
      createNotification(MESSAGES[lang].DELETE_SUCCESSFULLY);
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setTimeout(() => setIsSubmitting(false), 200);
    }
  };

  const onDeleteVizit = (vizit) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteUserVizit(vizit);
      },
    });
  };

  const onChangeVizitStatus = async (vizit, status) => {
    if (isSubmitting || !status) return;
    setIsSubmitting(true);
    try {
      await userAPIs.updateVizitStatus(projectUuid, vizit.uuid, status);
      onReloadTable();
      createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
    } catch (e) {
      console.log(e);
      createErrorNotification(e.message?.error || MESSAGES[lang].ERROR);
    } finally {
      setTimeout(() => setIsSubmitting(false), 200);
    }
  };

  return (
    <Stack>
      <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap" padding="20px">
        <Stack direction="row" gap="8px 24px" flexWrap="wrap">
          <Button
            variant="outlined"
            startIcon={<AddIcon sx={{ color: '#9E6AFD' }} />}
            onClick={() => navigate(routes.projectVizitCreate.path.replace(':uuid', currentProject?.uuid))}
          >
            добавить компанию
          </Button>
        </Stack>

        <form noValidate onSubmit={handleSubmit(handleSearch)}>
          <Stack direction="row" gap="8px 24px" flexWrap="wrap">
            <TextField
              name="query"
              size="small"
              placeholder="Введите название..."
              sx={{ minWidth: '240px' }}
              {...register('query')}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: getValues('query') ? 'visible' : 'hidden' }}
                    onClick={() => {
                      reset();
                      handleSearch({ query: '' });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
            <Button type="submit" variant="contained">
              поиск
            </Button>
          </Stack>
        </form>
      </Stack>

      <TableContainer id="vizits-table" component={Paper} sx={{ position: 'relative', marginBottom: '20px' }}>
        {loading && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ position: 'absolute', inset: '0', zIndex: 2, background: '#ffffff40', padding: '60px 0' }}
          >
            <CircularProgress />
          </Stack>
        )}
        <Table size="medium" sx={{ minWidth: 750, minHeight: 150 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow sx={{ background: '#F9FAFC' }}>
              {headCells.map((headCell, indexHead) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  width={headCell.width}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 600, lineHeight: '24px' }}>
                      {headCell.label}
                    </Typography>
                    {indexHead < headCells.length - 2 && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ width: '2px', height: '14px !important', margin: 'auto 0' }}
                      />
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {counts.total === 0 && !loading ? (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <Typography align="center">Нет данных</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {list.map((row, index) => {
                  const vizitDetailURL = `${routes.projectVizitDetail.path
                    .replace(':uuid', currentProject?.uuid)
                    .replace(':vizitUuid', row.uuid)}`;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`row-${index}`}
                      role="checkbox"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(vizitDetailURL)}
                    >
                      <TableCell component="th" scope="row" padding="none" sx={{ minWidth: '60%' }}>
                        <Stack direction="row" alignItems="center" gap="8px">
                          <Typography variant="body1">{row.name}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                          {row.web}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {/* <CompanyStatus status={row.members?.[0]?.isReadyTalk} /> */}
                        <VizitStatus status={row.status} />
                      </TableCell>
                      <TableCell>
                        <TableActionButton
                          options={[
                            // {
                            //   label: 'Принимать',
                            //   icon: <UserIcon icon="mdi:pencil" />,
                            //   //   disabled: item.isAccredited !== null,
                            //   onClick: (e) => {
                            //     navigate(vizitDetailURL);
                            //   },
                            // },

                            ...(['declined', 'initial'].includes(row.status)
                              ? [
                                {
                                  label: 'Подтверждать',
                                  icon: <UserIcon icon="mdi:check" />,
                                  onClick: (e) => {
                                    onChangeVizitStatus(row, 'approved');
                                  },
                                },
                              ]
                              : []),
                            ...(['approved', 'initial'].includes(row.status)
                              ? [
                                {
                                  label: 'Отклонить',
                                  icon: <UserIcon icon="mdi:close" />,
                                  onClick: (e) => {
                                    onChangeVizitStatus(row, 'declined');
                                  },
                                },
                              ]
                              : []),
                            {
                              label: 'Удалить',
                              icon: <UserIcon icon="mdi:delete" />,
                              //   disabled: item.isAccredited !== null,
                              onClick: (e) => {
                                onDeleteVizit(row);
                              },
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
        {counts.total > 0 && (
          <TablePagination
            labelRowsPerPage="Рядов на странице:"
            labelDisplayedRows={({ count, from, to }) => {
              return `${from}-${to} из ${count}`;
            }}
            page={page - 1}
            component="div"
            count={counts.total}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 20, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ marginTop: '20px' }}
          />
        )}
      </TableContainer>
    </Stack>
  );
}

export default VizitsList;
