import { CameraAlt, Check, Close, Delete } from '@mui/icons-material';
import { Avatar, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { createErrorNotification } from 'src/utils/notifications';
import Webcam from 'webcam-easy';
import ButtonLoading from '../button-loading';

function AvatarUpload({ avatar, buttonSize, setAvatar = () => {}, uploadAvatar = async () => {} }) {
  const webcam = useRef();
  const [showCamera, setShowCamera] = useState(false);
  const [tempAvatar, setTempAvatar] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    return () => {
      if (webcam.current) {
        webcam.current.stop();
      }
    };
  }, []);

  const handleCloseWebcam = () => {
    setShowCamera(false);
    setTempAvatar('');
    webcam.current.stop();
  };

  const handleUploadAvatar = async () => {
    setAvatar('');
    if (!webcam.current) {
      const webcamElement = document.getElementById('webcam');
      const canvasElement = document.getElementById('canvas');
      const snapSoundElement = document.getElementById('snapSound');
      webcam.current = new Webcam(webcamElement, 'user', canvasElement, snapSoundElement);
    }
    webcam.current
      .start()
      .then(() => {
        const webcamElement = document.getElementById('webcam');
        const canvasElement = document.getElementById('canvas');
        canvasElement.classList.add('d-none');
        webcamElement.classList.remove('d-none');
        setShowCamera(true);
        setTempAvatar('');
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Пожалуйста, разрешите доступ к камере');
        handleCloseWebcam();
      });
  };

  const handleTakePicture = async () => {
    try {
      const picture = webcam.current.snap();
      const imageBlob = await fetch(picture).then((res) => res.blob());
      setTempAvatar(imageBlob);
      afterTakePhoto();
    } catch (error) {
      console.error(error);
    }
  };

  function afterTakePhoto() {
    webcam.current.stop();
    const webcamElement = document.getElementById('webcam');
    const canvasElement = document.getElementById('canvas');
    canvasElement.classList.remove('d-none');
    webcamElement.classList.add('d-none');
  }

  const handleResetAvatar = () => {
    setTempAvatar('');
    const webcamElement = document.getElementById('webcam');
    const canvasElement = document.getElementById('canvas');
    canvasElement.classList.add('d-none');
    webcamElement.classList.remove('d-none');
    webcam.current.start();
  };

  const handleAcceptPicture = async () => {
    try {
      setIsUploading(true);
      handleCloseWebcam();
      setAvatar(tempAvatar);
      await uploadAvatar(tempAvatar);
    } finally {
      setIsUploading(false);
    }
  };

  const avatarURL = useMemo(() => {
    if (avatar) {
      return URL.createObjectURL(avatar);
    }
    return null;
  }, [avatar]);

  return (
    <>
      <Stack alignItems="center" gap="8px">
        {avatarURL && (
          <Avatar
            src={avatarURL}
            sx={{
              width: '100px',
              height: '100px',
              cursor: 'pointer',
              '&:hover': {
                boxShadow: (theme) => theme.shadows[9],
              },
            }}
            onClick={() => {
              eventBus.emit(EVENTS.OPEN_VIEW_IMAGE_MODAL, {
                images: [{ src: avatarURL }],
                fileName: 'avatar.png',
              });
            }}
          />
        )}

        <ButtonLoading
          size={buttonSize}
          variant="outlined"
          disabled={isUploading}
          isLoading={isUploading}
          onClick={handleUploadAvatar}
        >
          Сделать фото
        </ButtonLoading>
      </Stack>
      <Dialog maxWidth="lg" open={showCamera} onClose={handleCloseWebcam} keepMounted={true}>
        <DialogTitle>
          <Typography variant="span">Сделать фото</Typography>
          <IconButton sx={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={handleCloseWebcam}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ width: { xs: '100%', md: '700px' } }}>
          <Stack alignItems="center" gap="16px">
            <video id="webcam" autoPlay playsInline width="640" height="480"></video>
            <canvas id="canvas" className="d-none"></canvas>
            <audio id="snapSound" src="audio/snap.wav" preload="auto"></audio>
            <Stack
              direction="row"
              gap="8px"
              alignItems="center"
              sx={{
                position: 'absolute',
                bottom: '3rem',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {tempAvatar ? (
                <>
                  <IconButton
                    color="success"
                    sx={{ border: '1px solid', width: 48, height: 48, background: '#fff' }}
                    onClick={handleAcceptPicture}
                  >
                    <Check sx={{ fontSize: '28px' }} />
                  </IconButton>
                  <IconButton
                    color="error"
                    sx={{ border: '1px solid', width: 48, height: 48, background: '#fff' }}
                    onClick={handleResetAvatar}
                  >
                    <Delete sx={{ fontSize: '28px' }} />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  color="primary"
                  sx={{ border: '1px solid', width: 48, height: 48, background: '#fff' }}
                  onClick={handleTakePicture}
                >
                  <CameraAlt sx={{ fontSize: '28px' }} />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AvatarUpload;
