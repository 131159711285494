import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import moment from 'moment-timezone';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableSortLabel } from 'src/components/mui-table';
import TableSortSelect from 'src/components/table-sort-select';
import { routes } from 'src/constants';
import UserLayout from 'src/layouts/UserLayout';
import { generalAPIs } from 'src/services';
import { getProjectsList } from 'src/store/actions/application';
import { getLocalizationValue } from 'src/utils/common';
import { ORDER_DIRECTION } from 'src/utils/constant';
import { createNotification } from 'src/utils/notifications';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'название проекта',
    width: '45%',
  },
  {
    id: 'date_start_plan',
    numeric: false,
    disablePadding: false,
    label: 'Дата начала',
  },
  {
    id: 'date_finish_plan',
    numeric: false,
    disablePadding: false,
    label: 'Дата окончания',
  },
];

const sortByOptions = [
  { key: 'name', name: 'Имя' },
  { key: 'date_start_plan', name: 'Дата начала' },
  { key: 'date_finish_plan', name: 'Дата окончания' },
];

const ProjectsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = qs.parse(location.search);

  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);

  const [loading, setLoading] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [sortState, setSortState] = useState({
    sortOrder: ORDER_DIRECTION.ASC,
    sortBy: sortByOptions[1].key,
  });

  const { page: queryPage, limit, query } = queryParams;
  const rowsPerPage = parseInt(limit) || 15;
  const page = Math.max(queryPage || 1, 1);

  const handleChangePage = (event, newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({ ...queryParams, page: 1, limit: parseInt(event.target.value) || 15 });
  };

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams, page: newParams.page || 1 });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(`${routes.projects.path}?${query}`);
    },
    [queryParams]
  );

  const fetchProjectsList = useCallback(() => {
    return getProjectsList({ ...queryParams, page: queryParams.id, ...sortState });
  }, [location, sortState]);

  useEffect(() => {
    setLoading(true);
    fetchProjectsList().then(() => setLoading(false));
  }, [fetchProjectsList]);

  const { reset, register, getValues, handleSubmit } = useForm({
    defaultValues: { query: query },
  });

  const handleSearch = ({ query }) => {
    if (query && query.trim()) {
      setQueryParams({ ...queryParams, query: query });
    } else {
      let _queryParams = queryParams;
      delete _queryParams.query;
      setQueryParams(_queryParams);
    }
  };

  const handleStartProjectsLoader = async () => {
    try {
      setIsSyncing(true);
      await generalAPIs.startProjectsLoader();
      createNotification('Синхронизировать успешно', 'success');
    } catch (error) {
      console.error(error);
      createNotification(error.message?.error);
    } finally {
      setIsSyncing(false);
    }
  };

  const handleRequestSort = (property) => {
    const { sortBy, sortOrder } = sortState;
    const isAsc = sortBy === property && sortOrder === ORDER_DIRECTION.ASC;
    const isDesc = sortBy === property && sortOrder === ORDER_DIRECTION.DESC;
    setSortState({
      sortOrder: isAsc ? ORDER_DIRECTION.DESC : isDesc ? '' : ORDER_DIRECTION.ASC,
      sortBy: property,
    });
  };

  return (
    <UserLayout>
      <Stack gap="24px">
        <Card>
          <CardContent>
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography variant="h6">
                Все проекты
                <Typography variant="h6" component="span" sx={{ color: '#9155FD' }}>
                  {' '}
                  ({counts.total})
                </Typography>
              </Typography>
            )}
          </CardContent>
        </Card>

        <Card>
          <Stack direction="row" justifyContent="space-between" padding="20px ">
            {/* <Button variant="outlined" startIcon={<AddIcon sx={{ color: '#9E6AFD' }} />}>
              создать проект
            </Button> */}
            <form noValidate onSubmit={handleSubmit(handleSearch)}>
              <Stack direction="row" gap="24px">
                <TextField
                  name="query"
                  size="small"
                  placeholder="Поиск по дате, названию и статусу"
                  sx={{ minWidth: '400px' }}
                  {...register('query')}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: getValues('query') ? 'visible' : 'hidden' }}
                        onClick={() => {
                          reset();
                          handleSearch({ query: '' });
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
                <Button type="submit" variant="contained">
                  поиск
                </Button>
              </Stack>
            </form>
            <Tooltip
              title="Не включать отредактированные и заархивированные проекты"
              placement="top"
              style={{ textAlign: 'right' }}
            >
              <Button
                variant="contained"
                disabled={isSyncing}
                onClick={handleStartProjectsLoader}
                style={{ textTransform: 'none' }}
              >
                Cинхронизировать Тезис данные
              </Button>
            </Tooltip>
          </Stack>
          <Stack padding="0 20px 20px" sx={{ width: 'fit-content' }}>
            <TableSortSelect
              sortByOptions={sortByOptions}
              order={sortState.sortOrder}
              orderBy={sortState.sortBy}
              onSelectSort={handleRequestSort}
            />
          </Stack>
          <TableContainer id="projects-table" component={Paper} sx={{ position: 'relative', marginBottom: '20px' }}>
            {loading && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ position: 'absolute', inset: '0', zIndex: 2, background: '#ffffff40', padding: '60px 0' }}
              >
                <CircularProgress />
              </Stack>
            )}
            <Table size="medium" sx={{ minWidth: 750, minHeight: 150 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow sx={{ background: '#F9FAFC' }}>
                  {headCells.map((headCell, indexHead) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      width={headCell.width}
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <TableSortLabel
                          headCell={headCell}
                          order={sortState.sortOrder}
                          orderBy={sortState.sortBy}
                          onRequestSort={handleRequestSort}
                        />
                        {indexHead < headCells.length - 1 && (
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ width: '2px', height: '14px !important', margin: 'auto 0' }}
                          />
                        )}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && (
                  <>
                    {counts.total === 0 ? (
                      <TableRow>
                        <TableCell colSpan={headCells.length}>
                          <Typography align="center">Нет данных</Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {list.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={row.name + index}
                              role="checkbox"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => navigate(routes.projectsPage.path.replace(':uuid', row.uuid))}
                            >
                              <TableCell component="th" scope="row" padding="none" sx={{ minWidth: '60%' }}>
                                <Stack direction="row" alignItems="center" gap="8px" paddingY="10px">
                                  <Avatar alt="" src={row.photos?.logo?.[0]} />
                                  <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {getLocalizationValue(row, 'fullName')}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography component="span" variant="body1" sx={{ fontSize: '14px', fontWeight: 500 }}>
                                  {moment.unix(row.dateStartPlan).format('DD MMMM YYYY')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  component="span"
                                  variant="body1"
                                  sx={{ fontSize: '14px', fontWeight: 500 }}
                                  style={{ textTransform: 'capitalize' }}
                                >
                                  {moment.unix(row.dateFinishPlan).format('DD MMMM YYYY')}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
            {counts.total > 0 && (
              <TablePagination
                labelRowsPerPage="Рядов на странице:"
                labelDisplayedRows={({ count, from, to }) => {
                  return `${from}-${to} из ${count}`;
                }}
                page={page - 1}
                component="div"
                count={counts.total}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[15, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ marginTop: '20px' }}
              />
            )}
          </TableContainer>
        </Card>
      </Stack>
    </UserLayout>
  );
};

export default ProjectsPage;
