import moment from 'moment';

export const getDateForFile = (date) => {
  return moment(date).format('YYYY-MM-DD-HH-mm-SS');
};

export const getFullDate = (dateStr) => {
  const date = moment(dateStr);
  if (!date._isValid) {
    return null;
  }
  return date.format('DD.MM.YYYY');
};
