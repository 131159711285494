import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { COMPANY_TYPE_OPTIONS, LOCALIZATION, TEXT } from '../../constants';
import { addCompany, deleteUserCompany } from '../../store/actions/application';
import { EVENTS } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { companyValidationSchema } from '../../utils/formValidator';
import classes from './company-form.module.sass';
import { userAPIs } from '../../services';
import UserIcon from 'src/layouts/components/UserIcon';
import _ from 'lodash';

let isTyping;
export function CompanyForm({ userId, isLocked, callbackAfterAdded = () => {}, refetchProfileInfo = () => {} }) {
  const companies = useSelector((state) => state.application.companies);
  const company = companies?.[0];
  const lang = LOCALIZATION.ru_RU;
  const countries = useSelector((state) => state.application.countries);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        name: company?.name,
        fullName: company?.fullName,
        extLegalAddress: company?.extLegalAddress,
        countryOfRegistration: company?.countryOfRegistration || 643,
        webAddress: company?.webAddress,
        phone: company?.phone,
        contactPerson: company?.contactPerson,
        email: company?.email,
        companyType: company?.companyType || Object.keys(COMPANY_TYPE_OPTIONS)[0],
        kpp: company?.kpp,
        inn: company?.inn,
      }),
      [company]
    ),
    resolver: yupResolver(companyValidationSchema),
  });

  const [focusingField, setFocusingField] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const nameRef = useRef(null);
  const fullNameRef = useRef(null);
  const contactPersonRef = useRef(null);
  const kppRef = useRef(null);
  const innRef = useRef(null);
  const extLegalAddressRef = useRef(null);

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const body = {
        ...values,
        countryOfRegistration: parseInt(values.countryOfRegistration),
        LegalRepresentativeUserID: userId,
      };
      const newCompany = await addCompany(body);
      setIsChanged(false);
      callbackAfterAdded(newCompany);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const subscription = watch(() => setIsChanged(true));
    return () => subscription.unsubscribe();
  }, [watch]);

  const getSuggestion = async (query, field = '', isAddress) => {
    if (isSearching) return;
    try {
      setIsSearching(true);
      const res = isAddress ? await userAPIs.getSuggestionAddress(query) : await userAPIs.getSuggestionCompany(query);
      setSuggestions(res.message || []);
      setFocusingField(field);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSearching(false);
    }
  };

  const handleDeleteUserCompany = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: async () => {
        await deleteUserCompany(userId);
        refetchProfileInfo();
      },
    });
  };

  const renderSuggestion = useCallback(
    (field, ref, isAddress) => {
      const handleSelect = (suggestion) => {
        setFocusingField('');
        if (isAddress) {
          suggestion && setValue('extLegalAddress', suggestion);
        } else {
          suggestion.shortName && setValue('name', suggestion.shortName);
          suggestion.fullName && setValue('fullName', suggestion.fullName);
          suggestion.contactPerson && setValue('contactPerson', suggestion.managerName);
          suggestion.kpp && setValue('kpp', suggestion.kpp);
          suggestion.inn && setValue('inn', suggestion.inn);
          suggestion.type &&
            setValue(
              'companyType',
              suggestion.type === 'LEGAL' ? Object.keys(COMPANY_TYPE_OPTIONS)[0] : Object.keys(COMPANY_TYPE_OPTIONS)[3],
              {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              }
            );
          suggestion.emails?.[0] && setValue('email', suggestion.emails?.[0]);
          suggestion.phones?.[0] && setValue('phone', suggestion.phones?.[0]);
        }
      };

      return (
        <Popper
          open={focusingField === field && suggestions?.length > 0}
          anchorEl={ref.current}
          placement="bottom-start"
          transition
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper className={classes.suggestions}>
                <ClickAwayListener onClickAway={() => setFocusingField('')}>
                  <MenuList
                    sx={{
                      maxWidth: '50vw',
                      maxHeight: '450px',
                      overflow: 'scroll',
                    }}
                  >
                    {suggestions.map((suggest, i) => (
                      <MenuItem key={i} onClick={() => handleSelect(suggest)}>
                        {isAddress ? `${suggest}` : `${suggest.shortName} - ${suggest.managerName}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      );
    },
    [suggestions, focusingField]
  );

  const handleChangeTextField = (e, field, isAddress) => {
    const value = e.target.value;
    setValue(field, value);
    clearTimeout(isTyping);
    isTyping = setTimeout(() => {
      getSuggestion(value, field, isAddress);
    }, 1000);
  };

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="32px" marginBottom="22px">
        <Stack direction="row" gap="8px" flexWrap="wrap" justifyContent="space-between">
          <Typography variant="h6">{TEXT[lang].COMPANY}</Typography>
          {company && (
            <Button variant="outlined" onClick={handleDeleteUserCompany} endIcon={<UserIcon icon="mdi:close" />}>
              Удалить
            </Button>
          )}
        </Stack>

        <Grid container rowSpacing={5} columnSpacing={6.75} sx={{ '& > div > div': { width: '100%' } }}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label={TEXT[lang].NAME_COMPANY}
              InputLabelProps={{ shrink: true }}
              inputRef={nameRef}
              InputProps={{
                sx: {
                  '& input': {
                    textTransform: 'capitalize',
                  },
                },
              }}
              // autoFocus
              defaultValue={getValues('name')}
              {...register('name')}
              onChange={(e) => handleChangeTextField(e, 'name')}
              error={errors.name ? true : false}
              helperText={errors.name?.message}
              disabled={isLocked}
            />
            {renderSuggestion('name', nameRef)}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label={TEXT[lang].FULLNAME_COMPANY}
              InputLabelProps={{ shrink: true }}
              inputRef={fullNameRef}
              defaultValue={getValues('fullName')}
              {...register('fullName')}
              onChange={(e) => handleChangeTextField(e, 'fullName')}
              error={errors.fullName ? true : false}
              helperText={errors.fullName?.message}
              disabled={isLocked}
            />
            {renderSuggestion('fullName', fullNameRef)}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label={TEXT[lang].ADDRESS_COMPANY}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={extLegalAddressRef}
              defaultValue={getValues('extLegalAddress')}
              {...register('extLegalAddress')}
              onChange={(e) => handleChangeTextField(e, 'extLegalAddress', true)}
              error={errors.extLegalAddress ? true : false}
              helperText={errors.extLegalAddress?.message}
              disabled={isLocked}
            />
            {renderSuggestion('extLegalAddress', extLegalAddressRef, true)}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl error={errors.countryOfRegistration ? true : false} disabled={isLocked}>
              <InputLabel required id="select-label__company-country">
                {TEXT[lang].COUNTRY_COMPANY}
              </InputLabel>

              <Select
                labelId="select-label__company-country"
                label={TEXT[lang].COUNTRY_COMPANY}
                defaultValue={getValues('countryOfRegistration')}
                {...register('countryOfRegistration')}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.localization?.[lang]?.name}
                    {country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}
                  </MenuItem>
                ))}
              </Select>
              {errors.countryOfRegistration && (
                <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                  {errors.countryOfRegistration.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label={TEXT[lang].SITE_COMPANY}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={getValues('webAddress')}
              {...register('webAddress')}
              error={errors.webAddress ? true : false}
              helperText={errors.webAddress?.message}
              disabled={isLocked}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPhoneNumber
              required
              label={TEXT[lang].TELEPHONE_COMPANY}
              InputLabelProps={{ shrink: true }}
              name="phone"
              autoFormat={false}
              variant="outlined"
              defaultCountry={'ru'}
              {...register('phone')}
              value={getValues('phone')}
              onChange={(value) => setValue('phone', value)}
              error={errors.phone ? true : false}
              helperText={errors.phone?.message}
              disabled={isLocked}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label={TEXT[lang].CONTACT_PERSON}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={contactPersonRef}
              defaultValue={getValues('contactPerson')}
              {...register('contactPerson')}
              onChange={(e) => handleChangeTextField(e, 'contactPerson')}
              error={errors.contactPerson ? true : false}
              helperText={errors.contactPerson?.message}
              disabled={isLocked}
            />
            {renderSuggestion('contactPerson', contactPersonRef)}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label={TEXT[lang].EMAIL_COMPANY}
              InputLabelProps={{
                shrink: true,
              }}
              {...register('email')}
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              disabled={isLocked}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl error={errors.companyType ? true : false} disabled={isLocked}>
              <InputLabel required id="select-label__company-type">
                {TEXT[lang].TYPE_COMPANY}
              </InputLabel>

              <Select
                labelId="select-label__company-type"
                label={TEXT[lang].TYPE_COMPANY}
                defaultValue={getValues('companyType')}
                {...register('companyType')}
              >
                {Object.keys(COMPANY_TYPE_OPTIONS).map((type) => (
                  <MenuItem key={type} value={type}>
                    {COMPANY_TYPE_OPTIONS[type]?.[lang]}
                  </MenuItem>
                ))}
              </Select>
              {errors.companyType && (
                <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                  {errors.companyType.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label={TEXT[lang].KPP}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={kppRef}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
              }}
              type="number"
              defaultValue={getValues('kpp')}
              {...register('kpp')}
              onChange={(e) => handleChangeTextField(e, 'kpp')}
              error={errors.kpp ? true : false}
              helperText={errors.kpp?.message}
              disabled={isLocked}
            />
            {renderSuggestion('kpp', kppRef)}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label={TEXT[lang].INN}
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={innRef}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10);
              }}
              type="number"
              defaultValue={getValues('inn')}
              {...register('inn')}
              onChange={(e) => handleChangeTextField(e, 'inn')}
              error={errors.inn ? true : false}
              helperText={errors.inn?.message}
              disabled={isLocked}
            />
            {renderSuggestion('inn', innRef)}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', margin: 'auto', height: '100%' }}>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                sx={{ width: '100%' }}
                disabled={isSubmitting || !isChanged || isLocked}
              >
                {TEXT[lang].SAVE_CHANGES}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}
