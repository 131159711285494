import { DraftsOutlined, PhoneOutlined } from '@mui/icons-material';
import { Avatar, Button, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { DOCUMENT_TYPES, LOCALIZATION, MESSAGES, TEXT } from '../../../../constants';
import { userAPIs } from '../../../../services';
import { getDocumentOfMember } from '../../../../store/actions/vizit';
import eventBus, { EVENTS } from '../../../../utils/eventBus';
import { DEFAULT_TIMEZONE } from '../../../../utils/time';
import classes from './user.module.sass';

const UserComponent = ({ user, isExpand }) => {
  const lang = LOCALIZATION.ru_RU;
  const [avatar, setAvatar] = useState(null);

  const handleClickAppointment = (slot) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_APPOINTMENT_MODAL, { ...user, avatar, slot });
  };

  const getAvatarMember = async (memberUuid) => {
    try {
      const resAvatar = await getDocumentOfMember(memberUuid, DOCUMENT_TYPES.AVATAR);
      setAvatar(resAvatar);
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  useEffect(() => {
    getAvatarMember(user.userUuid);
  }, [user]);

  return (
    <Stack className={classes.content}>
      <div className={classes.info}>
        <Stack direction="row" gap="12px" alignItems="center" sx={{ margin: '12px 0' }}>
          <Avatar src={avatar ? URL.createObjectURL(avatar) : ''} sx={{ width: 85, height: 85 }}>
            {(user?.fullName?.split(' ')?.[0]?.[0] || '') + (user?.fullName?.split(' ')?.[1]?.[0] || '')}
          </Avatar>
          <Stack sx={{ overflow: 'hidden' }}>
            <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
              {user.fullName}
            </Typography>
            <Typography variant={'h7'} sx={{ color: '#8A8A8E' }}>
              {user.role}
            </Typography>
            {/* <Stack direction="row" gap="8px" marginTop="8px" flexWrap="wrap">
              <Tooltip title={<Typography sx={{ fontSize: '17px' }}>{user.telephone}</Typography>}>
                <PhoneOutlined />
              </Tooltip>
              <Tooltip title={<Typography sx={{ fontSize: '17px' }}>{user.email}</Typography>}>
                <DraftsOutlined />
              </Tooltip>
            </Stack> */}
            <Stack gap="8px" marginTop="16px">
              <Stack direction="row" gap="8px">
                <PhoneOutlined />
                <Typography>{user.telephone}</Typography>
              </Stack>
              <Stack direction="row" gap="8px">
                <DraftsOutlined />
                <Typography>{user.email}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </div>
      {isExpand && (
        <>
          <Typography color="#8A8A8E" algin="justify" sx={{ margin: '8px 0' }}>
            {user.description || TEXT[lang].NO_DESCRIPTION}
          </Typography>
          {user?.slots != null && (
            <div style={{ fontWeight: 'bold', margin: '10px 0' }}>{`${MESSAGES[lang].CHOOSE_MEETING_SLOT}:`}</div>
          )}
          <Stack direction="row" gap="6px" flexWrap="wrap">
            {(user.slots || []).map((slot, i) => (
              <Button
                key={i}
                variant="outlined"
                className={classes.timeButton}
                onClick={() => handleClickAppointment(slot)}
              >{`${moment.tz(slot.startTime, DEFAULT_TIMEZONE).format('DD/MM HH:mm')} - ${moment
                .tz(slot.endTime, DEFAULT_TIMEZONE)
                .format('HH:mm')}`}</Button>
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default UserComponent;
