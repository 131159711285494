import { Icon } from '@iconify/react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import eventBus, { EVENTS } from '../../utils/eventBus';
import VizitsSearchPage from '../vizits-search';

export default function VizitSearchModal() {
  const [show, setShow] = useState(true);
  const [data, setData] = useState(null);

  const onClose = () => setShow(false);

  const onOpen = (projectUuid) => {
    setShow(true);
    setData({ projectUuid });
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_VIZIT_SEARCH_MODAL, onOpen);
    return () => {
      eventBus.off(EVENTS.OPEN_VIZIT_SEARCH_MODAL, onOpen);
    };
  }, []);

  if (!data?.projectUuid) {
    return null;
  }
  return (
    <Dialog fullScreen open={show} onClose={onClose} className="modal-width-full">
      <DialogTitle sx={{ background: '#F4F5FA' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ top: 8, right: 10, position: 'absolute', color: 'grey.500' }}
        >
          <Icon icon="mdi:close" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: '#F4F5FA' }}>
        <VizitsSearchPage projectUuid={data?.projectUuid} />
      </DialogContent>
    </Dialog>
  );
}
