const Table = () => {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: theme.shadows[0],
          borderTopColor: theme.palette.divider,
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          '& .MuiTableCell-head': {
            fontSize: '0.75rem',
            fontWeight: 600,
            letterSpacing: '0.13px',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTableCell-body': {
            letterSpacing: '0.25px',
            color: theme.palette.text.secondary,
            '&:not(.MuiTableCell-sizeSmall):not(.MuiTableCell-paddingCheckbox):not(.MuiTableCell-paddingNone)': {
              paddingTop: theme.spacing(3.5),
              paddingBottom: theme.spacing(3.5),
            },
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTableCell-head:not(.MuiTableCell-paddingCheckbox):first-of-type, & .MuiTableCell-root:not(.MuiTableCell-paddingCheckbox):first-of-type ':
          {
            paddingLeft: theme.spacing(5),
          },
          '& .MuiTableCell-head:last-child, & .MuiTableCell-root:last-child': {
            paddingRight: theme.spacing(5),
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: '54px',
          borderBottom: `1px solid ${theme.palette.divider}`,
        }),
        paddingCheckbox: ({ theme }) => ({
          paddingLeft: theme.spacing(2),
        }),
        stickyHeader: ({ theme }) => ({
          backgroundColor: theme.palette.customColors.tableHeaderBg,
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTablePagination-select': {
            fontSize: '0.875rem',
            fontWeight: 400,
            minWidth: 'auto !important',
            color: theme.palette.text.primary,
          },
          '& > .MuiTablePagination-toolbar > *': {
            margin: 'auto',
          },
          '& .MuiTablePagination-displayedRows': {
            marginLeft: '26px',
            color: theme.palette.text.primary,
          },
          '& .MuiTablePagination-selectLabel': {
            color: theme.palette.text.secondary,
          },
          '& .MuiTablePagination-toolbar .MuiTablePagination-actions': {
            marginLeft: '26px',
            '& button svg': {
              color: theme.palette.action.active,
            },
            '& button:not(.Mui-disabled) svg': {
              color: theme.palette.text.primary,
            },
          },
        }),
      },
    },
  };
};

export default Table;
