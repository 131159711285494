import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { SelectHookForm } from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { generateRandomPassword } from 'src/utils/common';
import { LOCALIZATION, MESSAGES } from 'src/utils/constant';
import { supportedUserCreateValidationSchema } from 'src/utils/formValidator';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import { TableHeader } from '..';

function SupportedUserCreate({ projectUuid, systemRoles, onReloadTable = () => {} }) {
  const [showPassword, setShowPassword] = useState(false);
  const lang = useSelector((state) => state.application.lang) || LOCALIZATION.ru_RU;

  // const fetchData = async () => {
  //   const response = await request(`/roles?projectUuid=${projectUuid}`);
  //   console.log({ response });
  //   return response.message;
  // };

  // const { isLoading, data: roles } = useQuery(['fetchData', projectUuid], fetchData, { enabled: !!projectUuid });
  const defaultValues = {
    password: generateRandomPassword(),
    fullName: '',
    email: '',
    role: '',
    expiredDate: '',
  };

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(supportedUserCreateValidationSchema),
  });

  const handleCreateUser = async (values) => {
    try {
      const body = { ...values, expiredDate: format(values.expiredDate, 'yyyy-MM-dd') };
      await request(`/internal/projects/${projectUuid}/sysusers`, { method: 'POST', body });
      createNotification(MESSAGES[lang].CREATE_SUCCESSFULLY, 'success');
      reset(defaultValues);
      onReloadTable();
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  return (
    <Stack gap="16px">
      <TableHeader sx={{ color: (theme) => theme.palette.text.primary }}>Личные данные</TableHeader>
      {false ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(handleCreateUser)}>
          <Box sx={{ width: { xs: '100%', md: '70%' } }}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Фамилия Имя Отчество"
                  {...register('fullName')}
                  error={errors.fullName ? true : false}
                  helperText={errors.fullName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Роль"
                  {...register('role')}
                  error={errors.role ? true : false}
                  helperText={errors.role?.message}
                /> */}
                <Controller
                  name="roleUuid"
                  control={control}
                  render={({ field }) => (
                    <SelectHookForm
                      shrink={true}
                      label="Роль"
                      selectProps={{
                        value: field.value,
                        onChange: field.onChange,
                      }}
                      error={errors.role ? true : false}
                      errorComponent={
                        <>{errors.role && <FormHelperText error={true}>{errors.role.message}</FormHelperText>}</>
                      }
                    >
                      {systemRoles.map((value, i) => (
                        <MenuItem key={i} value={value.uuid}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </SelectHookForm>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="email"
                  label="Email"
                  {...register('email')}
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Ваш пароль"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('password')}
                  error={errors.password ? true : false}
                  helperText={errors.password?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="expiredDate"
                  control={control}
                  render={({ field }) => (
                    <ReactDatePicker
                      minDate={new Date()}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      customInput={
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          label="Дата окончания доступа"
                          error={errors.expiredDate ? true : false}
                          helperText={errors.expiredDate?.message}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack justifyContent="flex-end" sx={{ height: '100%' }}>
                  <Button type="submit" size="large" variant="contained" fullWidth>
                    добавить
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </Stack>
  );
}

export default SupportedUserCreate;
