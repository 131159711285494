// ** Next Import
// import Link from 'next/link'

// ** MUI Imports
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

const FooterContent = () => {
  return null;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography
        sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px', color: 'rgba(58, 53, 65, 0.6)' }}
      >{`© ${new Date().getFullYear()}, Экспо`}</Typography>
    </Box>
  );
};

export default FooterContent;
