import { Stack, Typography } from '@mui/material';

export default function ProfileField({ label, children, ...props }) {
  return (
    <Stack direction="row" flexWrap="wrap" gap="8px" {...props}>
      <Typography variant="body2">{label}: </Typography>
      <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.primary }}>
        {children}
      </Typography>
    </Stack>
  );
}
