import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function Alert({ show, onClose, onSubmit, title, submitLabel }) {
  const handleSubmit = async () => {
    await onSubmit();
    onClose();
  };
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="contained" color="error" onClick={handleSubmit}>
          {submitLabel || 'Ок'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
