import { LOCALIZATION } from '../../utils/constant';

export const BLOCKS_CONGRESS_PROGRAM = {
  [LOCALIZATION.en_US]: {
    'Пленарное заседание: «Новый облик промышленности в меняющемся мире: импортозамещение, модернизация, кадры»': 'Пленарное заседание: «Новый облик промышленности в меняющемся мире: импортозамещение, модернизация, кадры»',
    'Открытый диалог «Государственная поддержка экспорта в условиях санкций»': 'Открытый диалог «Государственная поддержка экспорта в условиях санкций»',
    'Заседание Координационного совета РСПП в СЗФО': 'Заседание Координационного совета РСПП в СЗФО',
    'Всероссийский фестиваль университетских и технологических проектов': 'Всероссийский фестиваль университетских и технологических проектов',
    'Пленарное заседание «Диалог Россия – Латинская Америка»': 'Пленарное заседание «Диалог Россия – Латинская Америка»',
    'Конференция «Отечественные продукты в глобальных цепочках добавленной стоимости»': 'Конференция «Отечественные продукты в глобальных цепочках добавленной стоимости»',
  },
  [LOCALIZATION.ru_RU]: {
    'Пленарное заседание: «Новый облик промышленности в меняющемся мире: импортозамещение, модернизация, кадры»': 'Пленарное заседание: «Новый облик промышленности в меняющемся мире: импортозамещение, модернизация, кадры»',
    'Открытый диалог «Государственная поддержка экспорта в условиях санкций»': 'Открытый диалог «Государственная поддержка экспорта в условиях санкций»',
    'Заседание Координационного совета РСПП в СЗФО': 'Заседание Координационного совета РСПП в СЗФО',
    'Всероссийский фестиваль университетских и технологических проектов': 'Всероссийский фестиваль университетских и технологических проектов',
    'Пленарное заседание «Диалог Россия – Латинская Америка»': 'Пленарное заседание «Диалог Россия – Латинская Америка»',
    'Конференция «Отечественные продукты в глобальных цепочках добавленной стоимости»': 'Конференция «Отечественные продукты в глобальных цепочках добавленной стоимости»',
  },
}
export const HOW_TO_KNOW = {
  [LOCALIZATION.en_US]: {
    'Реклама в печатных СМИ': 'Реклама в печатных СМИ',
    'Наружная реклама (рекламные щиты)': 'Наружная реклама (рекламные щиты)',
    'Реклама на радио': 'Реклама на радио',
    'Реклама в интернете': 'Реклама в интернете',
    'Реклама в социальных сетях': 'Реклама в социальных сетях',
    'Еmail-рассылка': 'Еmail-рассылка',
    'Приглашение от организатора по телефону': 'Приглашение от организатора по телефону',
    'Приглашение от профильных ассоциаций': 'Приглашение от профильных ассоциаций',
    'Приглашение от госструктур': 'Приглашение от госструктур',
    'От коллег, знакомых': 'От коллег, знакомых',
    'Посещал ранее': 'Посещал ранее',
  },
  [LOCALIZATION.ru_RU]: {
    'Реклама в печатных СМИ': 'Реклама в печатных СМИ',
    'Наружная реклама (рекламные щиты)': 'Наружная реклама (рекламные щиты)',
    'Реклама на радио': 'Реклама на радио',
    'Реклама в интернете': 'Реклама в интернете',
    'Реклама в социальных сетях': 'Реклама в социальных сетях',
    'Еmail-рассылка': 'Еmail-рассылка',
    'Приглашение от организатора по телефону': 'Приглашение от организатора по телефону',
    'Приглашение от профильных ассоциаций': 'Приглашение от профильных ассоциаций',
    'Приглашение от госструктур': 'Приглашение от госструктур',
    'От коллег, знакомых': 'От коллег, знакомых',
    'Посещал ранее': 'Посещал ранее',
  },
};

export const TYPE_ACTIVITY_COMPANY = {
  [LOCALIZATION.en_US]: {
    'Производственная компания': 'Производственная компания',
    'Дистрибьютор': 'Дистрибьютор',
    'Дилер': 'Дилер',
    'Оптовая торговля': 'Оптовая торговля',
    'Розничная торговля': 'Розничная торговля',
    'Интернет-магазин': 'Интернет-магазин',
    'Сеть': 'Сеть',
    'Услуги': 'Услуги',
    'Другое': 'Другое',
  },
  [LOCALIZATION.ru_RU]: {
    'Производственная компания': 'Производственная компания',
    'Дистрибьютор': 'Дистрибьютор',
    'Дилер': 'Дилер',
    'Оптовая торговля': 'Оптовая торговля',
    'Розничная торговля': 'Розничная торговля',
    'Интернет-магазин': 'Интернет-магазин',
    'Сеть': 'Сеть',
    'Услуги': 'Услуги',
    'Другое': 'Другое',
  },
};

export const PURPOSE_VISITING = {
  [LOCALIZATION.en_US]: {
    'Поиск заказчиков': 'Поиск заказчиков ',
    'Поиск поставщиков': 'Поиск поставщиков',
    'Ознакомление с тенденциями и новинками рынка': 'Ознакомление с тенденциями и новинками рынка',
    'Изучение выставки с целью будущего участия': 'Изучение выставки с целью будущего участия',
    'Продвижение собственных товаров и услуг': 'Продвижение собственных товаров и услуг',
    'Участие в деловой/конгрессной программе': 'Участие в деловой/конгрессной программе',
    'Поиск инвесторов, партнёров': 'Поиск инвесторов, партнёров',
    'Другое': 'Другое',
  },
  [LOCALIZATION.ru_RU]: {
    'Поиск заказчиков': 'Поиск заказчиков ',
    'Поиск поставщиков': 'Поиск поставщиков',
    'Ознакомление с тенденциями и новинками рынка': 'Ознакомление с тенденциями и новинками рынка',
    'Изучение выставки с целью будущего участия': 'Изучение выставки с целью будущего участия',
    'Продвижение собственных товаров и услуг': 'Продвижение собственных товаров и услуг',
    'Участие в деловой/конгрессной программе': 'Участие в деловой/конгрессной программе',
    'Поиск инвесторов, партнёров': 'Поиск инвесторов, партнёров',
    'Другое': 'Другое',
  },
};

export const INTERESTED_SECTIONS = {
  [LOCALIZATION.en_US]: {
    'Станкостроение': 'Станкостроение',
    'Энергетика и энергосберегающее оборудование': 'Энергетика и энергосберегающее оборудование',
    'Радиоэлектроника и компоненты': 'Радиоэлектроника и компоненты',
    'Автоматизация и робототехника': 'Автоматизация и робототехника',
    'Металлобработка': 'Металлобработка',
    'Энергетическое и транспортное машиностроение, двигателестроение': 'Энергетическое и транспортное машиностроение, двигателестроение',
    'Приборостроение': 'Приборостроение',
    'Региональные и коллективные экспозиции': 'Региональные и коллективные экспозиции',
    'Инженерный форум': 'Инженерный форум',
    'Услуги для бизнеса': 'Услуги для бизнеса',
    'Другое': 'Другое',
  },
  [LOCALIZATION.ru_RU]: {
    'Станкостроение': 'Станкостроение',
    'Энергетика и энергосберегающее оборудование': 'Энергетика и энергосберегающее оборудование',
    'Радиоэлектроника и компоненты': 'Радиоэлектроника и компоненты',
    'Автоматизация и робототехника': 'Автоматизация и робототехника',
    'Металлобработка': 'Металлобработка',
    'Энергетическое и транспортное машиностроение, двигателестроение': 'Энергетическое и транспортное машиностроение, двигателестроение',
    'Приборостроение': 'Приборостроение',
    'Региональные и коллективные экспозиции': 'Региональные и коллективные экспозиции',
    'Инженерный форум': 'Инженерный форум',
    'Услуги для бизнеса': 'Услуги для бизнеса',
    'Другое': 'Другое',
  },
};

export const INTERESTED_PROGRAM = {
  [LOCALIZATION.en_US]: { 'Названия деловой программы': 'Названия деловой программы' },
  [LOCALIZATION.ru_RU]: { 'Названия деловой программы': 'Названия деловой программы' },
};

export const ALLOWED_RECEIVE_NEWS = {
  [LOCALIZATION.ru_RU]: ['Да', 'Нет'],
  [LOCALIZATION.en_US]: ['Да', 'Нет'],
};

export const JOB_STATUS = {
  [LOCALIZATION.ru_RU]: {
    'Высший руководящий состав (рук-ли, из заместители)': 'Высший руководящий состав (рук-ли, из заместители)',
    'Среднее звено руководства(нач: Отделов, их заместителей)':
      'Среднее звено руководства(нач: Отделов, их заместителей)',
    'Специалист, инженерный состав': 'Специалист, инженерный состав',
    'Профессор, преподаватель, научный сотрудник': 'Профессор, преподаватель, научный сотрудник',
    'Учащийся, абитуриент': 'Учащийся, абитуриент',
    'Другое': 'Другое',
  },
  [LOCALIZATION.en_US]: {
    'Высший руководящий состав (рук-ли, из заместители)': 'Высший руководящий состав (рук-ли, из заместители)',
    'Среднее звено руководства(нач: Отделов, их заместителей)':
      'Среднее звено руководства(нач: Отделов, их заместителей)',
    'Специалист, инженерный состав': 'Специалист, инженерный состав',
    'Профессор, преподаватель, научный сотрудник': 'Профессор, преподаватель, научный сотрудник',
    'Учащийся, абитуриент': 'Учащийся, абитуриент',
    'Другое': 'Другое',
  },
};

export const EXTENT_COMMERCIAL_DECISIONS = {
  [LOCALIZATION.en_US]: {
    'Принимаю решения': 'Принимаю решения',
    'Влияю на принятие решений': 'Влияю на принятие решений',
    'Не влияю на принятие решений': 'Не влияю на принятие решений',
  },
  [LOCALIZATION.ru_RU]: {
    'Принимаю решения': 'Принимаю решения',
    'Влияю на принятие решений': 'Влияю на принятие решений',
    'Не влияю на принятие решений': 'Не влияю на принятие решений',
  },
};

export const AMOUNT_POTENTIAL_PURCHASES = {
  [LOCALIZATION.ru_RU]: {
    'до 50 тыс. руб.': 'до 50 тыс. руб.',
    'от 51 до 200 тыс. руб.': 'от 51 до 200 тыс. руб.',
    'от 201 до 500 тыс. руб.': 'от 201 до 500 тыс. руб.',
    'от 501 тыс. до 1 млн руб.': 'от 501 тыс. до 1 млн руб.',
    'от 1 до 3 млн руб.': 'от 1 до 3 млн руб.',
    'от 3 до 5 млн руб.': 'от 3 до 5 млн руб.',
    'более 5 млн руб.': 'более 5 млн руб.',
    'Затрудняюсь ответить': 'Затрудняюсь ответить',
    'Перспективы закупок отсутствуют': 'Перспективы закупок отсутствуют',
  },
  [LOCALIZATION.en_US]: {
    'до 50 тыс. руб.': 'до 50 тыс. руб.',
    'от 51 до 200 тыс. руб.': 'от 51 до 200 тыс. руб.',
    'от 201 до 500 тыс. руб.': 'от 201 до 500 тыс. руб.',
    'от 501 тыс. до 1 млн руб.': 'от 501 тыс. до 1 млн руб.',
    'от 1 до 3 млн руб.': 'от 1 до 3 млн руб.',
    'от 3 до 5 млн руб.': 'от 3 до 5 млн руб.',
    'более 5 млн руб.': 'более 5 млн руб.',
    'Затрудняюсь ответить': 'Затрудняюсь ответить',
    'Перспективы закупок отсутствуют': 'Перспективы закупок отсутствуют',
  },
};
export const SCOPE_COMPANY = {
  [LOCALIZATION.en_US]: {
    'Авиационная/Авиакосмическая промышленность': 'Авиационная/Авиакосмическая промышленность',
    'Автомобильная промышленность': 'Автомобильная промышленность',
    'Автоматизация и робототехника': 'Автоматизация и робототехника',
    'Горнодобывающая, камнеобрабатывающая промышленность': 'Горнодобывающая, камнеобрабатывающая промышленность',
    'Лёгкая промышленность': 'Лёгкая промышленность',
    'Лесная и деревообрабатывающая промышленность': 'Лесная и деревообрабатывающая промышленность',
    'Машиностроение': 'Машиностроение',
    'Медицинская промышленность': 'Медицинская промышленность',
    'Металлообрабатывающая промышленность': 'Металлообрабатывающая промышленность',
    'Металлургическая промышленность': 'Металлургическая промышленность',
    'Нефтегазовая промышленность': 'Нефтегазовая промышленность',
    'Пищевая промышленность': 'Пищевая промышленность',
    'Полиграфическая промышленность': 'Полиграфическая промышленность',
    'Приборостроение': 'Приборостроение',
    'Станкостроение': 'Станкостроение',
    'Судостроение и судоремонт': 'Судостроение и судоремонт',
    'Химическая и нефтехимическая промышленность': 'Химическая и нефтехимическая промышленность',
    'Цветная металлургия': 'Цветная металлургия',
    'Целлюлозно-бумажная промышленность': 'Целлюлозно-бумажная промышленность',
    'Чёрная металлургия': 'Чёрная металлургия',
    'Электротехника, электроника': 'Электротехника, электроника',
    'Радиоэлектронная промышленность': 'Радиоэлектронная промышленность',
    'Электрохимическая защита': 'Электрохимическая защита',
    'Энергетическая промышленность': 'Энергетическая промышленность',
    'Энергетическое и транспортное машиностроение, двигателестроение': 'Энергетическое и транспортное машиностроение, двигателестроение',
    'Фармацевтическая промышленность': 'Фармацевтическая промышленность',
    'Другое': 'Другое',
  },
  [LOCALIZATION.ru_RU]: {
    'Авиационная/Авиакосмическая промышленность': 'Авиационная/Авиакосмическая промышленность',
    'Автомобильная промышленность': 'Автомобильная промышленность',
    'Автоматизация и робототехника': 'Автоматизация и робототехника',
    'Горнодобывающая, камнеобрабатывающая промышленность': 'Горнодобывающая, камнеобрабатывающая промышленность',
    'Лёгкая промышленность': 'Лёгкая промышленность',
    'Лесная и деревообрабатывающая промышленность': 'Лесная и деревообрабатывающая промышленность',
    'Машиностроение': 'Машиностроение',
    'Медицинская промышленность': 'Медицинская промышленность',
    'Металлообрабатывающая промышленность': 'Металлообрабатывающая промышленность',
    'Металлургическая промышленность': 'Металлургическая промышленность',
    'Нефтегазовая промышленность': 'Нефтегазовая промышленность',
    'Пищевая промышленность': 'Пищевая промышленность',
    'Полиграфическая промышленность': 'Полиграфическая промышленность',
    'Приборостроение': 'Приборостроение',
    'Станкостроение': 'Станкостроение',
    'Судостроение и судоремонт': 'Судостроение и судоремонт',
    'Химическая и нефтехимическая промышленность': 'Химическая и нефтехимическая промышленность',
    'Цветная металлургия': 'Цветная металлургия',
    'Целлюлозно-бумажная промышленность': 'Целлюлозно-бумажная промышленность',
    'Чёрная металлургия': 'Чёрная металлургия',
    'Электротехника, электроника': 'Электротехника, электроника',
    'Радиоэлектронная промышленность': 'Радиоэлектронная промышленность',
    'Электрохимическая защита': 'Электрохимическая защита',
    'Энергетическая промышленность': 'Энергетическая промышленность',
    'Энергетическое и транспортное машиностроение, двигателестроение': 'Энергетическое и транспортное машиностроение, двигателестроение',
    'Фармацевтическая промышленность': 'Фармацевтическая промышленность',
    'Другое': 'Другое',
  },
};
