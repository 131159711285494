import { Button, Card, CardContent, Divider, Grid, Stack, TextField, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAbility } from 'src/hooks/useAbility';
import { generalAPIs } from 'src/services';
import { ACCESSES } from 'src/utils/ability';
import { EVENTS } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import DataProfile from './DataProfile';

function DataSettings({ projectUuid, refetchProjectDetail }) {
  const ability = useAbility();
  const currentProject = useSelector((state) => state.application.currentProject);

  const handleSyncProject = (syncAll) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_SYNC_PROJECT_MODAL, {
      onOk: async () => {
        try {
          await generalAPIs.syncProject(projectUuid, syncAll);
          refetchProjectDetail();
        } catch (error) {
          console.error(error);
          toast.error(error.message?.error);
        }
      },
    });
  };

  const disabledSync = currentProject?.isArchived;

  return (
    <Card>
      <CardContent sx={{ padding: '30px' }}>
        <Stack gap="20px">
          <Stack direction="row" marginY="10px" gap="48px">
            <Stack sx={{ width: '100%' }} gap="30px">
              <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="ID" InputLabelProps={{ shrink: true }} value={projectUuid} />
                </Grid>
                {ability.can(ACCESSES.SYNC_THEZIS) && (
                  <Grid item xs={12} md={6}>
                    <Stack justifyContent="center" height="100%">
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <Tooltip title="Cинхронизировать только данные проекта" placement="top">
                            <Button
                              fullWidth
                              variant="contained"
                              disabled={disabledSync}
                              onClick={() => handleSyncProject()}
                              style={{ textTransform: 'none' }}
                            >
                              Cинхронизировать данные
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Tooltip title="Cинхронизировать все данные, связанные с проектом" placement="top">
                            <Button
                              fullWidth
                              variant="contained"
                              disabled={disabledSync}
                              onClick={() => handleSyncProject(true)}
                              style={{ textTransform: 'none' }}
                            >
                              Cинхронизировать все
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Stack>

          <Divider />

          <DataProfile projectUuid={projectUuid} refetchProjectDetail={refetchProjectDetail} />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default DataSettings;
