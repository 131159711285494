import { Box, Button, Card, CardContent, Chip, Grid, Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Select from 'src/@core/components/mui/select';
import { updateUserByProjectUuid } from 'src/store/actions/application';
import { request } from 'src/utils/request';
import AddIcon from '@mui/icons-material/Add';
import CustomChip from 'src/@core/components/mui/chip';
import Loader from 'src/components/loader';
import UserIcon from 'src/layouts/components/UserIcon';
import AnketaDuplicateForm from './AnketaDuplicateForm';

function AnketaCreate({ projectUuid, roleUuid, setDisableAddMore, setShowAddNewField, refetchAnketaData }) {
  const [isDuplicate, setIsDuplicate] = useState(false);

  const handleDuplicate = () => {
    setIsDuplicate(true);
    setDisableAddMore(true);
  };

  const handleCancelDuplicate = () => {
    setIsDuplicate(false);
    setDisableAddMore(false);
  };

  useEffect(() => {}, []);

  return isDuplicate ? (
    <AnketaDuplicateForm
      projectUuid={projectUuid}
      roleUuid={roleUuid}
      onCancelDuplicate={handleCancelDuplicate}
      refetchAnketaData={refetchAnketaData}
    />
  ) : (
    <Stack alignItems="center" gap="13px">
      <UserIcon icon="mdi:alert-circle-outline" style={{ width: '50px', height: '50px', color: '#E3E3E3' }} />
      <Typography variant="h5">Анкета пуста</Typography>
      <Typography variant="body2">Хотите дублировать сюда уже готовую анкету?</Typography>
      <Stack direction="row" gap="16px" marginTop="18px">
        <Button variant="contained" onClick={handleDuplicate}>
          дублировать
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => setShowAddNewField(true)}>
          создать новую
        </Button>
      </Stack>
    </Stack>
  );
}

export default AnketaCreate;
