// ** MUI Imports
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

// ** Custom Components Imports
import ReactApexcharts from 'react-apexcharts';
import { Box, CardHeader, Stack } from '@mui/material';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'src/constants';

const TotalGrowth = ({ statistics = [] }) => {
  // ** Hook
  const theme = useTheme();
  const series = [
    {
      name: 'Зарегистрирован',
      data: statistics.map((item) => item.count || 0),
    },
  ];

  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
    },
    tooltip: { enabled: true },
    dataLabels: { enabled: false },
    stroke: {
      width: 3,
      curve: 'smooth',
      lineCap: 'round',
    },
    grid: {
      show: true,
      padding: {
        left: 24,
        top: 2,
        right: 24,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          [
            {
              offset: 0,
              opacity: 0.3,
              color: theme.palette.success.main,
            },
            {
              offset: 100,
              opacity: 0.1,
              color: theme.palette.background.paper,
            },
          ],
        ],
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: 'light',
        shadeIntensity: 1,
        color: theme.palette.success.main,
      },
    },
    xaxis: {
      // type: 'numeric',
      labels: { show: false },
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories: statistics.map((item) => moment(item.date).format('DD-MM-YYYY')),
    },
    yaxis: { show: false },
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
      <CardHeader title="Распределение времени создания заявок" />
      <CardContent
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'inherit' }}
      >
        <Box sx={{ margin: 'auto', width: '100%' }}>
          <ReactApexcharts type="area" height={250} options={options} series={series} />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
              {moment(statistics[0]?.date).format(DEFAULT_DATE_FORMAT)}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
              {moment(statistics[statistics.length - 1]?.date).format(DEFAULT_DATE_FORMAT)}
            </Typography>
          </Stack>
        </Box>
        {/* <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.primary' }}>
          Total Growth
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default TotalGrowth;
