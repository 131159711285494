import { useQuery } from 'react-query';
import { generalAPIs } from 'src/services';
import { setRoles } from 'src/store/actions/application';

function useFetchRoles({ projectUuid }) {
  const fetchRoles = async ({ queryKey }) => {
    const [, projectUuid] = queryKey;
    const res = await generalAPIs.getRoles(projectUuid);
    const roles = res?.message || [];
    setRoles(roles);
    return roles;
  };

  return useQuery(['fetchRoles', projectUuid], fetchRoles, {
    enabled: !!projectUuid,
  });
}

export default useFetchRoles;
