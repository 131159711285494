import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CaptureConsole } from '@sentry/integrations';

import DatePickerWrapper from './@core/styles/libs/react-datepicker';
import App from './App';
import { store } from './store';
import ThemeComponent from './theme/ThemeComponent';

// @casl/ability configs
import { AbilityContext } from './context/AbilityContext';
import ability from './utils/ability';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30000, // cache data for 30 seconds before refetching
      retry: false,
    },
  },
});

if (process.env.NODE_ENV === 'production') {
  // replace console.* for disable log on production
  console.log = () => {};

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new CaptureConsole({
        levels: [process.env.REACT_APP_LOG_LEVEL],
      }),
    ],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeComponent>
            <DatePickerWrapper>
              <AbilityContext.Provider value={ability}>
                <App />
              </AbilityContext.Provider>
            </DatePickerWrapper>
          </ThemeComponent>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
