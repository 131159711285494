import { request } from 'src/utils/request';
import qs from 'query-string';

export const emailTemplateAPIs = {
  getEmailTemplate: (projectUuid, roleUuid) =>
    request(`/internal/email-templates?` + qs.stringify({ projectUuid, roleUuid }), { method: 'GET' }),
  createTemplate: (body) => request(`/internal/email-templates`, { method: 'POST', body }),
  updateTemplate: (templateUuid, body) =>
    request(`/internal/email-templates/${templateUuid}`, { method: 'PATCH', body }),
  duplicateTemplate: (body) => request(`/internal/email-templates/duplicate`, { method: 'POST', body }),
};
