import { Box, Button, Menu, MenuItem, Popover, Stack } from '@mui/material';
import React, { useState } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { ORDER_DIRECTION } from 'src/utils/constant';

function TableSortSelect({ sortByOptions, order, orderBy, onSelectSort }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleOpenSortSelect = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChangeOrder = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onSelectSort(orderBy);
  };

  return (
    <Box>
      {/* <Button
        variant="outlined"
        startIcon={
          <Stack sx={{ width: '0.75em' }} onClick={handleChangeOrder}>
            <ArrowDropUp
              fontSize="large"
              sx={{ position: 'absolute', top: '5px' }}
              color={order === ORDER_DIRECTION.ASC && orderBy ? 'primary' : 'disabled'}
            />
            <ArrowDropDown
              fontSize="large"
              sx={{ position: 'absolute', bottom: '5px' }}
              color={order === ORDER_DIRECTION.DESC && orderBy ? 'primary' : 'disabled'}
            />
          </Stack>
        }
        sx={{ textTransform: 'none' }}
        onClick={handleOpenSortSelect}
      >
        Сортировать по «{sortByOptions.find((o) => o.key === orderBy)?.name}»
      </Button> */}

      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {sortByOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onSelectSort(option.key);
              handleClose();
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default TableSortSelect;
