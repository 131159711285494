import CustomChip from 'src/@core/components/mui/chip';

// const getStatusLabel = (status) => {
//   switch (status) {
//     case 'approved':
//       return 'Доступен для встречи';
//     default:
//       return 'Недступен для встречи';
//   }
// };

const getStatusLabel = (status) => {
  switch (status) {
    case 'declined':
      return 'отменена';
    case 'approved':
      return 'подтверждена';
    case 'deactivated':
      return 'Неактиво';
    case 'initial':
    default:
      return 'ожидает';
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'approved':
      return 'success';
    case 'deactivated':
      return 'error';
    default:
      return 'warning';
  }
};

export default function VizitStatus({ status }) {
  //   const label = getStatusLabel(status);
  const label = getStatusLabel(status);
  const color = getStatusColor(status);
  return <CustomChip label={label} skin="light" color={color} />;
}
