import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { EVENTS, LOCALIZATION, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import UserIcon from 'src/layouts/components/UserIcon';
import Close from '@mui/icons-material/Close';
import Loader from '../loader';

function ConfirmUpdateProjectModal() {
  const lang = LOCALIZATION.ru_RU;
  const [state, setState] = useState({
    show: false,
    onOk: async () => {},
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCancel = () => {
    setState({ show: false });
  };

  const handleShow = (data) => {
    setState({ ...state, ...data, show: true });
  };

  const handleOk = async () => {
    try {
      setIsSubmitting(true);
      await state.onOk();
    } catch (e) {
      console.log(e);
    } finally {
      handleCancel();
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_CONFIRM_UPDATE_PROJECT_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_CONFIRM_UPDATE_PROJECT_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Dialog open={state.show} onClose={handleCancel}>
      <DialogContent sx={{ padding: '40px', position: 'relative' }}>
        <IconButton sx={{ position: 'absolute', top: '20px', right: '20px' }} onClick={handleCancel}>
          <Close />
        </IconButton>
        <Stack gap="10px" alignItems="center">
          <UserIcon icon="mdi:alert-circle-outline" style={{ width: '50px', height: '50px', color: '#E3E3E3' }} />
          <Typography variant="h5" align="center">
            Вы уверены, <br /> что хотите сохранить изменения?
          </Typography>
          <Typography variant="body2" sx={{ color: '#E54449' }}>
            После сохранения изменений синхронизация будет отключена.
          </Typography>
          <Stack direction="row" gap="16px" marginTop="20px" sx={{ '& > button': { minWidth: '185px' } }}>
            <Button
              variant="contained"
              disabled={isSubmitting}
              onClick={handleOk}
              startIcon={isSubmitting ? <Loader size="16px" color="secondary" /> : <></>}
            >
              сохранить
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
              отменить
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmUpdateProjectModal;
