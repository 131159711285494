import qs from 'query-string';
import { useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { HotKeys } from 'react-hotkeys';
import { useNavigate } from 'react-router-dom';
import { userAPIs } from '../../services';
import { createErrorNotification } from '../../utils/notifications';
import Header from '../header';
import classes from './general-layout.module.sass';

let badgeId = '';
export default function GeneralLayout({ children, noHeader, className, container, allowCopyBadgeID }) {
  const Wrapper = container ? Container : 'div';
  const navigate = useNavigate();

  const searchUserByBadgeID = async (badgeId) => {
    try {
      const res = await userAPIs.getUser(qs.stringify({ badgeId, limit: 1, offset: 0 }));
      if (res?.message?.[0]) {
        navigate(`/profile/${res.message[0].uuid}`);
      } else {
        createErrorNotification('Пользователь не найден');
      }
    } catch (e) {
      createErrorNotification('Пользователь не найден');
    }
  };

  const handlers = {
    COPY_BADGE_ID: useCallback(() => {
      if (allowCopyBadgeID) {
        navigator.clipboard
          .readText()
          .then((text) => searchUserByBadgeID(text.trim()))
          .catch((error) => console.error(error));
      }
    }, [allowCopyBadgeID]),
  };

  const handleKeyPress = (e) => {
    if (allowCopyBadgeID) {
      const value = e.key;
      if (value === 'Enter') {
        searchUserByBadgeID(badgeId.trim());
        badgeId = '';
      } else {
        badgeId = badgeId + value;
      }
    }
  };

  useEffect(() => {
    if (allowCopyBadgeID) {
      window.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [allowCopyBadgeID]);

  return (
    <HotKeys keyMap={{ COPY_BADGE_ID: ['ctrl+v', 'command+v'] }} handlers={handlers}>
      <div className={classes.root}>
        {!noHeader && <Header />}
        <Wrapper className={className}>{children}</Wrapper>
      </div>
    </HotKeys>
  );
}
