import cx from 'classnames';
import classes from './style.module.sass';
import CustomChip from 'src/@core/components/mui/chip';

const getStatusLabel = (status) => {
  switch (status) {
    case 'declined':
      return 'отменена';
    case 'approved':
      return 'подтверждена';
    case 'hold':
      return 'в процессе';
    case 'done':
      return 'проведена';
    case 'initial':
    default:
      return 'ожидает';
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'declined':
      return 'error';
    case 'approved':
      return 'success';
    case 'hold':
      return 'success';
    case 'done':
      return 'warning';
    case 'initial':
    default:
      return 'warning';
  }
};

export default function MeetingStatus({ status, small = false, className }) {
  const label = getStatusLabel(status);
  const color = getStatusColor(status);
  return (
    <CustomChip label={label} skin="light" color={color} />
    // <div
    //   className={cx(
    //     classes.status,
    //     className,
    //     { [classes.denied]: status === false && !small },
    //     { [classes.granted]: status === true && !small },
    //     { [classes.small]: small }
    //   )}
    // >
    //   {label}
    // </div>
  );
}
