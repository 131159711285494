import cx from 'classnames';
import { setHours, setMinutes } from 'date-fns';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { Field } from 'react-final-form';
import ErrorWithDelay from '../error-with-delay';
import classes from './date-input.module.sass';
import CustomInput from './PickersCustomInput';

export default function DateTimeInputMui({ name, label, className }) {
  return (
    <Field name={name}>
      {({ input }) => (
        <div className={cx(classes.root, className)}>
          {label && <label>{label}</label>}
          <DatePicker
            selected={input.value}
            showTimeSelect
            timeIntervals={20}
            minTime={setHours(setMinutes(new Date(), 0), 8)}
            maxTime={setHours(setMinutes(new Date(), 0), 21)}
            timeFormat="HH:mm"
            dateFormat="dd/MM HH:mm"
            onChange={(date) => {
              let _date = date;
              if (date && date.getHours() < 8) {
                _date = setHours(_date, 8);
              }
              if (date && date.getHours() > 21) {
                _date = setHours(_date, 21);
              }
              input.onChange(_date);
            }}
            customInput={<CustomInput className={classes.input} />}
          />
          <div className={classes.errorWrapper}>
            <ErrorWithDelay name={name} delay={500}>
              {(error) => <span className={classes.error}>{error}</span>}
            </ErrorWithDelay>
          </div>
        </div>
      )}
    </Field>
  );
}
