import { useCallback, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { checkCurrentProject, getProjects, setProject } from '../../store/actions/application';
import { useSelector } from 'react-redux';
import Loader from '../loader';

const handleLoadOptions = (query, callback) => {
  getProjects({ query }).then((projects) => callback(projects));
};

const loadSuggestions = debounce(handleLoadOptions, 200);

export default function ProjectSelect({ className }) {
  const currentProject = useSelector((state) => state.application.currentProject);
  const projects = useSelector((state) => state.application.projects);
  const handleProjectChange = useCallback((project) => {
    setProject(project);
  }, []);

  useEffect(() => {
    getProjects().then((projects) => {
      checkCurrentProject(projects);
    });
  }, []);

  return (
    <AsyncSelect
      className={className}
      defaultOptions={projects}
      cacheOptions
      isClearable
      value={currentProject}
      getOptionLabel={(item) => item.name}
      getOptionValue={(item) => item.uuid}
      loadingMessage={() => 'Загрузка'}
      noOptionsMessage={() => 'Не найдено'}
      placeholder="Все проекты"
      loadOptions={loadSuggestions}
      onChange={handleProjectChange}
      components={{
        LoadingIndicator: () => <Loader variant="xsmall" className="me-2" />,
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(33 37 41 / 50%)' : 'none',
          border: '1px solid #212529',
          '&:hover': {
            border: '1px solid #212529',
          },
          '&:active': {
            border: '1px solid #212529',
          },
          '&:focus': {
            border: '1px solid #212529',
          },
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? '#e7e5e3' : '#fff',
          color: '#333',
        }),
      }}
    />
  );
}
