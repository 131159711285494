import { useQuery } from 'react-query';
import { projectAPIs } from 'src/services';
import { setProject } from 'src/store/actions/application';

function useProjectDetail({ projectUuid }) {
  const fetchProjectData = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await projectAPIs.getProject(projectUuid);
    const data = response.message?.[0];
    setProject(data);
    return data;
  };

  const response = useQuery(['fetchProjectDetail', projectUuid], fetchProjectData, { enabled: !!projectUuid });

  return response;
}

export default useProjectDetail;
