import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error404 from 'src/components/not-found';
import { DOCUMENT_TYPES } from 'src/constants';
import { userAPIs } from 'src/services';
import { createErrorNotification } from 'src/utils/notifications';

function MyTicketPage() {
  const params = useParams();
  const [source, setSource] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (userUuid, projectUuid) => {
    try {
      const document = await userAPIs.downloadUserDocument(userUuid, DOCUMENT_TYPES.CONFIRMATION_LETTER, projectUuid);
      if (document) {
        const src = window.URL.createObjectURL(document);
        setSource(src);
      }
    } catch (e) {
      console.error(e);
      createErrorNotification('У вас пока нет билета');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { userUuid, projectUuid } = params;
    if (userUuid && projectUuid) {
      fetchData(userUuid, projectUuid);
    }
  }, [params]);

  return isLoading ? (
    <CircularProgress style={{ display: 'block', margin: '20vh auto 0', color: '#872FC3' }} />
  ) : source ? (
    <object data={source} style={{ height: '100vh', width: '100%' }} type="application/pdf" />
  ) : (
    <Error404 />
  );
}

export default MyTicketPage;
