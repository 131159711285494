import LoaderSpinner from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import cx from 'classnames';
import { CircularProgress } from '@mui/material';

const sizes = {
  xsmall: {
    width: 20,
    height: 20,
  },
  small: {
    width: 30,
    height: 30,
  },
  medium: {
    width: 50,
    height: 50,
  },
  large: {
    width: 80,
    height: 80,
  },
};

export default function Loader({ size, color }) {
  return <CircularProgress size={size} color={color} />;
}
