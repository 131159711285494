import React, { forwardRef } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';

const TextFieldPhone = forwardRef(
  ({ required, label, name, shrink = true, defaultCountry = 'ru', disabled, ...restProps }, ref) => {
    return (
      <MuiPhoneNumber
        ref={ref}
        required={required}
        label={label}
        InputLabelProps={{ shrink }}
        variant={shrink ? 'outlined' : 'standard'}
        name={name}
        autoFormat={false}
        defaultCountry={defaultCountry}
        {...restProps}
        disabled={disabled}
      />
    );
  }
);

export default TextFieldPhone;
