import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { SelectHookForm } from 'src/@core/components/mui/select';
import { generateRandomPassword } from 'src/utils/common';
import { supportedUserEditValidationSchema } from 'src/utils/formValidator';

function EditForm({ systemRoles, show, onClose, isChangePassword, user, onHandleUpdateUser = () => {} }) {
  const [showPassword, setShowPassword] = useState(false);

  const defaultValues = {
    password: generateRandomPassword(),
    fullName: user?.fullName || '',
    email: user?.email || '',
    roleUuid: user?.roleUuid || '',
    expiredDate: new Date(user?.expiredDate) || '',
  };

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(supportedUserEditValidationSchema(isChangePassword)),
  });

  const handleUpdateUser = (values) => {
    const body = { ...values, expiredDate: format(values.expiredDate, 'yyyy-MM-dd') };
    const { fullName, roleUuid, email, expiredDate, password } = body;
    onHandleUpdateUser(isChangePassword ? { password } : { fullName, roleUuid, email, expiredDate });
    onClose();
  };

  return (
    <Dialog open={show} onClose={onClose}>
      <form onSubmit={handleSubmit(handleUpdateUser)}>
        <DialogTitle>{isChangePassword ? 'Изменить пароль' : 'Изменить пользователя'}</DialogTitle>
        <DialogContent>
          <Stack paddingY="20px">
            {isChangePassword ? (
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Введите Ваш новый пароль"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('password')}
                error={errors.password ? true : false}
                helperText={errors.password?.message}
              />
            ) : (
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Фамилия Имя Отчество"
                    {...register('fullName')}
                    error={errors.fullName ? true : false}
                    helperText={errors.fullName?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Роль"
                    {...register('roleUuid')}
                    error={errors.role ? true : false}
                    helperText={errors.role?.message}
                  /> */}
                  <Controller
                    name="roleUuid"
                    control={control}
                    render={({ field }) => (
                      <SelectHookForm
                        shrink={false}
                        label="Роль"
                        selectProps={{
                          value: field.value,
                          onChange: field.onChange,
                        }}
                        error={errors.role ? true : false}
                        errorComponent={
                          <>{errors.role && <FormHelperText error={true}>{errors.role.message}</FormHelperText>}</>
                        }
                      >
                        {systemRoles.map((value, i) => (
                          <MenuItem key={i} value={value.uuid}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </SelectHookForm>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="email"
                    label="Email"
                    {...register('email')}
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="expiredDate"
                    control={control}
                    render={({ field }) => (
                      <ReactDatePicker
                        minDate={new Date()}
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        customInput={
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Дата окончания доступа"
                            error={errors.expiredDate ? true : false}
                            helperText={errors.expiredDate?.message}
                          />
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Отмена
          </Button>
          <Button type="submit" variant="contained">
            Сохранить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditForm;
