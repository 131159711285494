import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import DateTimeInputMui from 'src/components/date-time-input-mui';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DEFAULT_UTC_OFFSET } from 'src/utils/constant';
import { timeZone, timeZoneConverter } from 'src/utils/time';
import { ru } from 'date-fns/locale';
import { createErrorNotification } from 'src/utils/notifications';

const DateReportDialog = ({ open, onClose = () => {}, onSubmit = () => {} }) => {
  const [value, setValue] = useState([null, null]);
  const handleSubmit = () => {
    if (value.some((el) => !el)) {
      createErrorNotification('Необходимо выбрать даты');
      return;
    }
    console.log('value', value);
    onSubmit(value);
  };
  useEffect(() => {
    if (!open) {
      setValue([null, null]);
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Отчет по дате</DialogTitle>
      <DialogContent>
        <Stack pt={'15px'}>
          <LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>
            <DateRangePicker
              displayStaticWrapperAs="desktop"
              value={value}
              onChange={setValue}
              startText="От"
              endText="До"
              clearText="Очистить"
              todayText="Сегодня"
              cancelText="Отмена"
              toolbarTitle=""
              renderInput={(startProps, endProps) => (
                <Stack direction="row" gap="15px" alignItems="center">
                  <TextField {...startProps} />
                  –
                  <TextField {...endProps} />
                </Stack>
              )}
            />
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateReportDialog;
