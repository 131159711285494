import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.css';

import 'moment/locale/ru';
import Loader from './components/loader';
import { ViewImageModal } from './components/view-image-modal';
import { routes } from './constants';
import { useAbility } from './hooks/useAbility';
import './icon/flag-icons-master/css/flag-icon.css';
import CheckMFAPage from './pages/check-mfa';
import LoginPage from './pages/login';
import MyTicketPage from './pages/my-ticket';
import ProfilePage from './pages/profile';
import ProjectRegistersPage from './pages/project-registers';
import ProjectScubSettingPage from './pages/project-scub';
import ProjectSettingsPage from './pages/project-settings';
import ProjectVizitsPage from './pages/project-vizits';
import ProjectsPage from './pages/projects';
import ProjectsDetailPage from './pages/projects-detail';
import ProjectsMeetingsPage from './pages/projects-meetings';
import ProjectsUsersPage from './pages/projects-users';
import RegisterCreatePage from './pages/register-create';
import SettingMfaPage from './pages/setting-mfa';
import SettingsPage from './pages/settings';
import StaffLoginPage from './pages/staff-login';
import UserCreatePage from './pages/users-create';
import VizitCreatePage from './pages/vizit-create';
import VizitDetailPage from './pages/vizit-detail';
import { checkCurrentProject, getCountries, getProjects } from './store/actions/application';
import { autoLogin } from './store/actions/auth';
import './styles/main.sass';
import { ACCESSES, updateAbility } from './utils/ability';
import { setDefaultTimezone } from './utils/time';
import UserDetailPage from './pages/user-detail';
import UsersPage from './pages/users';
import ProjectOrdersPage from './pages/project-orders';
import ViewPDFModal from './components/modal/ViewPDFModal';
import useCountries from './hooks/useCountries';
import TicketModal from './components/molecules/modal/BadgeModal';

function App() {
  const [init, setInit] = useState(false);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const mfaSuccess = useSelector((state) => state.auth.mfaSuccess);
  const user = useSelector((state) => state.auth.user);
  const staffProfile = useSelector((state) => state.auth.staffProfile);
  const ability = useAbility();

  let redirectRoute;

  if (!user) {
    redirectRoute = routes.login.path;
  } else if (mfaSuccess) {
    redirectRoute = routes.projects.path;
  } else if (mfaEnabled) {
    redirectRoute = routes.checkMfa.path;
  } else if (user?.isStaff) {
    redirectRoute = routes.projectsPage.path.replace(':uuid', user.projectUuid);
  } else {
    redirectRoute = routes.settingMfa.path;
  }
  // const redirectRoute = user ? routes.applications.path : routes.login.path;

  useCountries();

  useEffect(() => {
    autoLogin().then((isLoggedIn) => {
      if (isLoggedIn) {
        getProjects().then((projects) => {
          checkCurrentProject(projects).then(() => setInit(true));
        });
      } else {
        setInit(true);
      }
    });
    setDefaultTimezone();
  }, []);

  useEffect(() => {
    if (staffProfile) {
      updateAbility(ability, staffProfile.accesses);
    }
  }, [staffProfile]);

  if (!init) {
    return <Loader />;
  }

  const canCreateUser = ability.can(ACCESSES.CREATE_USER);
  const canSeeProfile = ability.can(ACCESSES.SEE_PROFILE);

  return (
    <>
      <Routes>
        {user ? (
          user?.isStaff ? (
            <>
              {canSeeProfile && <Route path={routes.profile.path} element={<ProfilePage />} />}
              <Route path={routes.myTicket.path} element={<MyTicketPage />} />
              <Route path="/projects">
                <Route index={true} element={<ProjectsPage />} />
                <Route path=":uuid" element={<ProjectsDetailPage />} />
                <Route path=":uuid/users">
                  <Route index={true} element={<ProjectsUsersPage />} />
                  {canCreateUser && <Route path="create" element={<UserCreatePage />} />}
                </Route>
                <Route path=":uuid/meetings" element={<ProjectsMeetingsPage />} />
                <Route path=":uuid/vizits">
                  <Route index={true} element={<ProjectVizitsPage />} />
                  <Route path=":vizitUuid" element={<VizitDetailPage />} />
                  <Route path="create" element={<VizitCreatePage />} />
                </Route>
                <Route path=":uuid/settings" element={<ProjectSettingsPage />} />
                <Route path=":uuid/registers">
                  <Route index={true} element={<ProjectRegistersPage />} />
                  {canCreateUser && <Route path="create" element={<RegisterCreatePage />} />}
                </Route>
                <Route path=":uuid/scub" element={<ProjectScubSettingPage />} />
                <Route path=":uuid/orders" element={<ProjectOrdersPage />} />
              </Route>
            </>
          ) : (
            <>
              <Route path={routes.profile.path} element={<ProfilePage />} />
              <Route path={routes.settings.path} element={<SettingsPage />} />
              <Route path={routes.myTicket.path} element={<MyTicketPage />} />
              <Route path="/users">
                <Route index={true} element={<UsersPage />} />
                <Route path=":id" element={<UserDetailPage />} />
              </Route>
              <Route path="/projects">
                <Route index={true} element={<ProjectsPage />} />
                <Route path=":uuid" element={<ProjectsDetailPage />} />
                <Route path=":uuid/users">
                  <Route index={true} element={<ProjectsUsersPage />} />
                  <Route path="create" element={<UserCreatePage />} />
                </Route>
                <Route path=":uuid/meetings" element={<ProjectsMeetingsPage />} />
                <Route path=":uuid/vizits">
                  <Route index={true} element={<ProjectVizitsPage />} />
                  <Route path=":vizitUuid" element={<VizitDetailPage />} />
                  <Route path="create" element={<VizitCreatePage />} />
                </Route>
                <Route path=":uuid/settings" element={<ProjectSettingsPage />} />
                <Route path=":uuid/registers">
                  <Route index={true} element={<ProjectRegistersPage />} />
                  <Route path="create" element={<RegisterCreatePage />}></Route>
                </Route>
                <Route path=":uuid/scub" element={<ProjectScubSettingPage />} />
                <Route path=":uuid/orders" element={<ProjectOrdersPage />} />
              </Route>

              {!mfaSuccess && <Route path={routes.settingMfa.path} element={<SettingMfaPage />} />}
              {!mfaSuccess && <Route path={routes.checkMfa.path} element={<CheckMFAPage />} />}
            </>
          )
        ) : (
          <>
            <Route path={routes.login.path} element={<LoginPage />} />
            <Route path={routes.staffLogin.path} element={<StaffLoginPage />} />
          </>
        )}
        <Route path="*" element={<Navigate to={redirectRoute} />} />
      </Routes>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <TicketModal />
      <ViewImageModal />
      <ViewPDFModal />
    </>
  );
}

export default App;
