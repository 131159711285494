import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionsButton from 'src/pages/projects-meetings/ActionsButton';
import { DEFAULT_TIMEZONE } from 'src/utils/time';
import AlertSelectProjectForm from '../../components/alert-select-project-form';
import MeetingFilters from '../../components/meetings-manage/components/Filters';
import Pagination from '../../components/pagination';
// import Table from '../../components/table';
// import TablePanel from '../../components/table/panel';
import { LOCALIZATION } from '../../constants';
import { deleteMeeting, setProject, updateMeetingStatus } from '../../store/actions/application';
import { MEETING_STATUS } from '../../utils/constant';
import eventBus, { EVENTS } from '../../utils/eventBus';
import MeetingStatus from '../status/MeetingStatus';
import { meetingHeadList } from './config';
import classes from './manage-meetings.module.sass';

const headCells = [
  {
    id: 'select',
    numeric: false,
    disablePadding: true,
    hideRightDivider: true,
    label: '',
  },
  {
    id: 'from',
    numeric: false,
    disablePadding: false,
    label: 'от',
  },
  {
    id: 'for',
    numeric: false,
    disablePadding: false,
    label: 'для',
  },
  {
    id: 'dateTime',
    numeric: false,
    disablePadding: false,
    label: 'дата и время',
  },
  {
    id: 'table',
    numeric: false,
    disablePadding: false,
    label: 'стол',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'статус',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

export default function MeetingList({ user, loading, onDownload, queryParams, setQueryParams, isBezviz, reloadTable }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);
  const currentProject = useSelector((state) => state.application.currentProject);
  const projectUuid = currentProject?.uuid;
  const activeTab = queryParams.tab || MEETING_STATUS.initial[LOCALIZATION.en_US];
  const { page: queryPage, limit, query } = queryParams;
  const rowsPerPage = parseInt(limit) || 20;
  const page = Math.max(queryPage || 1, 1);
  const tab = activeTab;

  const [showFilter, setShowFilter] = useState(false);

  // const handleChangePage = (page) => {
  //   setQueryParams({ ...queryParams, page });
  // };

  const handleSelectTab = (name) => () => {
    if (!currentProject) {
      return eventBus.emit(EVENTS.OPEN_ALERT_FORM, {
        onSubmit: ({ projectUuid }) => {
          setProject(projectUuid);
          setQueryParams({ tab: name, page: 1 });
        },
      });
    }
    setQueryParams({ ...queryParams, tab: name, page: 1 });
  };

  useEffect(() => {
    if (!currentProject) {
      return eventBus.emit(EVENTS.OPEN_ALERT_FORM, {
        onSubmit: ({ projectUuid }) => {
          setProject(projectUuid);
        },
      });
    }
  }, [currentProject, activeTab]);

  const tabs = Object.keys(MEETING_STATUS).map((s) => ({
    label: _.upperFirst(_.toLower(MEETING_STATUS[s][LOCALIZATION.ru_RU])),
    count: counts[s],
    icon: MEETING_STATUS[s].icon,
    customIcon: MEETING_STATUS[s].customIcon,
    isActive: activeTab === s,
    onSelect: handleSelectTab(s),
  }));

  const handleDeclineMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        updateMeetingStatus(projectUuid, meeting, MEETING_STATUS.declined[LOCALIZATION.en_US]);
      },
    });
  };

  const handleApproveMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    updateMeetingStatus(projectUuid, meeting, MEETING_STATUS.approved[LOCALIZATION.en_US]);
  };

  const handleDeleteMeeting = (e, meeting) => {
    e.preventDefault();
    e.stopPropagation();
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteMeeting(projectUuid, meeting.uuid, meeting.status);
      },
    });
  };

  const ActionColumn = {
    value: 'action',
    label: 'Действие',
    style: { textAlign: 'right' },
    render: (item) => (
      <div className={classes.actions}>
        {item.status !== MEETING_STATUS.declined[LOCALIZATION.en_US] && (
          <Button variant="outline-dark" className={classes.button} onClick={(e) => handleDeclineMeeting(e, item)}>
            <i className="bi bi-x-lg" />
          </Button>
        )}
        {item.status !== MEETING_STATUS.approved[LOCALIZATION.en_US] && (
          <Button variant="dark" className={classes.button} onClick={(e) => handleApproveMeeting(e, item)}>
            <i className="bi bi-check-lg text-white" />
          </Button>
        )}
        <Button
          variant="outline-danger"
          className={`${classes.button} ${classes.deleteBtn}`}
          onClick={(e) => handleDeleteMeeting(e, item)}
        >
          <i className="bi bi-trash3 ms-2 text-danger" />
        </Button>
      </div>
    ),
  };

  const handleFilter = (filters) => {
    setQueryParams({ ...filters });
  };

  const renderFilters = () => <MeetingFilters onSubmit={handleFilter} />;

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleChangePage = (event, newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({ ...queryParams, page: 1, limit: parseInt(event.target.value, 10) });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap="8px" padding="0 20px">
        <Stack direction="row" gap="8px" flexWrap="wrap">
          {tabs.map((tab, i) => (
            <Button
              key={`tab-${i}`}
              variant={tab.isActive ? 'contained' : 'text'}
              color={tab.isActive ? 'primary' : 'secondary'}
              // startIcon={tab.customIcon ? tab.customIcon : <UserIcon icon={tab.icon} />}
              onClick={tab.onSelect}
            >
              {tab.label} ({tab.count || 0})
            </Button>
          ))}
        </Stack>

        <Button
          endIcon={showFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          sx={{ '& .MuiButton-endIcon': { margin: 0 } }}
          onClick={handleToggleFilter}
        >
          фильтр
        </Button>
      </Stack>

      <Collapse in={showFilter}>
        <Box padding="16px 20px 0" margin="0 24px">
          <MeetingFilters onSubmit={handleFilter} />
        </Box>
      </Collapse>

      <TableContainer id="meetings-manage-table" component={Paper} sx={{ position: 'relative', margin: '20px 0 40px' }}>
        {loading && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ position: 'absolute', inset: '0', zIndex: 2, background: '#ffffff40', padding: '60px 0' }}
          >
            <CircularProgress />
          </Stack>
        )}
        <Table size="medium" sx={{ minWidth: 750, minHeight: 150 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow sx={{ background: '#F9FAFC' }}>
              {headCells.map((headCell, indexHead) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  width={headCell.width}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 600, lineHeight: '24px' }}>
                      {headCell.label}
                    </Typography>
                    {!headCell.hideRightDivider && indexHead < headCells.length - 1 && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ width: '2px', height: '14px !important', margin: 'auto 0' }}
                      />
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {counts[tab] === 0 && !loading ? (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <Typography align="center">Нет данных</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {list.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`row-${index}`}
                      role="checkbox"
                      sx={{ cursor: 'pointer' }}
                    //   onClick={() => navigate(`/projects/${row.uuid}`)}
                    >
                      <TableCell width="40px" sx={{ padding: '0 2px !important' }}>
                        <Checkbox />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" gap="8px">
                          <Stack>
                            <Typography variant="subtitle2" color={theme.palette.text.primary}>
                              {row.companyOwner}
                            </Typography>
                            <Link href={`/profile/${row.ownerUuid}`} variant="caption" target="_blank">
                              {row.fullNameOwner || ''}
                            </Link>
                            <Typography variant="caption">{row.email}</Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" gap="8px">
                          <Stack>
                            <Typography variant="subtitle2" color={theme.palette.text.primary}>
                              {row.companyMember}
                            </Typography>
                            <Link href={`/profile/${row.memberUuid}`} variant="caption" target="_blank">
                              {row.fullNameMember || ''}
                            </Link>
                            <Typography variant="caption">{row.email}</Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ width: 'fit-content', whiteSpace: 'nowrap', color: theme.palette.text.secondary }}
                        >
                          {`${moment.tz(row?.startTime, DEFAULT_TIMEZONE).format('DD/MM HH:mm')} - ${moment
                            .tz(row?.endTime, DEFAULT_TIMEZONE)
                            .format('HH:mm')}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ width: '60px', whiteSpace: 'nowrap', color: theme.palette.text.secondary }}
                        >
                          {row.tableNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <MeetingStatus status={row?.status} />
                      </TableCell>
                      <TableCell>
                        <ActionsButton projectUuid={projectUuid} item={row} reloadTable={reloadTable} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
        {counts[tab] > 0 && (
          <TablePagination
            labelRowsPerPage="Рядов на странице:"
            labelDisplayedRows={({ count, from, to }) => {
              return `${from}-${to} из ${count}`;
            }}
            page={page - 1}
            component="div"
            count={counts[tab]}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 20, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ marginTop: '20px' }}
          />
        )}
      </TableContainer>

      {/* <div className={classes.root}>
        <TablePanel tabs={tabs} showUpload={false} showDownload={false} renderFilters={renderFilters} />

        <div className={classes.tableWrap}>
          <Table
            headList={[...meetingHeadList, ActionColumn]}
            bodyList={list}
            className={classes.table}
            loading={loading}
            id="uuid"
          />
          <Pagination
            totalCount={counts[activeTab]}
            currentPage={queryParams.page}
            onChangePage={handleChangePage}
            amountPerPage={10}
          />
        </div>

        <AlertSelectProjectForm />
      </div> */}
    </>
  );
}
