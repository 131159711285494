import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

function TextFieldSearch({ searchText, onSearch }) {
  return (
    <TextField
      size="small"
      fullWidth
      value={searchText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ color: 'text.primary' }}>
            <Search />
          </InputAdornment>
        ),
      }}
      onChange={(e) => onSearch(e.target.value)}
    />
  );
}

export default TextFieldSearch;
