import React, { useState } from 'react';
import UserComponent from './users';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';
import { Avatar, Card, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material';
import classes from '../vizits-search.module.sass';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function CompanySection({ data }) {
  const members = data?.members || [];
  const pageCounter = Math.ceil(members.length / 4);
  const [currentPage, setCurrentPage] = useState(1);
  const [isExpand, setIsExpand] = useState(true);

  const moreThanFourMembers = members.length > 4;

  return (
    <Card className={classes.company}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} align="center">
            {data.logo ? (
              <img src={data.logo} alt="logo" className={classes.logo} />
            ) : (
              <Avatar sx={{ width: '70px', height: '70px' }} />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Typography
              variant="h6"
              sx={{
                // whiteSpace: 'nowrap',
                // textOverflow: 'ellipsis',
                // overflow: 'hidden',
                hyphens: 'auto',
                textAlign: 'center',
              }}
            >
              {data.name}
            </Typography>
            <Typography
              color="#8F4ED3"
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              {data.email}
            </Typography>
          </Grid>
          <Grid
            item
            xs={moreThanFourMembers ? 8 : 12}
            sm={moreThanFourMembers ? 8 : 12}
            md={moreThanFourMembers ? 3 : 5}
          >
            <Typography color="#8A8A8E" className={classes.desc}>
              {data.description}
            </Typography>
          </Grid>
          {moreThanFourMembers && (
            <Grid item xs={4} sm={4} md={2} algin="center">
              <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="8px">
                <IconButton onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
                  <KeyboardArrowLeft />
                </IconButton>
                <Typography>{`${currentPage} из ${pageCounter}`}</Typography>
                <IconButton onClick={() => setCurrentPage(Math.min(currentPage + 1, pageCounter))}>
                  <KeyboardArrowRight />
                </IconButton>
              </Stack>
            </Grid>
          )}
        </Grid>
        {members && members.length > 0 && (
          <Grid container spacing={2} marginTop="8px">
            {members.slice((currentPage - 1) * 4, currentPage * 4).map((user, i) => (
              <Grid key={i} item xs={12} sm={12} md={12} lg={6} xl={6}>
                <UserComponent user={user} isExpand={isExpand} />
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>

      {/* <div className={classes.expandBtn} onClick={() => setIsExpand(!isExpand)}>
        {isExpand ? <KeyboardArrowDownIcon /> : <KeyboardArrowUp />}
      </div> */}
    </Card>
  );
}

export default CompanySection;
