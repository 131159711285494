import React from 'react';

function ClockIcon({ ...props }) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 0H6.5V1.90476H12.5V0ZM8.5 12.381H10.5V6.66667H8.5V12.381ZM16.53 6.08571L17.95 4.73333C17.52 4.24762 17.05 3.79048 16.54 3.39048L15.12 4.74286C13.57 3.5619 11.62 2.85714 9.5 2.85714C4.53 2.85714 0.5 6.69524 0.5 11.4286C0.5 16.1619 4.52 20 9.5 20C14.48 20 18.5 16.1619 18.5 11.4286C18.5 9.40952 17.76 7.55238 16.53 6.08571ZM9.5 18.0952C5.63 18.0952 2.5 15.1143 2.5 11.4286C2.5 7.74286 5.63 4.7619 9.5 4.7619C13.37 4.7619 16.5 7.74286 16.5 11.4286C16.5 15.1143 13.37 18.0952 9.5 18.0952Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ClockIcon;
