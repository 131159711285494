import { Button, MenuItem, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SelectHookForm } from 'src/@core/components/mui/select';
import { createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';

function StepOne({ onSubmit, onCancelDuplicate }) {
  const { register, handleSubmit } = useForm();
  const projects = useSelector((state) => state.application.projects);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="13px" sx={{ maxWidth: '420px', margin: 'auto' }}>
        <Typography variant="body2">Выберите ивент, чтобы выбрать анкету для дублирования:</Typography>
        <SelectHookForm selectProps={{ placeholder: 'Выберите проект', ...register('projectUuid') }}>
          {(projects || []).map((value, i) => (
            <MenuItem key={i} value={value.uuid}>
              <>{value.name}</>
            </MenuItem>
          ))}
        </SelectHookForm>
        <Stack direction="row" gap="16px" marginTop="18px" sx={{ '& > *': { flex: '1 1 50%' } }}>
          <Button variant="contained" type="submit" size="large">
            далее
          </Button>
          <Button variant="outlined" color="secondary" size="large" onClick={onCancelDuplicate}>
            назад
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function StepTwo({ roles, onSubmit, defaultValues, onCancelDuplicate }) {
  const { register, handleSubmit } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="13px" sx={{ maxWidth: '420px', margin: 'auto' }}>
        <Typography variant="body2">Выберите роль, чтобы дублировать поля из её анкеты:</Typography>
        <SelectHookForm selectProps={{ placeholder: 'Выберите проект', ...register('roleUuid') }}>
          {(roles || []).map((value, i) => (
            <MenuItem key={i} value={value.id}>
              <>{value.name?.ru_RU}</>
            </MenuItem>
          ))}
        </SelectHookForm>
        <Stack direction="row" gap="16px" marginTop="18px" sx={{ '& > *': { flex: '1 1 50%' } }}>
          <Button variant="contained" type="submit" size="large">
            далее
          </Button>
          <Button variant="outlined" color="secondary" size="large" onClick={onCancelDuplicate}>
            назад
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function AnketaDuplicateForm({ projectUuid, roleUuid, onCancelDuplicate, refetchAnketaData }) {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const [roles, setRoles] = useState([]);

  async function handleStepOneSubmit(data) {
    setValues(data);
    fetchData(data.projectUuid);
    setStep(2);
  }

  async function handleStepTwoSubmit(data) {
    try {
      await request('/internal/anketas/duplicate', {
        method: 'POST',
        body: {
          projectUuid: projectUuid,
          roleUuid: roleUuid,
          duplicatedProjectUuid: data.projectUuid,
          duplicatedRoleUuid: data.roleUuid,
        },
      });
      refetchAnketaData();
    } catch (error) {
      console.error(error);
      toast.error('Шаблон не найден');
    }
  }

  const fetchData = async (projectUuid) => {
    try {
      const [resRoles] = await Promise.all([request(`/roles?projectUuid=${projectUuid}`)]);
      setRoles(resRoles.message || []);
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    }
  };

  return step === 1 ? (
    <StepOne onSubmit={handleStepOneSubmit} onCancelDuplicate={onCancelDuplicate} />
  ) : (
    <StepTwo
      roles={roles}
      onSubmit={handleStepTwoSubmit}
      defaultValues={values}
      onCancelDuplicate={onCancelDuplicate}
    />
  );
}

export default AnketaDuplicateForm;
