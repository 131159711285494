import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { LOCALIZATION, TEXT } from 'src/constants';
import Members from './components/members';

function AddMemberSection({
  projectUuid,
  userUuid,
  isLoadingVizit,
  vizits,
  setVizits,
  membersVizits,
  setMembersVizits,
}) {
  const lang = LOCALIZATION.ru_RU;

  const [showAdd, setShowAdd] = useState(false);

  return (
    vizits && (
      <>
        <Stack gap="30px" paddingBottom="14px">
          <Stack direction="row" gap="8px" flexWrap="wrap" justifyContent="space-between">
            <Typography variant="h6">{TEXT[lang].MEMBERS}</Typography>
            {(membersVizits || []).length === 0 && (
              <Button variant="outlined" endIcon={<AddIcon />} onClick={() => setShowAdd(true)}>
                {TEXT[lang].ADD_MEMBER}
              </Button>
            )}
          </Stack>
          <Grid container spacing={2}>
            {showAdd && (
              <Members
                projectUuid={projectUuid}
                userUuid={userUuid}
                membersVizits={membersVizits}
                setMembersVizits={setMembersVizits}
                setShowAdd={setShowAdd}
              />
            )}
            {(membersVizits || []).map((member, i) => (
              <Members
                key={i}
                member={member}
                projectUuid={projectUuid}
                userUuid={userUuid}
                membersVizits={membersVizits}
                setMembersVizits={setMembersVizits}
              />
            ))}
          </Grid>
        </Stack>
      </>
    )
  );
}

export default AddMemberSection;
