import { Box, Button, FormControl, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import AuthLayout from 'src/layouts/AuthLayout';
import { checkMfa } from '../../store/actions/auth';
import classes from './check.mfa.module.sass';
import { validatorMessage } from 'src/utils/formValidator';

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { mt: theme.spacing(8) },
}));

const schema = yup.object().shape({
  otp: yup
    .string()
    .length(6, validatorMessage.REQUIRE_LENGTH_CHARACTER.replace('{number}', 6))
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
});

export default function CheckMFAPage() {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { otp: '' },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  return (
    <AuthLayout>
      <div className={classes.main}>
        <form  onSubmit={handleSubmit(checkMfa)} className={classes.form}>
          <Box>
            <TypographyStyled variant="h5">Пожалуйста, введите цифровой 6-значный код для входа</TypographyStyled>
            <Typography variant="body2">Временный код доступен в приложении</Typography>
          </Box>

          <Stack>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <Controller
                name="otp"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.otp)}
                    placeholder="______"
                    sx={{ '& input': { letterSpacing: '4px' } }}
                  />
                )}
              />
              {errors.otp && <FormHelperText sx={{ color: 'error.main' }}>{errors.otp.message}</FormHelperText>}
            </FormControl>
          </Stack>
          <Button type="submit" variant="contained" size="large" disabled={isSubmitting} className={classes.submit}>
            Войти
          </Button>
        </form>
      </div>
    </AuthLayout>
  );
}
