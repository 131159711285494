import { Col, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import classes from './filters.module.sass';
import DateTimeInput from '../../date-time-input';
import moment from 'moment-timezone';
import { timeZone, timeZoneConverter } from '../../../utils/time';
import { DEFAULT_UTC_OFFSET } from '../../../utils/constant';
import DateTimeInputMui from 'src/components/date-time-input-mui';
import { Button } from '@mui/material';

function MeetingFilters({ onSubmit }) {
  const location = useLocation();
  const query = qs.parse(location.search);

  const initialValues = {};

  const handleSubmit = (filters) => {
    const since = timeZoneConverter(
      moment(filters.since),
      DEFAULT_UTC_OFFSET,
      timeZone(moment(filters.since).toDate())
    );
    const till = timeZoneConverter(moment(filters.till), DEFAULT_UTC_OFFSET, timeZone(moment(filters.till).toDate()));
    return onSubmit({
      ...filters,
      ...(filters.since ? { since: since.unix() * 1000 } : {}),
      ...(filters.till ? { till: till.unix() * 1000 } : {}),
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        if (!values.since && !values.till) {
          errors.since = 'Required';
          errors.till = 'Required';
        }
        if (values.since && !values.till) {
          errors.till = 'Required';
        }
        if (!values.since && values.till) {
          errors.since = 'Required';
        }
        if (values.since && values.till && moment(values.since).isAfter(moment(values.till))) {
          errors.since = 'Invalid date range';
        }
        return errors;
      }}
    >
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.filters}>
          <Row style={{ alignItems: 'flex-end' }}>
            <Col xs={12} md={6} xl={3}>
              <DateTimeInputMui name="since" label="Дата начала" className={classes.date} />
            </Col>
            <Col xs={12} md={6} xl={3}>
              <DateTimeInputMui name="till" label="Дата окончания" className={classes.date} />
            </Col>

            <Col xs={12} md={6} xl={3}>
              <Button
                variant="outlined"
                size="lg"
                className={classes.button}
                onClick={() => {
                  form.restart();
                  onSubmit({});
                }}
              >
                Очистить фильтры
              </Button>
            </Col>
            <Col xs={12} md={6} xl={3}>
              <Button type="submit" variant="contained" size="lg" className={classes.button}>
                Искать
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
}

export default MeetingFilters;
