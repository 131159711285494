import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArticleIcon from '@mui/icons-material/Article';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFullName } from '../../utils/common';
import {
  DEFAULT_BIRTH_DAY,
  EVENTS,
  GENDER_OPTIONS,
  LOCALIZATION,
  MESSAGES,
  REGEX_E164_PHONE,
  TEXT,
} from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { userValidationSchema } from '../../utils/formValidator';
import { createNotification } from '../../utils/notifications';
import {
  SCOPE_COMPANY,
  TYPE_ACTIVITY_COMPANY,
  PURPOSE_VISITING,
  INTERESTED_SECTIONS,
  ALLOWED_RECEIVE_NEWS,
  HOW_TO_KNOW,
  JOB_STATUS,
  EXTENT_COMMERCIAL_DECISIONS,
  AMOUNT_POTENTIAL_PURCHASES,
  BLOCKS_CONGRESS_PROGRAM,
} from './options';
import classes from './user-form.module.sass';
import MuiPhoneNumber from 'material-ui-phone-number';
import { editUser } from '../../store/actions/application';
import { userAPIs } from '../../services';
import TextFieldPhone from 'src/@core/components/mui/textfield/TextFieldPhone';
import { SelectHookForm } from 'src/@core/components/mui/select';

let fetchPassportInterval;
let isTyping;
const DEFAULT_RUSSIA = 'Российская Федерация';
export function UserForm({ user, isLocked, onAddNewAccreadition }) {
  const { id } = useParams();
  const countries = useSelector((state) => state.application.countries);
  const userCompany = useSelector((state) => state.auth.company);
  const lang = LOCALIZATION.ru_RU;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isAllowedPersonalData, setIsAllowedPersonalData] = useState(false);
  const [isAllowedReceiveNews, setIsAllowedReceiveNews] = useState(false);
  const [useOtherJobStatus, setUseOtherJobStatus] = useState(false);
  const [useOtherTypeActivityCompany, setUseOtherTypeActivityCompany] = useState(false);
  const [useOtherPurposeVisiting, setUseOtherPurposeVisiting] = useState(false);
  const [useOtherScopeCompany, setUseOtherScopeCompany] = useState(false);
  const [useUseOtherInterestedSections, setUseOtherInterestedSections] = useState(false);
  const [useUseOtherHowToKnow, setUseOtherHowToKnow] = useState(false);
  const [errorCheckbox, setErrorCheckbox] = useState(false);
  const citizenshipRef = useRef(null);
  const checkboxRef = useRef(null);
  const [emptyOther, setEmptyOther] = useState({});

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        fullname: user ? getFullName(user) : '',
        city: user?.city ?? userCompany?.extLegalAddress,
        gender: user?.gender,
        email: user?.email,
        phone: user?.phone,
        citizenship: user?.citizenship ? user.citizenship.toString() : '',
        birthday: user?.birthday ?? DEFAULT_BIRTH_DAY,
        companyName: user?.properties?.companyName ?? userCompany?.name ?? '',
        document_number: user?.properties?.document_number ?? user?.passport?.properties?.document_number ?? '',
        issued_by: user?.properties?.issued_by ?? user?.passport?.properties?.issued_by ?? '',
        scopeCompany: user?.properties?.scopeCompany ?? [],
        otherScopeCompany: user?.properties?.otherScopeCompany ?? '',
        typeActivityCompany: user?.properties?.typeActivityCompany ?? [],
        otherTypeActivityCompany: user?.properties?.otherTypeActivityCompany ?? '',
        purposeVisiting: user?.properties?.purposeVisiting ?? [],
        otherPurposeVisiting: user?.properties?.otherPurposeVisiting ?? '',
        interestedSections: user?.properties?.interestedSections ?? [],
        otherInterestedSections: user?.properties?.otherInterestedSections ?? '',
        interestedProgram: user?.properties?.interestedProgram ?? '',
        howToKnow: user?.properties?.howToKnow ?? [],
        otherHowToKnow: user?.properties?.otherHowToKnow ?? '',
        allowedReceiveNews: user?.properties?.allowedReceiveNews ?? '',
        allowedPersonalData: user?.properties?.allowedPersonalData ?? '',
        jobStatus: user?.properties?.jobStatus ?? '',
        // otherJobStatus: user?.properties?.otherJobStatus ?? '',
        extentCommercialDecisions: user?.properties?.extentCommercialDecisions ?? [],
        amountPotentialPurchases: user?.properties?.amountPotentialPurchases ?? [],
        blocksCongressProgram: user?.properties?.blocksCongressProgram ?? [],
        legalFormCountry: user?.properties?.legalFormCountry ?? DEFAULT_RUSSIA,
        legalForm: user?.properties?.legalForm ?? '',
        legalFormIndex: user?.properties?.legalFormIndex ?? '',
        legalFormCity: user?.properties?.legalFormCity ?? '',
        legalFormTel: user?.properties?.legalFormTel ?? userCompany?.phone ?? '',
        legalFormWeb: user?.properties?.legalFormWeb ?? userCompany?.webAddress ?? '',
      }),
      [user]
    ),
    resolver: yupResolver(userValidationSchema),
  });

  const onSubmit = async (values) => {
    if (!isAllowedPersonalData) {
      checkboxRef?.current?.focus();
      setErrorCheckbox(true);
      return;
    }
    if (Object.values(emptyOther).some((key) => key)) {
      return;
    }
    try {
      setIsSubmitting(true);
      const {
        fullname,
        birthday,
        gender,
        email,
        phone,
        companyName,
        citizenship,
        document_number,
        city,
        issued_by,
        scopeCompany,
        otherScopeCompany,
        typeActivityCompany,
        otherTypeActivityCompany,
        purposeVisiting,
        otherPurposeVisiting,
        interestedSections,
        otherInterestedSections,
        interestedProgram,
        howToKnow,
        otherHowToKnow,
        blocksCongressProgram,
        allowedReceiveNews,
        allowedPersonalData,
        jobStatus,
        otherJobStatus,
        extentCommercialDecisions,
        amountPotentialPurchases,
        legalForm,
        legalFormCountry,
        legalFormIndex,
        legalFormCity,
        legalFormTel,
        legalFormWeb,
      } = values;

      const arrName = fullname?.split(' ') ?? [];
      const lastName = arrName[0];
      const firstName = arrName.length > 2 ? arrName[1] : '';
      const middleName = arrName.length > 2 ? arrName.slice(2).join(' ') : arrName[1];

      const payloadUser = {
        firstName,
        lastName,
        middleName,
        birthday,
        citizenship: parseInt(citizenship),
        city,
        email,
        gender,
        phone,
        properties: {
          ...(companyName ? { companyName } : {}),
          ...(scopeCompany ? { scopeCompany } : {}),
          ...(otherScopeCompany ? { otherScopeCompany } : {}),
          ...(typeActivityCompany ? { typeActivityCompany } : {}),
          ...(otherTypeActivityCompany ? { otherTypeActivityCompany } : {}),
          ...(purposeVisiting ? { purposeVisiting } : {}),
          ...(otherPurposeVisiting ? { otherPurposeVisiting } : {}),
          ...(interestedSections ? { interestedSections } : {}),
          ...(otherInterestedSections ? { otherInterestedSections } : {}),
          ...(interestedProgram ? { interestedProgram } : {}),
          ...(howToKnow ? { howToKnow } : {}),
          ...(otherHowToKnow ? { otherHowToKnow } : {}),
          ...(blocksCongressProgram ? { blocksCongressProgram } : {}),
          allowedReceiveNews: isAllowedReceiveNews,
          allowedPersonalData: isAllowedPersonalData,
          // ...(allowedReceiveNews ? { allowedReceiveNews } : {}),
          // ...(allowedPersonalData ? { allowedPersonalData } : {}),
          ...(jobStatus ? { jobStatus } : {}),
          ...(otherJobStatus ? { otherJobStatus } : {}),
          ...(extentCommercialDecisions ? { extentCommercialDecisions } : {}),
          ...(amountPotentialPurchases ? { amountPotentialPurchases } : {}),
          ...(legalForm ? { legalForm } : { legalForm: '' }),
          ...(legalFormCountry ? { legalFormCountry } : { legalFormCountry: '' }),
          ...(legalFormIndex ? { legalFormIndex } : { legalFormIndex: '' }),
          ...(legalFormCity ? { legalFormCity } : { legalFormCity: '' }),
          ...(legalFormTel && REGEX_E164_PHONE.test(legalFormTel) ? { legalFormTel } : { legalFormTel: '' }),
          ...(legalFormWeb ? { legalFormWeb } : { legalFormWeb: '' }),
        },
        ...(user?.readyToAccreditate === false && { readyToAccreditate: true }),
      };

      const payloadPassport = {
        nationality: parseInt(citizenship),
        document_number,
        birth_date: birthday,
        issued_by,
      };

      // await editUser({ uuid: user.uuid, updatingUserFields: payloadUser, dataProperties: payloadPassport });
      await editUser({ uuid: user.uuid, ...payloadUser, ...payloadPassport });

      // createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      setIsChanged(false);
      // setIsValidForm(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [focusingField, setFocusingField] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const nameRef = useRef(null);
  const extLegalAddressRef = useRef(null);

  const getSuggestion = async (query, field = '', isAddress) => {
    if (isSearching) return;
    try {
      setIsSearching(true);
      const res = isAddress ? await userAPIs.getSuggestionAddress(query) : await userAPIs.getSuggestionCompany(query);
      setSuggestions(res.message || []);
      setFocusingField(field);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSearching(false);
    }
  };

  const renderSuggestion = useCallback(
    (field, ref, isAddress) => {
      const handleSelect = (suggestion) => {
        setFocusingField('');
        if (isAddress) {
          suggestion && setValue('city', suggestion);
          suggestion && setValue('legalFormCity', suggestion.split(',')?.[0]);
          suggestion && setValue('legalFormCountry', DEFAULT_RUSSIA);
        } else {
          suggestion.shortName && setValue('companyName', suggestion.shortName);
          suggestion.emails?.[0] && setValue('email', suggestion.emails?.[0]);
          suggestion.phones?.[0] && setValue('phone', suggestion.phones?.[0]);
          suggestion && setValue('legalFormCountry', DEFAULT_RUSSIA);
          suggestion.city && setValue('legalFormCity', suggestion.city);
          suggestion.address && setValue('city', suggestion.address);
        }
      };

      return (
        <Popper
          open={focusingField === field && suggestions?.length > 0}
          anchorEl={ref.current}
          placement="bottom-start"
          transition
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper className={classes.suggestions}>
                <ClickAwayListener onClickAway={() => setFocusingField('')}>
                  <MenuList
                    sx={{
                      maxWidth: '50vw',
                      maxHeight: '450px',
                      overflow: 'scroll',
                    }}
                  >
                    {suggestions.map((suggest, i) => (
                      <MenuItem key={i} onClick={() => handleSelect(suggest)}>
                        {isAddress ? `${suggest}` : `${suggest.shortName} - ${suggest.managerName}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      );
    },
    [suggestions, focusingField]
  );

  const handleChangeTextField = (e, field, isAddress) => {
    const value = e.target.value;
    setValue(field, value);
    clearTimeout(isTyping);
    isTyping = setTimeout(() => {
      getSuggestion(value, field, isAddress);
    }, 1000);
  };

  const handleForceEdit = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: TEXT[lang].CONFIRM,
      content: `${MESSAGES[lang].FORCE_EDIT_CONFIRM}`,
      onOk: () => {
        editUser({ uuid: user.uuid, forceEdit: true });
      },
    });
  };

  useEffect(() => {
    const subscription = watch((values) => {
      setIsChanged(true);
      let isValid = false;

      // const jobStatus = getValues('jobStatus');
      // const isUseOtherJobStatus =
      //   jobStatus &&
      //   (jobStatus === JOB_STATUS[lang]['Другое(укажите)'] ||
      //     !Object.keys(JOB_STATUS[lang]).find((type) => type === jobStatus));
      // setUseOtherJobStatus(isUseOtherJobStatus);
      // if (isUseOtherJobStatus) {
      //   isValid = isValid && Boolean(getValues('otherJobStatus'));
      // }

      const typeActivityCompany = getValues('typeActivityCompany');
      const isUseOtherTypeActivityCompany =
        typeActivityCompany && typeActivityCompany.includes(TYPE_ACTIVITY_COMPANY[lang]['Другое']);
      setUseOtherTypeActivityCompany(isUseOtherTypeActivityCompany);
      if (isUseOtherTypeActivityCompany) {
        setEmptyOther((old) => ({ ...old, otherTypeActivityCompany: !Boolean(getValues('otherTypeActivityCompany')) }));
        isValid = isValid && Boolean(getValues('otherTypeActivityCompany'));
      } else {
        setEmptyOther((old) => ({ ...old, otherTypeActivityCompany: false }));
      }

      const purposeVisiting = getValues('purposeVisiting');
      const isUseOtherPurposeVisiting = purposeVisiting && purposeVisiting.includes(PURPOSE_VISITING[lang]['Другое']);
      setUseOtherPurposeVisiting(isUseOtherPurposeVisiting);
      if (isUseOtherPurposeVisiting) {
        setEmptyOther((old) => ({ ...old, otherPurposeVisiting: !Boolean(getValues('otherPurposeVisiting')) }));
        isValid = isValid && Boolean(getValues('otherPurposeVisiting'));
      } else {
        setEmptyOther((old) => ({ ...old, otherPurposeVisiting: false }));
      }

      const scopeCompany = getValues('scopeCompany');
      const isUseOtherScopeCompany = scopeCompany && scopeCompany.includes(SCOPE_COMPANY[lang]['Другое']);
      setUseOtherScopeCompany(isUseOtherScopeCompany);
      if (isUseOtherScopeCompany) {
        setEmptyOther((old) => ({ ...old, otherScopeCompany: !Boolean(getValues('otherScopeCompany')) }));
        isValid = isValid && Boolean(getValues('otherScopeCompany'));
      } else {
        setEmptyOther((old) => ({ ...old, otherScopeCompany: false }));
      }

      const interestedSections = getValues('interestedSections');
      const isUseOtherInterestedSections =
        interestedSections && interestedSections.includes(INTERESTED_SECTIONS[lang]['Другое']);
      setUseOtherInterestedSections(isUseOtherInterestedSections);
      if (isUseOtherInterestedSections) {
        setEmptyOther((old) => ({ ...old, otherInterestedSections: !Boolean(getValues('otherInterestedSections')) }));
        isValid = isValid && Boolean(getValues('otherInterestedSections'));
      } else {
        setEmptyOther((old) => ({ ...old, otherInterestedSections: false }));
      }

      // const howToKNow = getValues('howToKnow');
      // const isUseOtherHowToKnow =
      //   howToKNow &&
      //   (howToKNow === HOW_TO_KNOW[lang]['Другое'] ||
      //     !Object.keys(HOW_TO_KNOW[lang]).find((type) => type === howToKNow));
      // setUseOtherHowToKnow(isUseOtherHowToKnow);
      // if (isUseOtherHowToKnow) {
      //   isValid = isValid && Boolean(getValues('otherHowToKnow'));
      // }

      const requiredFields = [
        'fullname',
        // 'gender',
        'companyName',
        'city',
        'phone',
        'email',
        'scopeCompany',
        'legalFormCountry',
        'legalFormCity',
      ];
      const filledFields = requiredFields.filter((key) => values[key]);
      isValid = isValid && filledFields.length === requiredFields.length;
      setIsValidForm(filledFields.length === requiredFields.length);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isScanning) {
      if (user) {
        setValue('fullname', getFullName(user));
        setValue('birthday', user.birthday || DEFAULT_BIRTH_DAY);
        setValue('gender', user.gender);
        setValue('phone', user.phone);

        setValue('citizenship', user.citizenship ? user.citizenship.toString() : '');
        if (citizenshipRef?.current) {
          citizenshipRef.current.value = user.citizenship ? user.citizenship.toString() : '';
        }
      }
      if (user?.passport) {
        if (
          user?.passport?.properties?.document_number &&
          user?.passport?.properties?.issued_by &&
          user?.passport?.properties?.nationality &&
          user?.passport?.properties?.birth_date &&
          user?.birthday &&
          user?.gender &&
          user?.firstName &&
          user?.lastName &&
          user?.citizenship &&
          user?.email &&
          user?.phone
        ) {
          clearInterval(fetchPassportInterval);
          setIsScanning(false);
        }
        if (user?.passport.properties?.document_number) {
          setValue('document_number', user?.passport.properties?.document_number);
        }
        if (user?.passport.properties?.issued_by) {
          setValue('issued_by', user?.passport.properties?.issued_by);
        }
        setIsChanged(false);
      }
    } else {
      if (user && user.properties && !user.passport) {
        if (user?.properties?.document_number) {
          setValue('document_number', user?.properties?.document_number);
        }
        if (user?.properties?.issued_by) {
          setValue('issued_by', user?.properties?.issued_by);
        }
        // const typeActivityCompany = user?.properties?.typeActivityCompany;
        // setValue('typeActivityCompany', typeActivityCompany);
        // if (!Object.keys(TYPE_ACTIVITY_COMPANY[lang]).find((type) => type === typeActivityCompany)) {
        //   setUseOtherTypeActivityCompany(true);
        //   setValue('otherTypeActivityCompany', typeActivityCompany);
        // }
        // setValue('comment', user?.properties?.comment);
        // const purposeVisiting = user?.properties?.purposeVisiting;
        // setValue('purposeVisiting', purposeVisiting);
        // if (!Object.keys(PURPOSE_VISITING[lang]).find((type) => type === purposeVisiting)) {
        //   setUseOtherPurposeVisiting(true);
        //   setValue('otherPurposeVisiting', purposeVisiting);
        // }
        // setValue('allowedReceiveNews', user?.properties?.allowedReceiveNews);
        // setValue('allowedPersonalData', user?.properties?.allowedPersonalData);
        setIsAllowedReceiveNews(user?.properties?.allowedReceiveNews);
        setIsAllowedPersonalData(user?.properties?.allowedPersonalData);
        // const jobStatus = user?.properties?.jobStatus;
        // setValue('jobStatus', jobStatus);
        // if (!Object.keys(JOB_STATUS[lang]).find((type) => type === jobStatus)) {
        //   setUseOtherJobStatus(true);
        //   setValue('otherJobStatus', jobStatus);
        // }
        setValue('jobStatus', user?.properties?.jobStatus);
        setValue('extentCommercialDecisions', user?.properties?.extentCommercialDecisions);
        setValue('amountPotentialPurchases', user?.properties?.amountPotentialPurchases);
        setValue('companyName', user?.properties?.companyName);
        setValue('blocksCongressProgram', user?.properties?.blocksCongressProgram);
        setValue('legalForm', user?.properties?.legalForm);
        setValue('legalFormCountry', user?.properties?.legalFormCountry);
        setValue('legalFormIndex', user?.properties?.legalFormIndex);
        setValue('legalFormCity', user?.properties?.legalFormCity);
        setValue('legalFormTel', user?.properties?.legalFormTel);
        setValue('legalFormWeb', user?.properties?.legalFormWeb);
        setValue('howToKnow', user?.properties?.howToKnow);
        setIsChanged(false);
      }
    }

    if (user && user.properties) {
      const typeActivityCompany = user?.properties?.typeActivityCompany;
      const otherTypeActivityCompany = user?.properties?.otherTypeActivityCompany;
      if (typeActivityCompany) {
        setValue('typeActivityCompany', typeActivityCompany);
        if (typeActivityCompany.includes(TYPE_ACTIVITY_COMPANY[lang].Другое)) {
          setUseOtherTypeActivityCompany(true);
          setValue('otherTypeActivityCompany', otherTypeActivityCompany);
        }
      }
      const purposeVisiting = user?.properties?.purposeVisiting;
      const otherPurposeVisiting = user?.properties?.otherPurposeVisiting;
      if (purposeVisiting) {
        setValue('purposeVisiting', purposeVisiting);
        if (purposeVisiting.includes(PURPOSE_VISITING[lang].Другое)) {
          setUseOtherPurposeVisiting(true);
          setValue('otherPurposeVisiting', otherPurposeVisiting);
        }
      }
      // const jobStatus = user?.properties?.jobStatus;
      // const otherJobStatus = user?.properties?.otherJobStatus;
      // if (jobStatus) {
      //   if (!Object.keys(JOB_STATUS[lang]).find((type) => type === jobStatus)) {
      //     setUseOtherJobStatus(true);
      //     setValue('jobStatus', JOB_STATUS[lang]['Другое']);
      //     setValue('otherJobStatus', otherJobStatus);
      //   } else {
      //     setValue('jobStatus', jobStatus);
      //   }
      // }
      const scopeCompany = user?.properties?.scopeCompany;
      const otherScopeCompany = user?.properties?.otherScopeCompany;
      if (scopeCompany) {
        setValue('scopeCompany', scopeCompany);
        if (scopeCompany.includes(SCOPE_COMPANY[lang].Другое)) {
          setUseOtherScopeCompany(true);
          setValue('otherScopeCompany', otherScopeCompany);
        }
      }
      const interestedSections = user?.properties?.interestedSections;
      const otherInterestedSections = user?.properties?.otherInterestedSections;
      if (interestedSections) {
        setValue('interestedSections', interestedSections);
        if (interestedSections.includes(INTERESTED_SECTIONS[lang].Другое)) {
          setUseOtherInterestedSections(true);
          setValue('otherInterestedSections', otherInterestedSections);
        }
      }
      // const howToKnow = user?.properties?.howToKnow;
      // const otherHowToKnow = user?.properties?.otherHowToKnow;
      // if (howToKnow) {
      //   if (!Object.keys(HOW_TO_KNOW[lang]).find((type) => type === howToKnow)) {
      //     setUseOtherHowToKnow(true);
      //     setValue('howToKnow', HOW_TO_KNOW[lang]['Другое']);
      //     setValue('otherHowToKnow', otherHowToKnow);
      //   } else {
      //     setValue('howToKnow', howToKnow);
      //   }
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isScanning, citizenshipRef]);

  useEffect(() => {
    return () => {
      clearInterval(fetchPassportInterval);
      setIsScanning(false);
    };
  }, []);

  useEffect(() => {
    // Clear interval after 2 minutes
    setTimeout(() => {
      clearInterval(fetchPassportInterval);
      setIsScanning(false);
    }, 120000);
  }, [fetchPassportInterval]);

  useEffect(() => {
    if (isAllowedPersonalData && errorCheckbox) {
      setErrorCheckbox(false);
    }
  }, [isAllowedPersonalData]);

  const labelStyle = { fontSize: 17 };

  const renderMultiSelect = (selected) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, flexDirection: 'column' }}>
      {selected.map((value) => (
        <div
          key={value}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            overflowWrap: 'anywhere',
            width: '100%',
          }}
        >
          {value}
        </div>
      ))}
    </Box>
  );

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
      {/* {isLocked && (
        <IconButton color="primary" className={classes.editBtn} onClick={handleForceEdit}>
          <ModeEditIcon />
        </IconButton>
      )} */}
      <Grid container columnSpacing={6.75} rowSpacing={5}>
        <Grid
          item
          xs={12}
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            '* > label': {
              whiteSpace: 'normal',
            },
          }}
        >
          <FormControlLabel
            ref={checkboxRef}
            control={
              <Checkbox
                sx={{ color: errorCheckbox ? 'red' : '' }}
                checked={isAllowedPersonalData}
                onClick={(e) => {
                  setIsAllowedPersonalData(e.target.checked);
                  setIsChanged(true);
                }}
                disabled={isLocked}
              />
            }
            label={TEXT[lang].ALLOWED_PERSONAL_DATA}
          />
          <span style={{ color: 'red', marginLeft: '-14px' }}>*</span>
          <IconButton sx={{ marginLeft: '4px' }} onClick={() => window.open('/documents/Согласие.pdf')}>
            <ArticleIcon />
          </IconButton>
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].FULLNAME}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              sx: {
                '& input': {
                  textTransform: 'capitalize',
                },
              },
            }}
            defaultValue={getValues('fullname')}
            {...register('fullname')}
            error={errors.fullname ? true : false}
            helperText={errors.fullname?.message}
            disabled={isLocked}
          />
        </Grid>
        {/* <Grid item sm={12} xs={12} md={6}>
          <FormControl margin="normal" error={errors.gender ? true : false} sx={{ width: '100%' }} disabled={isLocked}>
            <InputLabel
              shrink={true}
              variant="standard"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
                fontSize: 17,
              }}
            >
              {TEXT[lang].GENDER}
            </InputLabel>
            <Select
              variant="standard"
              margin="dense"
              defaultValue={getValues('gender')}
              {...register('gender')}
              style={{ ...labelStyle }}
            >
              {Object.keys(GENDER_OPTIONS).map((gender) => (
                <MenuItem key={gender} value={gender}>
                  {GENDER_OPTIONS[gender][lang]}
                </MenuItem>
              ))}
            </Select>
            {errors.gender && (
              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                {errors.gender.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* <Grid item sm={12} xs={12} md={6}>
          <TextField
            type="date"
            label={TEXT[lang].DATE_OF_BIRTH}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('birthday')}
            {...register('birthday')}
            error={errors.birthday ? true : false}
            helperText={errors.birthday?.message}
            disabled={isLocked}
          />
        </Grid> */}
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].COMPANY_NAME}
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputRef={nameRef}
            defaultValue={getValues('companyName')}
            {...register('companyName')}
            onChange={(e) => handleChangeTextField(e, 'companyName')}
            error={errors.companyName ? true : false}
            helperText={errors.companyName?.message}
            disabled={isLocked}
          />
          {renderSuggestion('companyName', nameRef)}
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].PLACE_OF_LIVING}
            fullWidth
            InputLabelProps={{ shrink: true }}
            defaultValue={getValues('city')}
            inputRef={extLegalAddressRef}
            {...register('city')}
            onChange={(e) => handleChangeTextField(e, 'city', true)}
            error={errors.city ? true : false}
            helperText={errors.city?.message}
            disabled={isLocked}
          />
          {renderSuggestion('city', extLegalAddressRef, true)}
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextFieldPhone
            required={true}
            label={TEXT[lang].NUMBER_TELEPHONE}
            fullWidth
            name="phone"
            autoFormat={false}
            {...register('phone')}
            value={getValues('phone')}
            onChange={(value) => setValue('phone', value)}
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].EMAIL}
            fullWidth
            InputLabelProps={{ shrink: true }}
            defaultValue={getValues('email')}
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].JOB_STATUS}
            fullWidth
            InputLabelProps={{ shrink: true }}
            defaultValue={getValues('jobStatus')}
            {...register('jobStatus')}
            error={errors.jobStatus ? true : false}
            helperText={errors.jobStatus?.message}
            disabled={isLocked}
          />
        </Grid>
        {/* <Grid item sm={12} xs={12} md={6}>
          <FormControl sx={{ width: '100%' }} margin="normal" disabled={isLocked}>
            <InputLabel
              shrink={true}
              required
              variant="standard"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
                fontSize: 17,
              }}
            >
              {TEXT[lang].JOB_STATUS}
            </InputLabel>
            <Select
              variant="standard"
              margin="dense"
              defaultValue={getValues('jobStatus')}
              {...register('jobStatus')}
              value={getValues('jobStatus')}
              style={{ ...labelStyle }}
            >
              {Object.keys(JOB_STATUS[lang]).map((value, i) => (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {useOtherJobStatus && (
            <TextField
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
                style: labelStyle,
              }}
              variant="standard"
              defaultValue={getValues('otherJobStatus')}
              {...register('otherJobStatus')}
              error={errors.otherJobStatus ? true : false}
              helperText={errors.otherJobStatus?.message}
              disabled={isLocked}
            />
          )}
        </Grid> */}
        <Grid item sm={12} xs={12} md={6}>
          <SelectHookForm
            label={TEXT[lang].EXTENT_COMMERCIAL_DECISIONS}
            error={errors.extentCommercialDecisions ? true : false}
            disabled={isLocked}
            selectProps={{
              defaultValue: getValues('extentCommercialDecisions'),
              ...register('extentCommercialDecisions'),
              multiple: true,
              renderValue: renderMultiSelect,
            }}
            errorComponent={
              <>
                {errors.extentCommercialDecisions && (
                  <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                    {errors.extentCommercialDecisions.message}
                  </FormHelperText>
                )}
              </>
            }
          >
            {Object.keys(EXTENT_COMMERCIAL_DECISIONS[lang]).map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectHookForm>
        </Grid>
        {/* <Grid item sm={12} xs={12} md={6}>
          <FormControl
            sx={{ width: '100%' }}
            margin="normal"
            error={errors.citizenship ? true : false}
            disabled={isLocked}
          >
            <InputLabel
              shrink={true}
              variant="standard"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
                fontSize: 17,
              }}
            >
              {TEXT[lang].CITIZENSHIP}
            </InputLabel>
            <Select
              ref={citizenshipRef}
              variant="standard"
              margin="dense"
              defaultValue={getValues('citizenship')}
              {...register('citizenship')}
              style={{ ...labelStyle }}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.localization?.[lang]?.name}
                  {country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}
                </MenuItem>
              ))}
            </Select>
            {errors.citizenship && (
              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                {errors.citizenship.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* <Grid item sm={12} xs={12} md={6}>
          <TextField
            label={TEXT[lang].PASSPORT_NUMBER}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('document_number')}
            {...register('document_number')}
            error={errors.document_number ? true : false}
            helperText={errors.document_number?.message}
            disabled={isLocked}
          />
        </Grid> */}
        {/* <Grid item sm={12} xs={12} md={6}>
          <TextField
            label={TEXT[lang].ISSUED_BY}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('issued_by')}
            {...register('issued_by')}
            error={errors.issued_by ? true : false}
            helperText={errors.issued_by?.message}
            disabled={isLocked}
          />
        </Grid> */}
        <Grid item sm={12} xs={12} md={6}>
          <SelectHookForm
            label={TEXT[lang].SCOPE_OF_COMPANY}
            error={errors.scopeCompany ? true : false}
            disabled={isLocked}
            selectProps={{
              defaultValue: getValues('scopeCompany'),
              ...register('scopeCompany'),
              multiple: true,
              renderValue: renderMultiSelect,
            }}
            errorComponent={
              <>
                {errors.scopeCompany && (
                  <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                    {errors.scopeCompany.message}
                  </FormHelperText>
                )}
              </>
            }
          >
            {Object.keys(SCOPE_COMPANY[lang]).map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectHookForm>

          {useOtherScopeCompany && (
            <TextField
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
                style: labelStyle,
              }}
              // variant="standard"
              defaultValue={getValues('otherScopeCompany')}
              {...register('otherScopeCompany')}
              error={emptyOther?.otherScopeCompany}
              helperText={emptyOther?.otherScopeCompany ? 'Please fill out this field' : ''}
              disabled={isLocked}
            />
          )}
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <SelectHookForm
            label={TEXT[lang].TYPE_ACTIVITY_COMPANY}
            error={errors.typeActivityCompany ? true : false}
            disabled={isLocked}
            selectProps={{
              defaultValue: getValues('typeActivityCompany'),
              ...register('typeActivityCompany'),
              multiple: true,
              renderValue: renderMultiSelect,
            }}
            errorComponent={
              <>
                {errors.typeActivityCompany && (
                  <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                    {errors.typeActivityCompany.message}
                  </FormHelperText>
                )}
              </>
            }
          >
            {Object.keys(TYPE_ACTIVITY_COMPANY[lang]).map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectHookForm>
          {useOtherTypeActivityCompany && (
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={getValues('otherTypeActivityCompany')}
              {...register('otherTypeActivityCompany')}
              error={emptyOther?.otherTypeActivityCompany}
              helperText={emptyOther?.otherTypeActivityCompany ? 'Please fill out this field' : ''}
              disabled={isLocked}
            />
          )}
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <SelectHookForm
            label={TEXT[lang].INTERESTED_SECTIONS}
            error={errors.interestedSections ? true : false}
            disabled={isLocked}
            selectProps={{
              defaultValue: getValues('interestedSections'),
              ...register('interestedSections'),
              multiple: true,
              renderValue: renderMultiSelect,
            }}
            errorComponent={
              <>
                {errors.interestedSections && (
                  <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                    {errors.interestedSections.message}
                  </FormHelperText>
                )}
              </>
            }
          >
            {Object.keys(INTERESTED_SECTIONS[lang]).map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectHookForm>
          {useUseOtherInterestedSections && (
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={getValues('otherInterestedSections')}
              {...register('otherInterestedSections')}
              error={emptyOther?.otherInterestedSections}
              helperText={emptyOther?.otherInterestedSections ? 'Please fill out this field' : ''}
              disabled={isLocked}
            />
          )}
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <SelectHookForm
            label={TEXT[lang].PURPOSE_VISITING}
            error={errors.purposeVisiting ? true : false}
            disabled={isLocked}
            selectProps={{
              defaultValue: getValues('purposeVisiting'),
              ...register('purposeVisiting'),
              multiple: true,
              renderValue: renderMultiSelect,
            }}
            errorComponent={
              <>
                {errors.purposeVisiting && (
                  <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                    {errors.purposeVisiting.message}
                  </FormHelperText>
                )}
              </>
            }
          >
            {Object.keys(PURPOSE_VISITING[lang]).map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectHookForm>
          {useOtherPurposeVisiting && (
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              defaultValue={getValues('otherPurposeVisiting')}
              {...register('otherPurposeVisiting')}
              error={emptyOther?.otherPurposeVisiting}
              helperText={emptyOther?.otherPurposeVisiting ? 'Please fill out this field' : ''}
              disabled={isLocked}
            />
          )}
        </Grid>
        {/* <Grid item sm={12} xs={12} md={6}>
          <FormControl
            sx={{ width: '100%' }}
            error={errors.amountPotentialPurchases ? true : false}
            margin="normal"
            disabled={isLocked}
          >
            <InputLabel
              shrink={true}
              required
              variant="standard"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
                fontSize: 17,
              }}
            >
              {TEXT[lang].AMOUNT_POTENTIAL_PURCHASES}
            </InputLabel>
            <Select
              variant="standard"
              margin="dense"
              defaultValue={getValues('amountPotentialPurchases')}
              {...register('amountPotentialPurchases')}
              // value={getValues('amountPotentialPurchases')}
              style={{ ...labelStyle }}
              multiple={true}
            >
              {Object.keys(AMOUNT_POTENTIAL_PURCHASES[lang]).map((value, i) => (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {errors.amountPotentialPurchases && (
              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                {errors.amountPotentialPurchases.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        <Grid item sm={12} xs={12} md={6}>
          <SelectHookForm
            label={TEXT[lang].HOW_TO_KNOW}
            error={errors.howToKnow ? true : false}
            disabled={isLocked}
            selectProps={{
              defaultValue: getValues('howToKnow'),
              ...register('howToKnow'),
              multiple: true,
              renderValue: renderMultiSelect,
            }}
            errorComponent={
              <>
                {errors.howToKnow && (
                  <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                    {errors.howToKnow.message}
                  </FormHelperText>
                )}
              </>
            }
          >
            {Object.keys(HOW_TO_KNOW[lang]).map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectHookForm>
          {/* {useUseOtherHowToKnow && (
            <TextField
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
                style: labelStyle,
              }}
              variant="standard"
              defaultValue={getValues('otherHowToKnow')}
              {...register('otherHowToKnow')}
              error={errors.otherHowToKnow ? true : false}
              helperText={errors.otherHowToKnow?.message}
              disabled={isLocked}
            />
          )} */}
        </Grid>
        {/* <Grid item sm={12} xs={12} md={6}>
          <FormControl
            sx={{ width: '100%' }}
            error={errors.blocksCongressProgram ? true : false}
            margin="normal"
            disabled={isLocked}
          >
            <InputLabel
              shrink={true}
              required
              variant="standard"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
                fontSize: 17,
              }}
            >
              {TEXT[lang].BLOCKS_CONGRESS_PROGRAM}
            </InputLabel>
            <Select
              variant="standard"
              margin="dense"
              defaultValue={getValues('blocksCongressProgram')}
              {...register('blocksCongressProgram')}
              style={{ ...labelStyle }}
              multiple={true}
            >
              {Object.keys(BLOCKS_CONGRESS_PROGRAM[lang]).map((value, i) => (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {errors.blocksCongressProgram && (
              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                {errors.blocksCongressProgram.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* ========== Организационно-правовая форма ==========*/}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack>
            {/* <Typography sx={{ fontSize: 18, margin: '12px 0 0' }}>Организационно-правовая форма</Typography> */}
            <Grid container columnSpacing={6.75} rowSpacing={5}>
              {/* <Grid item sm={12} xs={12} md={6}>
                <TextField
                  label={TEXT[lang].LEGAL_FORM}
                  margin="normal"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    style: labelStyle,
                  }}
                  variant="standard"
                  defaultValue={getValues('legalForm')}
                  {...register('legalForm')}
                  error={errors.legalForm ? true : false}
                  helperText={errors.legalForm?.message}
                  disabled={isLocked}
                />
              </Grid> */}
              <Grid item sm={12} xs={12} md={6}>
                <SelectHookForm
                  label={TEXT[lang].COUNTRY_COMPANY}
                  error={errors.legalFormCountry ? true : false}
                  disabled={isLocked}
                  selectProps={{
                    ref: citizenshipRef,
                    defaultValue: getValues('legalFormCountry'),
                    ...register('legalFormCountry'),
                  }}
                  errorComponent={
                    <>
                      {errors.legalFormCountry && (
                        <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                          {errors.legalFormCountry.message}
                        </FormHelperText>
                      )}
                    </>
                  }
                >
                  {countries.map((country) => (
                    <MenuItem key={country.code} value={country.localization?.[LOCALIZATION.ru_RU]?.name}>
                      {country.localization?.[lang]?.name}
                      {country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}
                    </MenuItem>
                  ))}
                </SelectHookForm>
              </Grid>
              {/* <Grid item sm={12} xs={12} md={6}>
                <TextField
                  type="number"
                  label={TEXT[lang].INDEX}
                  margin="normal"
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    style: labelStyle,
                  }}
                  variant="standard"
                  defaultValue={getValues('legalFormIndex')}
                  {...register('legalFormIndex')}
                  error={errors.legalFormIndex ? true : false}
                  helperText={errors.legalFormIndex?.message}
                  disabled={isLocked}
                />
              </Grid> */}
              <Grid item sm={12} xs={12} md={6}>
                <TextField
                  required
                  label={TEXT[lang].CITY}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  defaultValue={getValues('legalFormCity')}
                  {...register('legalFormCity')}
                  error={errors.legalFormCity ? true : false}
                  helperText={errors.legalFormCity?.message}
                  disabled={isLocked}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={6}>
                <TextFieldPhone
                  fullWidth
                  label={TEXT[lang].MOB_TEL}
                  name="legalFormTel"
                  {...register('legalFormTel')}
                  value={getValues('legalFormTel')}
                  onChange={(value) => setValue('legalFormTel', value)}
                  error={errors.legalFormTel ? true : false}
                  helperText={errors.legalFormTel?.message}
                  disabled={isLocked}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={6}>
                <TextField
                  label={TEXT[lang].SITE_COMPANY}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  defaultValue={getValues('legalFormWeb')}
                  {...register('legalFormWeb')}
                  error={errors.legalFormWeb ? true : false}
                  helperText={errors.legalFormWeb?.message}
                  disabled={isLocked}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllowedReceiveNews}
                onClick={(e) => {
                  setIsAllowedReceiveNews(e.target.checked);
                  setIsChanged(true);
                }}
                disabled={isLocked}
              />
            }
            label={TEXT[lang].ALLOWED_RECEIVE_NEWS}
          />
        </Grid>
        {/* <Grid item sm={12} xs={12} md={6}>
          <PassportUserUpload
            user={user}
            projectUuid={id}
            onUploadedFile={(uploaded) => setUploadedFile(uploaded)}
            onFetchPassportInterval={() => {
              fetchPassportInterval = setInterval(() => {
                getMemberByUuid(id, user.uuid);
              }, 10000);
            }}
            getMemberDetail={() => getMemberByUuid(id, user.uuid)}
            isScanning={isScanning}
            setIsScanning={setIsScanning}
          />
        </Grid> */}
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          className={classes.button}
          style={{ justifyContent: 'center', marginBottom: '24px' }}
        >
          <Button
            type="submit"
            className={classes.submitButton}
            variant="contained"
            size="large"
            disabled={isSubmitting || !isValidForm || !isChanged || isLocked}
          >
            {TEXT[lang].SAVE_CHANGES}
          </Button>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} className={classes.button} style={{ justifyContent: 'flex-start' }}>
          <Button
            className={classes.secondaryButton}
            variant="contained"
            size="large"
            endIcon={<AddIcon />}
            onClick={onAddNewAccreadition}
            // disabled={isSubmitting || !isValidForm || !uploadedFile}
          >
            {TEXT[lang].ADD_MEMBER}
          </Button>
        </Grid> */}
      </Grid>
    </form>
  );
}
