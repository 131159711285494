// ** MUI Imports
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// ** Theme
import themeOptions from './ThemeOptions';

// ** Global Styles
import GlobalStyling from './globalStyles';

const ThemeComponent = (props) => {
  // ** Props
  const { children } = props;

  const settings = {
    themeColor: 'primary',
    mode: 'light',
    skin: 'default',
    footer: 'static',
    layout: 'vertical',
    lastLayout: 'vertical',
    direction: 'ltr',
    navHidden: false,
    appBarBlur: true,
    navCollapsed: false,
    contentWidth: 'boxed',
    toastPosition: 'top-right',
    verticalNavToggleType: 'accordion',
    appBar: 'fixed',
  };

  // ** Pass merged ThemeOptions (of core and user) to createTheme function
  let theme = createTheme(themeOptions(settings, 'light'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={() => GlobalStyling(theme)} />
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
