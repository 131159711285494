import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArticleIcon from '@mui/icons-material/Article';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFullName } from '../../utils/common';
import {
  DEFAULT_BIRTH_DAY,
  EVENTS,
  GENDER_OPTIONS,
  LOCALIZATION,
  MESSAGES,
  REGEX_E164_PHONE,
  TEXT,
} from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { userExpertValidationSchema } from '../../utils/formValidator';
import { createNotification } from '../../utils/notifications';
import {
  SCOPE_COMPANY,
  TYPE_ACTIVITY_COMPANY,
  PURPOSE_VISITING,
  INTERESTED_SECTIONS,
  ALLOWED_RECEIVE_NEWS,
  HOW_TO_KNOW,
  JOB_STATUS,
  EXTENT_COMMERCIAL_DECISIONS,
  AMOUNT_POTENTIAL_PURCHASES,
  BLOCKS_CONGRESS_PROGRAM,
} from './options';
import classes from './user-form.module.sass';
import MuiPhoneNumber from 'material-ui-phone-number';
import { editUser } from '../../store/actions/application';
import { userAPIs } from '../../services';

let fetchPassportInterval;
let isTyping;
const DEFAULT_RUSSIA = 'Российская Федерация';
export function UserExpertForm({ user, isLocked, onAddNewAccreadition }) {
  const { id } = useParams();
  const countries = useSelector((state) => state.application.countries);
  const userCompany = useSelector((state) => state.auth.company);
  const lang = LOCALIZATION.ru_RU;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidForm, setIsValidForm] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isAllowedPersonalData, setIsAllowedPersonalData] = useState(false);
  const [isAllowedReceiveNews, setIsAllowedReceiveNews] = useState(false);
  const [useOtherJobStatus, setUseOtherJobStatus] = useState(false);
  const [useOtherTypeActivityCompany, setUseOtherTypeActivityCompany] = useState(false);
  const [useOtherPurposeVisiting, setUseOtherPurposeVisiting] = useState(false);
  const [useOtherScopeCompany, setUseOtherScopeCompany] = useState(false);
  const [useUseOtherInterestedSections, setUseOtherInterestedSections] = useState(false);
  const [useUseOtherHowToKnow, setUseOtherHowToKnow] = useState(false);
  const [errorCheckbox, setErrorCheckbox] = useState(false);
  const citizenshipRef = useRef(null);
  const checkboxRef = useRef(null);
  const [emptyOther, setEmptyOther] = useState({});

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        fullname: user ? getFullName(user) : '',
        lastName: user?.lastName,
        firstName: user?.firstName,
        middleName: user?.middleName,
        city: user?.city,
        gender: user?.gender,
        email: user?.email,
        phone: user?.phone,
        citizenship: user?.citizenship ? user.citizenship.toString() : '',
        birthday: user?.birthday ?? DEFAULT_BIRTH_DAY,
        companyName: user?.properties?.companyName ?? userCompany?.fullName ?? '',
        document_number: user?.properties?.document_number ?? user?.passport?.properties?.document_number ?? '',
        issued_by: user?.properties?.issued_by ?? user?.passport?.properties?.issued_by ?? '',
        scopeCompany: user?.properties?.scopeCompany ?? [],
        otherScopeCompany: user?.properties?.otherScopeCompany ?? '',
        typeActivityCompany: user?.properties?.typeActivityCompany ?? [],
        otherTypeActivityCompany: user?.properties?.otherTypeActivityCompany ?? '',
        purposeVisiting: user?.properties?.purposeVisiting ?? [],
        otherPurposeVisiting: user?.properties?.otherPurposeVisiting ?? '',
        interestedSections: user?.properties?.interestedSections ?? [],
        otherInterestedSections: user?.properties?.otherInterestedSections ?? '',
        interestedProgram: user?.properties?.interestedProgram ?? '',
        howToKnow: user?.properties?.howToKnow ?? [],
        otherHowToKnow: user?.properties?.otherHowToKnow ?? '',
        allowedReceiveNews: user?.properties?.allowedReceiveNews ?? '',
        allowedPersonalData: user?.properties?.allowedPersonalData ?? '',
        jobStatus: user?.properties?.jobStatus ?? '',
        // otherJobStatus: user?.properties?.otherJobStatus ?? '',
        extentCommercialDecisions: user?.properties?.extentCommercialDecisions ?? [],
        amountPotentialPurchases: user?.properties?.amountPotentialPurchases ?? [],
        blocksCongressProgram: user?.properties?.blocksCongressProgram ?? [],
        legalFormCountry: user?.properties?.legalFormCountry ?? DEFAULT_RUSSIA,
        legalForm: user?.properties?.legalForm ?? '',
        legalFormIndex: user?.properties?.legalFormIndex ?? '',
        legalFormCity: user?.properties?.legalFormCity ?? '',
        legalFormTel: user?.properties?.legalFormTel ?? '',
        legalFormWeb: user?.properties?.legalFormWeb ?? '',
      }),
      [user]
    ),
    resolver: yupResolver(userExpertValidationSchema),
  });

  const onSubmit = async (values) => {
    if (!isAllowedPersonalData) {
      checkboxRef?.current?.focus();
      setErrorCheckbox(true);
      return;
    }
    if (Object.values(emptyOther).some((key) => key)) {
      return;
    }
    try {
      setIsSubmitting(true);
      const {
        fullname,
        lastName,
        firstName,
        middleName,
        birthday,
        gender,
        email,
        phone,
        companyName,
        citizenship,
        document_number,
        city,
        issued_by,
        scopeCompany,
        otherScopeCompany,
        typeActivityCompany,
        otherTypeActivityCompany,
        purposeVisiting,
        otherPurposeVisiting,
        interestedSections,
        otherInterestedSections,
        interestedProgram,
        howToKnow,
        otherHowToKnow,
        blocksCongressProgram,
        allowedReceiveNews,
        allowedPersonalData,
        jobStatus,
        otherJobStatus,
        extentCommercialDecisions,
        amountPotentialPurchases,
        legalForm,
        legalFormCountry,
        legalFormIndex,
        legalFormCity,
        legalFormTel,
        legalFormWeb,
      } = values;

      // const arrName = fullname?.split(' ') ?? [];
      // const lastName = arrName[0];
      // const firstName = arrName.length > 2 ? arrName[1] : '';
      // const middleName = arrName.length > 2 ? arrName.slice(2).join(' ') : arrName[1];

      const payloadUser = {
        firstName,
        lastName,
        middleName,
        birthday,
        citizenship: parseInt(citizenship),
        city,
        email,
        gender,
        phone,
        properties: {
          ...(companyName ? { companyName } : {}),
          ...(scopeCompany ? { scopeCompany } : {}),
          ...(otherScopeCompany ? { otherScopeCompany } : {}),
          ...(typeActivityCompany ? { typeActivityCompany } : {}),
          ...(otherTypeActivityCompany ? { otherTypeActivityCompany } : {}),
          ...(purposeVisiting ? { purposeVisiting } : {}),
          ...(otherPurposeVisiting ? { otherPurposeVisiting } : {}),
          ...(interestedSections ? { interestedSections } : {}),
          ...(otherInterestedSections ? { otherInterestedSections } : {}),
          ...(interestedProgram ? { interestedProgram } : {}),
          ...(howToKnow ? { howToKnow } : {}),
          ...(otherHowToKnow ? { otherHowToKnow } : {}),
          ...(blocksCongressProgram ? { blocksCongressProgram } : {}),
          allowedReceiveNews: isAllowedReceiveNews,
          allowedPersonalData: isAllowedPersonalData,
          // ...(allowedReceiveNews ? { allowedReceiveNews } : {}),
          // ...(allowedPersonalData ? { allowedPersonalData } : {}),
          ...(jobStatus ? { jobStatus } : {}),
          ...(otherJobStatus ? { otherJobStatus } : {}),
          ...(extentCommercialDecisions ? { extentCommercialDecisions } : {}),
          ...(amountPotentialPurchases ? { amountPotentialPurchases } : {}),
          ...(legalForm ? { legalForm } : { legalForm: '' }),
          ...(legalFormCountry ? { legalFormCountry } : { legalFormCountry: '' }),
          ...(legalFormIndex ? { legalFormIndex } : { legalFormIndex: '' }),
          ...(legalFormCity ? { legalFormCity } : { legalFormCity: '' }),
          ...(legalFormTel && REGEX_E164_PHONE.test(legalFormTel) ? { legalFormTel } : { legalFormTel: '' }),
          ...(legalFormWeb ? { legalFormWeb } : { legalFormWeb: '' }),
        },
        ...(user?.readyToAccreditate === false && { readyToAccreditate: true }),
      };

      const payloadPassport = {
        nationality: parseInt(citizenship),
        document_number,
        birth_date: birthday,
        issued_by,
      };

      // await editUser({ uuid: user.uuid, updatingUserFields: payloadUser, dataProperties: payloadPassport });
      await editUser({ uuid: user.uuid, ...payloadUser, ...payloadPassport });

      // createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      setIsChanged(false);
      // setIsValidForm(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [focusingField, setFocusingField] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const nameRef = useRef(null);
  const extLegalAddressRef = useRef(null);

  const getSuggestion = async (query, field = '', isAddress) => {
    if (isSearching) return;
    try {
      setIsSearching(true);
      const res = isAddress ? await userAPIs.getSuggestionAddress(query) : await userAPIs.getSuggestionCompany(query);
      setSuggestions(res.message || []);
      setFocusingField(field);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSearching(false);
    }
  };

  const renderSuggestion = useCallback(
    (field, ref, isAddress) => {
      const handleSelect = (suggestion) => {
        setFocusingField('');
        if (isAddress) {
          suggestion && setValue('city', suggestion);
          suggestion && setValue('legalFormCity', suggestion);
          suggestion && setValue('legalFormCountry', DEFAULT_RUSSIA);
        } else {
          suggestion.fullName && setValue('companyName', suggestion.fullName);
          suggestion.emails?.[0] && setValue('email', suggestion.emails?.[0]);
          suggestion.phones?.[0] && setValue('phone', suggestion.phones?.[0]);
          suggestion && setValue('legalFormCountry', DEFAULT_RUSSIA);
        }
      };

      return (
        <Popper
          open={focusingField === field && suggestions?.length > 0}
          anchorEl={ref.current}
          placement="bottom-start"
          transition
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper className={classes.suggestions}>
                <ClickAwayListener onClickAway={() => setFocusingField('')}>
                  <MenuList
                    sx={{
                      maxWidth: '50vw',
                      maxHeight: '450px',
                      overflow: 'scroll',
                    }}
                  >
                    {suggestions.map((suggest, i) => (
                      <MenuItem key={i} onClick={() => handleSelect(suggest)}>
                        {isAddress ? `${suggest}` : `${suggest.shortName} - ${suggest.managerName}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      );
    },
    [suggestions, focusingField]
  );

  const handleChangeTextField = (e, field, isAddress) => {
    const value = e.target.value;
    setValue(field, value);
    clearTimeout(isTyping);
    isTyping = setTimeout(() => {
      getSuggestion(value, field, isAddress);
    }, 1000);
  };

  const handleForceEdit = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: TEXT[lang].CONFIRM,
      content: `${MESSAGES[lang].FORCE_EDIT_CONFIRM}`,
      onOk: () => {
        editUser({ uuid: user.uuid, forceEdit: true });
      },
    });
  };

  useEffect(() => {
    const subscription = watch((values) => {
      setIsChanged(true);
      // let isValid = false;

      // const requiredFields = [
      //   'fullname',
      //   // 'gender',
      //   'companyName',
      //   'city',
      //   'phone',
      //   'email',
      //   'scopeCompany',
      //   'legalFormCountry',
      //   'legalFormCity',
      // ];
      // const filledFields = requiredFields.filter((key) => values[key]);
      // isValid = isValid && filledFields.length === requiredFields.length;
      // setIsValidForm(filledFields.length === requiredFields.length);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isScanning) {
      if (user) {
        setValue('fullname', getFullName(user));
        setValue('lastName', user.lastName);
        setValue('firstName', user.firstName);
        setValue('middleName', user.middleName);
        setValue('birthday', user.birthday || DEFAULT_BIRTH_DAY);
        setValue('gender', user.gender);
        setValue('phone', user.phone);

        setValue('citizenship', user.citizenship ? user.citizenship.toString() : '');
        if (citizenshipRef?.current) {
          citizenshipRef.current.value = user.citizenship ? user.citizenship.toString() : '';
        }
      }
      if (user?.passport) {
        if (
          user?.passport?.properties?.document_number &&
          user?.passport?.properties?.issued_by &&
          user?.passport?.properties?.nationality &&
          user?.passport?.properties?.birth_date &&
          user?.birthday &&
          user?.gender &&
          user?.firstName &&
          user?.lastName &&
          user?.citizenship &&
          user?.email &&
          user?.phone
        ) {
          clearInterval(fetchPassportInterval);
          setIsScanning(false);
        }
        if (user?.passport.properties?.document_number) {
          setValue('document_number', user?.passport.properties?.document_number);
        }
        if (user?.passport.properties?.issued_by) {
          setValue('issued_by', user?.passport.properties?.issued_by);
        }
        setIsChanged(false);
      }
    } else {
      if (user && user.properties && !user.passport) {
        if (user?.properties?.document_number) {
          setValue('document_number', user?.properties?.document_number);
        }
        if (user?.properties?.issued_by) {
          setValue('issued_by', user?.properties?.issued_by);
        }
        setIsAllowedReceiveNews(user?.properties?.allowedReceiveNews);
        setIsAllowedPersonalData(user?.properties?.allowedPersonalData);
        setValue('jobStatus', user?.properties?.jobStatus);
        setValue('extentCommercialDecisions', user?.properties?.extentCommercialDecisions);
        setValue('amountPotentialPurchases', user?.properties?.amountPotentialPurchases);
        setValue('companyName', user?.properties?.companyName);
        setValue('blocksCongressProgram', user?.properties?.blocksCongressProgram);
        setValue('legalForm', user?.properties?.legalForm);
        setValue('legalFormCountry', user?.properties?.legalFormCountry);
        setValue('legalFormIndex', user?.properties?.legalFormIndex);
        setValue('legalFormCity', user?.properties?.legalFormCity);
        setValue('legalFormTel', user?.properties?.legalFormTel);
        setValue('legalFormWeb', user?.properties?.legalFormWeb);
        setValue('howToKnow', user?.properties?.howToKnow);
        setIsChanged(false);
      }
    }

    if (user && user.properties) {
      const typeActivityCompany = user?.properties?.typeActivityCompany;
      const otherTypeActivityCompany = user?.properties?.otherTypeActivityCompany;
      if (typeActivityCompany) {
        setValue('typeActivityCompany', typeActivityCompany);
        if (typeActivityCompany.includes(TYPE_ACTIVITY_COMPANY[lang].Другое)) {
          setUseOtherTypeActivityCompany(true);
          setValue('otherTypeActivityCompany', otherTypeActivityCompany);
        }
      }
      const purposeVisiting = user?.properties?.purposeVisiting;
      const otherPurposeVisiting = user?.properties?.otherPurposeVisiting;
      if (purposeVisiting) {
        setValue('purposeVisiting', purposeVisiting);
        if (purposeVisiting.includes(PURPOSE_VISITING[lang].Другое)) {
          setUseOtherPurposeVisiting(true);
          setValue('otherPurposeVisiting', otherPurposeVisiting);
        }
      }
      const scopeCompany = user?.properties?.scopeCompany;
      const otherScopeCompany = user?.properties?.otherScopeCompany;
      if (scopeCompany) {
        setValue('scopeCompany', scopeCompany);
        if (scopeCompany.includes(SCOPE_COMPANY[lang].Другое)) {
          setUseOtherScopeCompany(true);
          setValue('otherScopeCompany', otherScopeCompany);
        }
      }
      const interestedSections = user?.properties?.interestedSections;
      const otherInterestedSections = user?.properties?.otherInterestedSections;
      if (interestedSections) {
        setValue('interestedSections', interestedSections);
        if (interestedSections.includes(INTERESTED_SECTIONS[lang].Другое)) {
          setUseOtherInterestedSections(true);
          setValue('otherInterestedSections', otherInterestedSections);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isScanning, citizenshipRef]);

  useEffect(() => {
    return () => {
      clearInterval(fetchPassportInterval);
      setIsScanning(false);
    };
  }, []);

  useEffect(() => {
    // Clear interval after 2 minutes
    setTimeout(() => {
      clearInterval(fetchPassportInterval);
      setIsScanning(false);
    }, 120000);
  }, [fetchPassportInterval]);

  useEffect(() => {
    if (isAllowedPersonalData && errorCheckbox) {
      setErrorCheckbox(false);
    }
  }, [isAllowedPersonalData]);

  const labelStyle = { fontSize: 17 };

  const renderMultiSelect = (selected) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, flexDirection: 'column' }}>
      {selected.map((value) => (
        <div
          key={value}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            overflowWrap: 'anywhere',
            width: '100%',
          }}
        >
          {value}
        </div>
      ))}
    </Box>
  );

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
      {/* {isLocked && (
        <IconButton color="primary" className={classes.editBtn} onClick={handleForceEdit}>
          <ModeEditIcon />
        </IconButton>
      )} */}
      <Grid container columnSpacing={8} rowSpacing={2}>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].LAST_NAME}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            InputProps={{
              sx: {
                '& input': {
                  textTransform: 'capitalize',
                },
              },
            }}
            variant="standard"
            defaultValue={getValues('lastName')}
            {...register('lastName')}
            error={errors.lastName ? true : false}
            helperText={errors.lastName?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].FIRST_NAME}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            InputProps={{
              sx: {
                '& input': {
                  textTransform: 'capitalize',
                },
              },
            }}
            variant="standard"
            defaultValue={getValues('firstName')}
            {...register('firstName')}
            error={errors.firstName ? true : false}
            helperText={errors.firstName?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            label={TEXT[lang].MIDDLE_NAME}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            InputProps={{
              sx: {
                '& input': {
                  textTransform: 'capitalize',
                },
              },
            }}
            variant="standard"
            defaultValue={getValues('middleName')}
            {...register('middleName')}
            error={errors.middleName ? true : false}
            helperText={errors.middleName?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].COMPANY_NAME}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            inputRef={nameRef}
            variant="standard"
            defaultValue={getValues('companyName')}
            {...register('companyName')}
            onChange={(e) => handleChangeTextField(e, 'companyName')}
            error={errors.companyName ? true : false}
            helperText={errors.companyName?.message}
            disabled={isLocked}
          />
          {renderSuggestion('companyName', nameRef)}
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            label={TEXT[lang].EMAIL}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('email')}
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <MuiPhoneNumber
            label={TEXT[lang].NUMBER_TELEPHONE}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            margin="normal"
            style={{ width: '100%' }}
            name="phone"
            variant="standard"
            autoFormat={false}
            defaultCountry={'ru'}
            {...register('phone')}
            value={getValues('phone')}
            onChange={(value) => setValue('phone', value)}
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <FormControl
            sx={{ width: '100%' }}
            margin="normal"
            error={errors.legalFormCountry ? true : false}
            disabled={isLocked}
          >
            <InputLabel
              required
              shrink={true}
              variant="standard"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
                fontSize: 17,
              }}
            >
              {TEXT[lang].COUNTRY_COMPANY}
            </InputLabel>
            <Select
              ref={citizenshipRef}
              variant="standard"
              margin="dense"
              defaultValue={getValues('legalFormCountry')}
              {...register('legalFormCountry')}
              style={{ ...labelStyle }}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.localization?.[LOCALIZATION.ru_RU]?.name}>
                  {country.localization?.[lang]?.name}
                  {country.shortNames?.[0] && ` (${country.shortNames?.[0]})`}
                </MenuItem>
              ))}
            </Select>
            {errors.legalFormCountry && (
              <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                {errors.legalFormCountry.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].CITY}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('legalFormCity')}
            {...register('legalFormCity')}
            error={errors.legalFormCity ? true : false}
            helperText={errors.legalFormCity?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            required
            label={TEXT[lang].PLACE_OF_LIVING}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('city')}
            inputRef={extLegalAddressRef}
            {...register('city')}
            onChange={(e) => handleChangeTextField(e, 'city', true)}
            error={errors.city ? true : false}
            helperText={errors.city?.message}
            disabled={isLocked}
          />
          {renderSuggestion('city', extLegalAddressRef, true)}
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <MuiPhoneNumber
            required
            label={TEXT[lang].MOB_TEL}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            margin="normal"
            style={{ width: '100%' }}
            name="legalFormTel"
            variant="standard"
            autoFormat={false}
            defaultCountry={'ru'}
            {...register('legalFormTel')}
            value={getValues('legalFormTel')}
            onChange={(value) => setValue('legalFormTel', value)}
            error={errors.legalFormTel ? true : false}
            helperText={errors.legalFormTel?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            label={TEXT[lang].SITE_COMPANY}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('legalFormWeb')}
            {...register('legalFormWeb')}
            error={errors.legalFormWeb ? true : false}
            helperText={errors.legalFormWeb?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <TextField
            label={TEXT[lang].JOB_STATUS}
            margin="normal"
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
              sx: {
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              },
              style: labelStyle,
            }}
            variant="standard"
            defaultValue={getValues('jobStatus')}
            {...register('jobStatus')}
            error={errors.jobStatus ? true : false}
            helperText={errors.jobStatus?.message}
            disabled={isLocked}
          />
        </Grid>
        <Grid item xs={12} sx={{ whiteSpace: 'nowrap' }}>
          <FormControlLabel
            ref={checkboxRef}
            control={
              <Checkbox
                sx={{
                  height: 'fit-content',
                  color: errorCheckbox ? 'red' : '',
                  '&.Mui-checked': { color: '#872FC3' },
                  fontSize: 17,
                }}
                checked={isAllowedPersonalData}
                onClick={(e) => {
                  setIsAllowedPersonalData(e.target.checked);
                  setIsChanged(true);
                }}
                disabled={isLocked}
              />
            }
            style={{ ...labelStyle }}
            label={TEXT[lang].ALLOWED_PERSONAL_DATA}
          />
          <span style={{ color: 'red', marginLeft: '-14px' }}>*</span>
          <IconButton sx={{ marginLeft: '4px' }} onClick={() => window.open('/documents/Согласие.pdf')}>
            <ArticleIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          className={classes.button}
          style={{ justifyContent: 'center', marginBottom: '24px' }}
        >
          <Button
            type="submit"
            className={classes.submitButton}
            variant="contained"
            size="large"
            disabled={isSubmitting || !isValidForm || !isChanged || isLocked}
          >
            {TEXT[lang].SAVE_CHANGES}
          </Button>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} className={classes.button} style={{ justifyContent: 'flex-start' }}>
          <Button
            className={classes.secondaryButton}
            variant="contained"
            size="large"
            endIcon={<AddIcon />}
            onClick={onAddNewAccreadition}
            // disabled={isSubmitting || !isValidForm || !uploadedFile}
          >
            {TEXT[lang].ADD_MEMBER}
          </Button>
        </Grid> */}
      </Grid>
    </form>
  );
}
