import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { Form } from 'react-final-form';
import UserLayout from 'src/layouts/UserLayout';
import Input from '../../components/input';
import { changePassword } from '../../store/actions/application';
import { deleteAccount } from '../../store/actions/auth';
import classes from './settings.module.sass';

export default function SettingsPage() {
  // const qrCode = useSelector((state) => state.application.qrCode);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <UserLayout className={classes.root} container>
      <Card>
        <CardContent>
          <Stack gap="16px">
            <Typography variant="h6">Настройки</Typography>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  {/* <Tab label="Настроить МФА" value="1" /> */}
                  <Tab label="Изменить пароль" value="1" />
                  <Tab label="Удалить аккаунт" value="2" />
                </TabList>
              </Box>
              {/* <TabPanel value="1">
                        <Container className={classes.container}>
                            <Row>
                                <Col>
                                    <p className={classes.text}>Включите МФА для повышения безопасности</p>
                                </Col>
                                <Col>
                                    <Button
                                        type="submit"
                                        variant="dark"
                                        size="lg"
                                        className={classes.turnOff}
                                        onClick={settingMfa}
                                    >Подключить</Button>
                                </Col>
                            </Row>
                            {qrCode &&
                                <Col>
                                    <Row>
                                        <div>
                                            <p><strong>Инструкция</strong></p>
                                            <p>1. Скачайте <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank">Google Authenticator</a> on your mobile.</p>
                                            <p>2. Сканируйте QR-код чтобы установить ключ</p>
                                        </div>
                                    </Row>
                                    <Row>
                                        <img className={classes.qr} src={qrCode} />
                                    </Row>
                                </Col>
                            }
                        </Container>
                    </TabPanel> */}
              <TabPanel value="1">
                <Form onSubmit={changePassword}>
                  {({ handleSubmit, submitError, submitting }) => (
                    <div className={classes.main}>
                      <form onSubmit={handleSubmit} className={classes.form}>
                        <Stack gap="20px" maxWidth="400px" paddingY="10px">
                          <Input name="password" type="password" placeholder="Пароль" />
                          <Input name="new_password" type="password" placeholder="Новый пароль" />
                          <Input name="new_password_again" type="password" placeholder="Новый пароль снова" />
                          <Stack>
                            <Button
                              type="submit"
                              variant="contained"
                              size="large"
                              isLoading={submitting}
                              className={classes.submit}
                            >
                              подверждить
                            </Button>
                            {submitError && <div className={classes.error}>{submitError}</div>}
                            {!submitError && submitting && <div className={classes.result}>Пароль изменен</div>}
                          </Stack>
                        </Stack>
                      </form>
                    </div>
                  )}
                </Form>
              </TabPanel>
              <TabPanel value="2">
                <Stack direction="row" gap="30px" alignItems="center" flexWrap="wrap" paddingY="20px">
                  <Typography variant="body1">Вы действительно хотите удалить аккаунт?</Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className={classes.turnOff}
                    onClick={deleteAccount}
                  >
                    Удалить
                  </Button>
                </Stack>
              </TabPanel>
            </TabContext>
          </Stack>
        </CardContent>
      </Card>
    </UserLayout>
  );
}
