import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { login } from '../../store/actions/auth';
import classes from './login.module.sass';
import AuthLayout from 'src/layouts/AuthLayout';
import { routes } from 'src/constants';
import { useNavigate } from 'react-router-dom';

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { mt: theme.spacing(8) },
}));

const schema = yup.object().shape({
  // email: yup.string().email().required(),
  // password: yup.string().min(5).required(),
});

export default function LoginPage() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { username: '', password: '' },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  return (
    <AuthLayout>
      <div className={classes.main}>
        <form onSubmit={handleSubmit(login)} className={classes.form}>
          <Box>
            <TypographyStyled variant="h5">Авторизация 🚀</TypographyStyled>
            <Typography variant="body2">Сделайте управление простым и увлекательным!</Typography>
          </Box>

          <Stack>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <Controller
                name="username"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    label="Логин"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.email)}
                  />
                )}
              />
              {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth>
              <InputLabel error={Boolean(errors.password)}>Пароль</InputLabel>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    label="Пароль"
                    onChange={onChange}
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: 'error.main' }} id="">
                  {errors.password.message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Stack gap="20px">
            <Button type="submit" variant="contained" size="large" disabled={isSubmitting} className={classes.submit}>
              Войти
            </Button>
            <Button variant="outlined" size="large" onClick={() => navigate(routes.staffLogin.path)}>
              Вход для сотрудников
            </Button>
          </Stack>
        </form>
      </div>
    </AuthLayout>
  );
}
