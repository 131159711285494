import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SelectHookForm } from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { routes } from 'src/constants';
import AuthLayout from 'src/layouts/AuthLayout';
import { getProjects } from 'src/store/actions/application';
import { staffLoginValidationSchema } from 'src/utils/formValidator';
import { staffLogin } from '../../store/actions/auth';
import classes from './login.module.sass';
import { getLocalizationValue } from 'src/utils/common';

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { mt: theme.spacing(8) },
}));

export default function StaffLoginPage() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { isLoading, data: projects } = useQuery(['getProjects'], getProjects);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { username: '', password: '', projectUuid: '' },
    mode: 'onBlur',
    resolver: yupResolver(staffLoginValidationSchema),
  });

  return (
    <AuthLayout>
      <div className={classes.main}>
        <form onSubmit={handleSubmit(staffLogin)} className={classes.form}>
          <Box>
            <TypographyStyled variant="h5">Авторизация 🚀</TypographyStyled>
            <Typography variant="body2">Сделайте управление простым и увлекательным!</Typography>
          </Box>

          <Stack gap="16px">
            <FormControl fullWidth>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    type="email"
                    label="Email"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.email)}
                  />
                )}
              />
              {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth>
              <InputLabel error={Boolean(errors.password)}>Пароль</InputLabel>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    label="Пароль"
                    onChange={onChange}
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: 'error.main' }} id="">
                  {errors.password.message}
                </FormHelperText>
              )}
            </FormControl>
            {isLoading ? (
              <Loader />
            ) : (
              <Controller
                name="projectUuid"
                control={control}
                render={({ field }) => (
                  <SelectHookForm
                    label="Проект"
                    shrink={false}
                    selectProps={{ value: field.value, onChange: field.onChange }}
                    error={errors.projectUuid}
                    errorComponent={
                      <>
                        {errors.projectUuid && (
                          <FormHelperText error={true}>{errors.projectUuid?.message}</FormHelperText>
                        )}
                      </>
                    }
                  >
                    {projects.map((project) => (
                      <MenuItem key={project.uuid} value={project.uuid}>
                        <>{getLocalizationValue(project, 'fullName')}</>
                      </MenuItem>
                    ))}
                  </SelectHookForm>
                )}
              />
            )}
          </Stack>
          <Stack gap="20px">
            <Button type="submit" variant="contained" size="large" disabled={isSubmitting} className={classes.submit}>
              Войти
            </Button>
            <Button variant="outlined" size="large" onClick={() => navigate(routes.login.path)}>
              Вход для администратора
            </Button>
          </Stack>
        </form>
      </div>
    </AuthLayout>
  );
}
