import cx from 'classnames';
import { Button as RBButton } from 'react-bootstrap';
import Loader from '../loader';
import classes from './style.module.sass';

export default function Button({ children, className, disabled, isLoading, ...other }) {
  return (
    <RBButton
      className={cx(classes.button, className, { [classes.disabled]: disabled })}
      {...other}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Loader variant="small" color="white" /> : children}
    </RBButton>
  );
}
