import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { logout } from '../../store/actions/auth';
import classes from './style.module.sass';
import { routes } from '../../constants';
import Button from '../button';
import { Container } from 'react-bootstrap';

export default function Header({ dark }) {
  const user = useSelector((state) => state.auth.user);
  const mfaSuccess = useSelector((state) => state.auth.mfaSuccess);
  return (
    <Container className={classes.header}>
      <Link
        to={user && mfaSuccess ? routes.applications.path : routes.login.path}
        className={cx(classes.logo, { [classes.dark]: dark })}
      >
        <span>Панель ЭФИ-Менеджер</span>
      </Link>
      <div className={classes.right}>
        {user && mfaSuccess && (
          <Link className={classes.settings} to={routes.manageMeetings.path} variant="outline-dark">
            Управление встречами
          </Link>
        )}
        {user && mfaSuccess && (
          <Link className={classes.settings} to={routes.settings.path} variant="outline-dark">
            настройки
          </Link>
        )}
        {user && (
          <Button onClick={logout} className={classes.exit} variant="outline-dark">
            выйти из системы
          </Button>
        )}
      </div>
    </Container>
  );
}
