import { Card, CardContent, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import Loader from 'src/components/loader';
import { systemUserAPIs } from 'src/services';
import { getSupportedUsers } from 'src/store/actions/application';
import SupportedUserCreate from './SupportedUserCreate';
import SupportedUserList from './SupportedUserList';

function SupportedUserTab({ projectUuid }) {
  const location = useLocation();

  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const fetchSystemRoles = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await systemUserAPIs.getSystemRoles(projectUuid);
    return res.message;
  };

  const { isLoading: isLoadingRoles, data: systemRoles } = useQuery(
    ['fetchSystemRoles', projectUuid],
    fetchSystemRoles,
    { enabled: !!projectUuid }
  );

  const handleGetApplicationList = useCallback(
    async (query) => {
      setIsLoadingTable(true);
      await getSupportedUsers(query);
      setIsLoadingTable(false);
    },
    [location]
  );

  useEffect(() => {
    handleGetApplicationList();
  }, [handleGetApplicationList]);

  if (isLoadingRoles) return <Loader />;
  return (
    <Card>
      <CardContent>
        <Stack gap="38px">
          <SupportedUserCreate
            projectUuid={projectUuid}
            systemRoles={systemRoles || []}
            onReloadTable={handleGetApplicationList}
          />
          <SupportedUserList
            loading={isLoadingTable}
            projectUuid={projectUuid}
            systemRoles={systemRoles || []}
            onReloadTable={handleGetApplicationList}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default SupportedUserTab;
