import { combineReducers } from 'redux';
import authReducer from './auth';
import applicationReducer from './application';

const combinedReducer = () =>
  combineReducers({
    auth: authReducer,
    application: applicationReducer,
  });

export default combinedReducer;
