import { Person } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { routes } from 'src/constants';
import { ACCESSES } from 'src/utils/ability';
import { getLocalizationValue } from 'src/utils/common';

export const getProjectExtraNavItems = (projectData, ability) => {
  const projectUuid = projectData?.uuid;
  const extraNavItems = projectData
    ? [
      { sectionTitle: getLocalizationValue(projectData, 'fullName') },
      {
        title: 'Участники',
        userIcon: 'mdi:account-box-multiple',
        path: routes.projectsUsers.path.replace(':uuid', projectUuid),
        // inactive: true,
      },
      {
        title: 'Статистика',
        userIcon: 'ic:baseline-query-stats',
        path: routes.projectsPage.path.replace(':uuid', projectUuid),
        // inactive: true,
      },
      {
        title: 'Встречи',
        userIcon: 'mdi:user-group',
        path: routes.projectsMeetings.path.replace(':uuid', projectUuid),
        // inactive: true,
        hidden: ability.cannot(ACCESSES.MANAGE_MEETING),
      },
      {
        title: 'Компании',
        icon: <BadgeIcon />,
        path: routes.projectVizits.path.replace(':uuid', projectUuid),
        hidden: ability.cannot(ACCESSES.MANAGE_VIZIT),
      },
      {
        title: 'Заказы',
        icon: <LocalGroceryStoreIcon />,
        path: routes.projectOrders.path.replace(':uuid', projectUuid),
        hidden: ability.cannot(ACCESSES.SEE_ORDER),
      },
      // { title: 'Мониторинг', userIcon: 'ic:outline-remove-red-eye' },
      // { title: 'Обращения', userIcon: 'mdi:email-mark-as-unread' },
      // { title: 'Заказы', userIcon: 'ic:baseline-shopping-cart' },
      {
        title: 'Скуд',
        userIcon: 'mdi:shield',
        path: routes.projectScubSettings.path.replace(':uuid', projectUuid),
        hidden: ability.cannot(ACCESSES.EDIT_SETTINGS),
      },
      {
        title: 'Настройки',
        userIcon: 'mdi:build',
        path: routes.projectSettings.path.replace(':uuid', projectUuid),
        hidden: ability.cannot(ACCESSES.EDIT_SETTINGS),
      },
      {
        title: 'Регистрация',
        icon: <PersonAddAltRoundedIcon />,
        path: routes.projectRegisters.path.replace(':uuid', projectUuid),
      },
    ]
    : [];

  return extraNavItems.filter((item) => !item.hidden);
};

const navigation = () => {
  return [
    {
      title: 'Главная',
      icon: <HomeOutlinedIcon />,
      path: '/',
      // badgeContent: 'new',
      // badgeColor: 'error',
      // children: [
      //   {
      //     title: 'CRM',
      //     path: '/dashboards/crm',
      //   },
      //   {
      //     title: 'Analytics',
      //     path: '/dashboards/analytics',
      //   },
      //   {
      //     title: 'eCommerce',
      //     path: '/dashboards/ecommerce',
      //   },
      // ],
    },
    {
      title: 'Все проекты',
      icon: <CircleOutlinedIcon sx={{ fontSize: '14px', width: '20px' }} />,
      path: '/projects',
    },
    {
      title: 'Все пользователи',
      icon: <Person />,
      path: '/users',
    },
  ];
};

export default navigation;
