/* eslint-disable react-hooks/exhaustive-deps */
import { FilterSharp } from '@mui/icons-material';
import { Card, Stack, Typography } from '@mui/material';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ButtonBack from '../../components/buttonBack';
import ConfirmModal from '../../components/confirm-modal';
import GeneralLayout from '../../components/general-layout';
import ProjectSelect from '../../components/projects-select';
import Title from '../../components/title';
import { isBezviz, routes } from '../../constants';
import { downloadApplicationList, getMeetingList, getTotalMeetings } from '../../store/actions/application';
import { MEETING_STATUS, LOCALIZATION } from '../../utils/constant';
import List from './list';
import classes from './manage-meetings.module.sass';

export default function ManageMeetings({ projectUuid, userUuid }) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const currentProject = useSelector((state) => state.application.currentProject);
  const list = useSelector((state) => state.application.list);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const queryParams = qs.parse(location.search);
  const [filters, setFilters] = useState({
    userUuid,
    page: 1,
    limit: 10,
  });

  const handleGetMeetingList = useCallback(() => {
    return getMeetingList({ ...filters });
  }, [filters, currentProject]);

  const downloadList = useCallback(() => {
    // return downloadApplicationList(queryParams);
  }, [filters]);

  const setQueryParams = useCallback(
    ({ page = filters.page || 1, ...otherParams }) => {
      const newKeys = Object.keys(otherParams);
      const newFilter = {
        userUuid,
        tab: filters.tab,
        ...Object.entries(filters)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...otherParams,
        page,
      };
      setFilters(newFilter);
      //   const query = qs.stringify({
      //     ...Object.entries(queryParams)
      //       .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
      //       .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
      //     ...otherParams,
      //   });
      //   navigate(`${routes.manageMeetings.path}/${page}?${query}`);
    },
    [filters]
  );

  useEffect(() => {
    if (currentProject && userUuid) {
      getTotalMeetings({ projectUuid: currentProject?.uuid, userUuid: userUuid, filters });
    }
  }, [currentProject, userUuid, filters]);

  useEffect(() => {
    if (!loading && currentProject) {
      setLoading(true);
      handleGetMeetingList().then(() => setLoading(false));
    }
  }, [handleGetMeetingList]);

  return (
    <Card>
      <Stack>
        <Typography variant="h6" padding="20px 20px 16px">
          Встречи
        </Typography>
        <List
          user={user}
          isBezviz={isBezviz(user)}
          onDownload={downloadList}
          loading={loading}
          queryParams={{
            ...filters,
            page: filters.page || 1,
          }}
          setQueryParams={setQueryParams}
          reloadTable={handleGetMeetingList}
        />
      </Stack>
    </Card>
  );
}
