const Fab = () => {
  return {
    MuiFab: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '5px',
          width: '32px',
          height: '32px',
          minHeight: '32px',
        }),
      },
    },
  };
};

export default Fab;
