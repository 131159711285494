import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import classes from './filters.module.sass';
import { Button, Grid, Stack } from '@mui/material';
import Input from 'src/components/input';
import Select from 'src/components/select';
import Checkbox from 'src/components/checkbox';
import useResponsive from 'src/hooks/useResponsive';
import TagFilter from 'src/components/filters/TagFilter';

import moment from 'moment';
import DateInput from 'src/components/date-input';
const CHECKBOXES = {
  hasDocuments: 'Есть документы',
  hasAvatar: 'Есть аватарка',
  isGivenMerch: 'Пользователь уже получил мерч',
  isBadgePrinted: 'Бейдж был напечатан',
};

const regions = [
  'Санкт-Петербург',
  'Алтайский край',
  'Амурская область',
  'Архангельская область',
  'Астраханская область',
  'Белгородская область',
  'Брянская область',
  'Владимирская область',
  'Волгоградская область',
  'Вологодская область',
  'Воронежская область',
  'Москва',
  'Севастополь',
  'Донецкая Народная Республика',
  'Еврейская автономная область',
  'Забайкальский край',
  'Запорожская область',
  'Ивановская область',
  'Иркутская область',
  'Кабардино-Балкарская Республика',
  'Калининградская область',
  'Калужская область',
  'Камчатский край',
  'Карачаево-Черкесская Республика',
  'Кемеровская область — Кузбасс',
  'Кировская область',
  'Краснодарский край',
  'Красноярский край',
  'Курганская область',
  'Ленинградская область',
  'Липецкая область',
  'Луганская Народная Республика',
  'Магаданская область',
  'Московская область',
  'Ненецкий автономный округ',
  'Нижегородская область',
  'Новгородская область',
  'Новосибирская область',
  'Омская область',
  'Оренбургская область',
  'Орловская область',
  'Пензенская область',
  'Пермский край',
  'Приморский край',
  'Псковская область',
  'Республика Адыгея (Адыгея)',
  'Республика Алтай',
  'Республика Башкортостан',
  'Республика Бурятия',
  'Республика Дагестан',
  'Республика Ингушетия',
  'Республика Калмыкия',
  'Республика Карелия',
  'Республика Коми',
  'Республика Крым',
  'Республика Марий Эл',
  'Республика Мордовия',
  'Республика Саха (Якутия)',
  'Республика Северная Осетия — Алания',
  'Республика Татарстан (Татарстан)',
  'Республика Тыва',
  'Республика Хакасия',
  'Ростовская область',
  'Рязанская область',
  'Самарская область',
  'Саратовская область',
  'Сахалинская область',
  'Свердловская область',
  'Смоленская область',
  'Ставропольский край',
  'Тамбовская область',
  'Тверская область',
  'Томская область',
  'Тульская область',
  'Тюменская область',
  'Удмуртская Республика',
  'Ульяновская область',
  'Хабаровский край',
  'Ханты-Мансийский автономный округ — Югра',
  'Херсонская область',
  'Челябинская область',
  'Чеченская Республика',
  'Чувашская Республика — Чувашия',
  'Чукотский автономный округ',
  'Ямало-Ненецкий автономный округ',
  'Ярославская область',
  'Курская область',
  'Мурманская область',
];

const competences = [
  'Автоматизация бизнес-процессов организаций',
  'Администрирование отеля',
  'Геопространственные технологии',
  'Облицовка плиткой',
  'Лабораторный химический анализ',
  'Лечебная деятельность(Фельдшер)',
  'Малярные и декоративные работы',
  'Медицинский и социальный уход',
  'Мехатроника',
  'Поварское дело',
  'Пожарная безопасность',
  'Производственная сборка изделий авиационной техники',
  'Промышленная автоматика',
  'Промышленный дизайн',
  'Реверсивный инжиниринг',
  'Ремонт беспилотных летательных аппаратов',
  'Ремонт и обслуживание легковых автомобилей',
  'Ресторанный сервис',
  'Сантехника и отопление',
  'Сборка корпусов металлических судов',
  'Сварочные технологии',
  'Спасательные работы',
  'Структурированные кабельные системы',
  'Технологии информационного моделирования BIM',
  'Технологии моды',
  'Токарные работы на станках с ЧПУ',
  'Туризм',
  'Управление перевозочным процессом на железнодорожном транспорте',
  'Фармацевтика',
  'Фрезерные работы на станках с ЧПУ',
  'Холодильная техника и системы кондиционирования',
  'Эксплуатация беспилотных авиационных систем',
  'Эксплуатация судов водного транспорта',
  'Электромонтаж',
  'Электроника',
  '3D Моделирование для компьютерных игр',
  'Вожатская деятельность',
  'Графический дизайн',
  'Дошкольное воспитание',
  'Инженерный дизайн САПР',
  'Информационная безопасность',
  'Кузовной ремонт',
  'Неразрушающий контроль',
  'Промышленная механика и монтаж',
  'Окраска автомобиля',
  'Преподавание в младших классах',
  'Преподавание технологии',
  'Программные решения для бизнеса',
  'Производство металлоконструкций',
  'Работы на токарных универсальных стендах',
  'Работы на фрезерных универсальных стендах',
  'Сервис на воздушном транспорте',
  'Сетевое и системное администрирование',
  'Слесарная работа с металлом',
  'Изготовление прототипов (Аддитивное производство)',
];

export default function UserFilters({ onSubmit }) {
  const isDesktop = useResponsive();
  const location = useLocation();

  const countries = useSelector((state) => state.application.countries);
  const roles = useSelector((state) => state.application.roles);

  const query = qs.parse(location.search, { parseBooleans: true });
  const initialValues = {
    ...query,
    tags: (typeof query.tags === 'string' ? query.tags?.split(',') : []) ?? [],
    citizenship: countries.find((c) => c.code === parseInt(query.citizenship, 10))?.code,
    countryResidence: countries.find((c) => c.code === parseInt(query.countryResidence, 10))?.code,
    mainRoleId: roles.find((c) => c.id === query.mainRoleId)?.id,
    birthday: query.birthday ? new Date(query.birthday) : '',
    region: query.region || '',
    competence: query.region || '',
    passportSerial: query.passportSerial || '',
    passportNumber: query.passportNumber || '',
  };
  const handleSubmit = (filters) => {
    let baseFilter = { ...filters };
    Object.keys(CHECKBOXES).forEach((key) => {
      if (!baseFilter[key]) {
        delete baseFilter[key];
      }
    });
    return onSubmit({
      ...baseFilter,
      tags: filters.tags?.join(','),
      citizenship: filters.citizenship,
      countryResidence: filters.countryResidence,
      mainRoleId: filters.mainRoleId,
    });
  };
  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.filters}>
          <Stack gap={isDesktop ? '36px' : '24px'}>
            <Grid container spacing={12}>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  <Input name="lastName" label="Фамилия" />
                  <Input name="firstName" label="Имя" />
                  <Input name="middleName" label="Отчество" />
                  <Input name="email" label="Email" type="email" />
                  <Input name="passportSerial" label="Серия паспорта" />
                  <Input name="passportNumber" label="Номер паспорта" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  {/* <Select
                    name="citizenship"
                    label="Гражданство"
                    options={countries}
                    getOptionLabel={(item) => item.localization?.ru_RU?.name}
                    getOptionValue={(item) => item.code}
                    isClearable
                  /> */}

                  <DateInput name="birthday" label="Дата рождения" />
                  <Select name="region" label="Регион" options={regions} isClearable />
                  <Select name="competence" label="Компетенция" options={competences} isClearable />

                  <Input name="phone" label="Телефон" />

                  <Input name="companyName" label="Название компании" />
                  {/* <Input name="uuid" label="Uuid" /> */}
                  <Input name="transactionNumber" label="Номер транзакции" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  {/* <Select
                    name="countryResidence"
                    label="Страна проживания/работы"
                    options={countries}
                    getOptionLabel={(item) => item.localization?.ru_RU?.name}
                    getOptionValue={(item) => item.code}
                    isClearable
                  />
                  <Input name="externalId" placeholder="ICM number" /> */}
                  <Select
                    name="mainRoleId"
                    label="Роль"
                    options={roles}
                    getOptionLabel={(item) => item.name?.ru_RU}
                    getOptionValue={(item) => item.id}
                    isClearable
                  />
                  <TagFilter />
                  <Stack gap="8px">
                    {Object.keys(CHECKBOXES).map((key) => (
                      <Checkbox key={key} name={key} label={CHECKBOXES[key]} />
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={12}>
              <Grid item xs={12} md={12} lg={4}></Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={() => {
                    form.restart();
                    onSubmit({});
                  }}
                >
                  Очистить фильтры
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button type="submit" variant="contained" className={classes.button}>
                  Искать
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      )}
    </Form>
  );
}
